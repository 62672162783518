import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AppPublicRoutes from "./appPublicRoutes";
import AppPrivateRoutes from "./appPrivateRoutes";
import AppRoleRoutes from "./appRoleRoutes";

const App = ({ location }) => (
  <React.Fragment>
    <AppPublicRoutes location={location} />
    <AppPrivateRoutes location={location} />
    <AppRoleRoutes location={location} />
  </React.Fragment>
);

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.account.token
  };
}

export default connect(mapStateToProps)(App);
