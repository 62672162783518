import React from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape, formatMessage } from "react-intl";
import { StyledCheckItemSection, StyledLabel } from "./Styles";
import CheckItem from "./CheckItem";
import messages from "./CheckItem.messages";

export class CheckItemSection extends React.Component {
  checkItemSection = React.createRef();

  render() {
    const { checkItems, checkChange, checkRemove, canEdit, intl } = this.props;
    const reversedCheckItems = checkItems.slice().reverse();
    const count = checkItems.slice().length;
    return count > 0 ? (
      <StyledCheckItemSection>
        {reversedCheckItems.map((checkItem) => (
          <CheckItem
            key={checkItem.id}
            checkItem={checkItem}
            checkChange={checkChange}
            checkRemove={checkRemove}
            canEdit={canEdit}
          />
        ))}

        <div ref={this.checkItemSection} />
      </StyledCheckItemSection>
    ) : (
      <div>
        <StyledLabel>{intl.formatMessage(messages.general.empty)}</StyledLabel>
        <div ref={this.checkItemSection} />
      </div>
    );
  }
}

CheckItemSection.propTypes = {
  checkItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkChange: PropTypes.func.isRequired,
  checkRemove: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(CheckItemSection);
