import {
  FETCH_USERS_BEGIN,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  PUT_USER_BEGIN,
  PUT_USER_SUCCESS,
  PUT_USER_FAILURE,
  POST_USER_BEGIN,
  POST_USER_SUCCESS,
  POST_USER_FAILURE,
  DELETE_USER_BEGIN,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE
} from "../actions/types";

const initialState = {
  users: [],
  isFetchingData: false,
  isSavingData: false,
  isDeleting: false,
  error: null
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case FETCH_USERS_BEGIN:
      return {
        ...state,
        users: [],
        isFetchingData: true,
        error: null
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        isFetchingData: false,
        error: null
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        users: [],
        isFetchingData: false,
        error: action.payload
      };
    case PUT_USER_BEGIN: {
      return {
        ...state,
        isSavingData: true,
        error: null
      };
    }
    case PUT_USER_SUCCESS: {
      const index = state.users.findIndex(x => x.id === action.payload.id);
      return {
        ...state,
        users: [
          ...state.users.slice(0, index),
          action.payload,
          ...state.users.slice(index + 1)
        ],
        isSavingData: false
      };
    }
    case PUT_USER_FAILURE: {
      return {
        ...state,
        isSavingData: false,
        error: action.payload
      };
    }
    case POST_USER_BEGIN: {
      return {
        ...state,
        isSavingData: true
      };
    }
    case POST_USER_SUCCESS: {
      return {
        ...state,
        isSavingData: false
      };
    }
    case POST_USER_FAILURE: {
      return {
        ...state,
        isSavingData: false,
        error: action.payload
      };
    }
    case DELETE_USER_BEGIN:
      return {
        ...state,
        isDeleting: true,
        error: null
      };
    case DELETE_USER_SUCCESS: {
      const index = state.users.findIndex(x => x.id === action.payload);
      return {
        ...state,
        isDeleting: false,
        users: [
          ...state.users.slice(0, index),
          ...state.users.slice(index + 1)
        ],
        error: null
      };
    }
    case DELETE_USER_FAILURE:
      return {
        ...state,
        isDeleting: false,
        error: null
      };

    default:
      return state;
  }
}
