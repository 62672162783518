import React from "react";
import PropTypes from "prop-types";
import MaterialTable from "material-table";
import { injectIntl, intlShape } from "react-intl";
import ActionButton from "../projects/projectSummarySection/items/itemHeader/headerActions/ActionButton";
import styled from "styled-components";
import messages from "./Projects.messages";
import { Flex } from "..";

const StyledProjectColumn = styled.span`
  color: ${({ theme }) => theme.blue_regular};
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`;

const ProjectsTable = ({
  projects,
  deleteProjectClicked,
  editProjectClicked,
  downloadProjectReportClicked,
  intl,
}) => (
  <React.Fragment>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
    />
    <MaterialTable
      localization={{
        body: {
          emptyDataSourceMessage: intl.formatMessage(
            messages.general.noAssociatedProject
          ),
        },
      }}
      columns={[
        {
          title: intl.formatMessage(messages.fields.project),
          field: "name",
          render: (rowData) => (
            <StyledProjectColumn onClick={() => editProjectClicked(rowData.id)}>
              {rowData.name}
            </StyledProjectColumn>
          ),
        },
        {
          title: intl.formatMessage(messages.fields.manager),
          field: "manager.fullname",
        },
        {
          title: intl.formatMessage(messages.fields.progress),
          field: "progressPercentage",
          render: (rowData) => <p>{rowData.progressPercentage} %</p>,
        },
        {
          title: intl.formatMessage(messages.fields.archived),
          field: "isArchived",
          render: (rowData) => (
            <input type="checkbox" checked={rowData.isArchived} disabled />
          ),
        },
        {
          title: intl.formatMessage(messages.fields.actions),
          sorting: false,
          render: (rowData) => (
            <Flex>
              <ActionButton
                onClick={() =>
                  downloadProjectReportClicked(rowData.id, rowData.name)
                }
                icon="download"
                iconSize={25}
                title={intl.formatMessage(messages.general.download)}
              />

              <ActionButton
                icon="remove"
                onClick={() => deleteProjectClicked(rowData.id)}
                iconSize={25}
                title={intl.formatMessage(messages.general.delete)}
              />
            </Flex>
          ),
        },
      ]}
      data={projects}
      options={{ toolbar: false, paging: false }}
    />
  </React.Fragment>
);

ProjectsTable.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteProjectClicked: PropTypes.func.isRequired,
  editProjectClicked: PropTypes.func.isRequired,
  downloadProjectReportClicked: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ProjectsTable);
