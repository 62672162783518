import React from "react";
import { Dialog } from "@blueprintjs/core";
import PropTypes from "prop-types";
import styled from "styled-components";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import FormContainer from "../../forms/formContainer/FormContainer";
import { Label } from "../../forms";
import { Flex } from "../..";
import { Button, ButtonSize } from "../../buttons";
import { LoadingOverlay } from "../../overlays";
import { UserMultiSelect } from "../../users";
import messages from "../Projects.messages";

const StyledDialog = styled(Dialog)`
  position: relative;
  max-width: 1000px !important;
  width: 80% !important;
`;

class UserDialog extends React.Component {
  constructor(props) {
    super(props);

    const { item } = this.props;

    this.state = {
      selectedUsers: item.assignedUsers,
      lastSelectedUserId: 0
    };
  }

  deselectUser = (index, userId) => {
    this.setState(prevState => ({
      ...prevState,
      selectedUsers: [
        ...prevState.selectedUsers.slice(0, index),
        ...prevState.selectedUsers.slice(index + 1)
      ],
      lastSelectedUserId: userId
    }));
  };

  getSelectedUserIndex = user => {
    const { selectedUsers } = this.state;

    return selectedUsers.findIndex(x => x.id === user.id);
  };

  handleClear = () => {
    this.setState({
      selectedUsers: [],
      lastSelectedUserId: 0
    });
  };

  handleTagRemove = (_tag, index) => {
    this.deselectUser(index);
  };

  handleUserSelect = user => {
    if (!this.isUserSelected(user)) {
      this.selectUser(user);
    } else {
      this.deselectUser(this.getSelectedUserIndex(user), user.id);
    }
  };

  isUserSelected = user => {
    const { selectedUsers } = this.state;

    return selectedUsers.some(x => x.id === user.id);
  };

  onClose = () => {
    const { closeDialog } = this.props;

    closeDialog();

    this.handleClear();
  };

  onSubmit = event => {
    event.preventDefault();

    const {
      props: { submit },
      state: { selectedUsers }
    } = this;

    submit(selectedUsers);
  };

  selectUser = user => {
    this.setState(prevState => ({
      ...prevState,
      selectedUsers: [...prevState.selectedUsers, user],
      lastSelectedUserId: user.id
    }));
  };

  render() {
    const {
      props: { isOpen, users, intl, loading },
      state: { selectedUsers }
    } = this;
    return (
      <StyledDialog
        onClose={this.onClose}
        isOpen={isOpen}
        title={intl.formatMessage(messages.dialog.assignedUsers)}
        canOutsideClickClose={false}
      >
        {loading && <LoadingOverlay />}

        <FormContainer onSubmit={e => e.preventDefault()}>
          <Flex justify="center">
            <Flex
              justify="center"
              align="center"
              direction="column"
              basis="50%"
            >
              <Label>
                <FormattedMessage {...messages.dialog.assignedUsers} />
              </Label>

              <Flex margin="10px 0px">
                <UserMultiSelect
                  handleClear={this.handleClear}
                  handleTagRemove={this.handleTagRemove}
                  handleUserSelect={this.handleUserSelect}
                  users={users}
                  selectedUsers={selectedUsers}
                />
              </Flex>

              <Button
                onClick={this.onSubmit}
                type="button"
                size={ButtonSize.MEDIUM}
              >
                <FormattedMessage {...messages.dialog.save} />
              </Button>
            </Flex>
          </Flex>
        </FormContainer>
      </StyledDialog>
    );
  }
}

UserDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  loading: PropTypes.bool.isRequired
};

export default injectIntl(UserDialog);
