import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { Dialog } from "@blueprintjs/core";
import PageLayout from "../PageLayout";
import { ItemGanttChart, ItemType } from "../../../../components/projects";
import * as projectActions from "../../../../actions/project";
import { LoadingOverlay } from "../../../../components/overlays";
import { H2 } from "../../../../components/text/Headers";
import messages from "./ProjectDashboardPage.messages";
import ItemPopoverContent from "../../../../components/projects/projectSummarySection/items/itemHeader/headerPopover/ItemPopoverContent";

class ProjectsDashboardPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpened: false,
      dialogDisplayedItem: null,
      selectedProject: undefined,
      selectedGoal: undefined,
      selectedStrategy: undefined
    };
  }

  componentDidMount() {
    const { fetchProjects } = this.props;

    fetchProjects();
  }

  getRedirectUrl = actionId => {
    const {
      selectedProject: project,
      selectedGoal: goal,
      selectedStrategy: strategy
    } = this.state;

    // prettier-ignore
    return `/project-summary?project=${project.id}&goal=${goal.id}&strategy=${strategy.id}&action=${actionId}`;
  };

  chartItemSelected = (id, itemType) => {
    const { projects, history } = this.props;

    switch (itemType) {
      case ItemType.PROJECT:
        this.setState({
          selectedProject: projects.find(x => x.id === id),
          selectedGoal: undefined,
          selectedStrategy: undefined
        });
        break;
      case ItemType.OBJECTIVE:
        this.setState(prevState => ({
          selectedGoal: prevState.selectedProject.goals.find(x => x.id === id),
          selectedStrategy: undefined
        }));
        break;
      case ItemType.STRATEGY:
        this.setState(prevState => ({
          selectedStrategy: prevState.selectedGoal.strategies.find(
            x => x.id === id
          )
        }));
        break;
      case ItemType.ACTION: {
        // This history push will cause an error in dev, cause an exception from DHTMLX Gantt is not handled (and it cannot be).
        // The error will be handled in production.
        history.push(this.getRedirectUrl(id));
        break;
      }
      default:
        break;
    }
  };

  chartItemLongPressed = item => {
    this.setState(prevState => ({
      ...prevState,
      dialogOpened: true,
      dialogDisplayedItem: item
    }));
  };

  onDialogClose = () => {
    this.setState(prevState => ({
      ...prevState,
      dialogOpened: false,
      dialogDisplayedItem: null
    }));
  };

  resetState = () => {
    this.setState({});
  };

  render() {
    const {
      props: { loading, projects, intl },
      state: {
        selectedProject,
        selectedGoal,
        selectedStrategy,
        dialogOpened,
        dialogDisplayedItem
      }
    } = this;
    return (
      <PageLayout>
        {loading && <LoadingOverlay />}
        <H2>
          <FormattedMessage {...messages.general.title} />
        </H2>

        {projects && projects.length > 0 && (
          <ItemGanttChart
            items={projects}
            itemType={ItemType.PROJECT}
            onItemSelected={this.chartItemSelected}
            onItemLongPressed={this.chartItemLongPressed}
            title={intl.formatMessage(messages.general.projects)}
          />
        )}

        {selectedProject && selectedProject.goals.length > 0 && (
          <ItemGanttChart
            items={selectedProject.goals}
            itemType={ItemType.OBJECTIVE}
            onItemSelected={this.chartItemSelected}
            onItemLongPressed={this.chartItemLongPressed}
            title={intl.formatMessage(messages.general.goals)}
          />
        )}

        {selectedGoal && selectedGoal.strategies.length > 0 && (
          <ItemGanttChart
            items={selectedGoal.strategies}
            itemType={ItemType.STRATEGY}
            onItemSelected={this.chartItemSelected}
            onItemLongPressed={this.chartItemLongPressed}
            title={intl.formatMessage(messages.general.strategies)}
          />
        )}

        {selectedStrategy && selectedStrategy.actions.length > 0 && (
          <ItemGanttChart
            items={selectedStrategy.actions}
            itemType={ItemType.ACTION}
            onItemSelected={this.chartItemSelected}
            onItemLongPressed={this.chartItemLongPressed}
            title={intl.formatMessage(messages.general.actions)}
            margin="0px 0px 35px 0px"
          />
        )}

        <Dialog
          isOpen={dialogOpened}
          onClose={this.onDialogClose}
          className="bp3-dialog-small"
        >
          <ItemPopoverContent item={dialogDisplayedItem} />
        </Dialog>
      </PageLayout>
    );
  }
}

ProjectsDashboardPage.defaultProps = {
  margin: "0px"
};

ProjectsDashboardPage.propTypes = {
  fetchProjects: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  intl: intlShape.isRequired,
  margin: PropTypes.string
};

const mapStateToProps = ({ project }) => ({
  projects: project.projects,
  loading: project.loading
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      fetchProjects: projectActions.fetchProjects
    }
  )(ProjectsDashboardPage)
);
