import React from "react";
import { connect } from "react-redux";
import ReactRouterPropTypes from "react-router-prop-types";
import { withRouter } from "react-router-dom";
import { Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import ForgotPasswordForm from "../../../forms/forgotPassword";
import * as actions from "../../../../actions/auth";
import PageLayout from "../PageLayout";
import AppToaster from "../../../../components/toasters/AppToaster";
import messages from "./messages";

class ForgotPasswordPage extends React.Component {
  submit = data => {
    const { resetPasswordRequest, history, intl } = this.props;

    return resetPasswordRequest(data).then(() => {
      this.showSuccessToaster(intl.formatMessage(messages.emailSent));
      history.push("/login");
    });
  };

  showSuccessToaster = message => {
    AppToaster.show({ message, intent: Intent.SUCCESS });
  };

  render() {
    return (
      <PageLayout>
        <ForgotPasswordForm submit={this.submit} />
      </PageLayout>
    );
  }
}

ForgotPasswordPage.propTypes = {
  resetPasswordRequest: PropTypes.func.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(
  withRouter(
    connect(
      null,
      { resetPasswordRequest: actions.resetPasswordRequest }
    )(ForgotPasswordPage)
  )
);
