import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DisplayStyle } from "../styles/Styles";

const StyledInputGroup = styled.div`
  margin: ${({ displayStyle }) => {
    if (displayStyle === DisplayStyle.INLINE) return "8px 5px";
    return "5px";
  }};

  flex-grow: ${({ grow }) => grow || "0"};

  display: ${({ displayStyle }) => {
    if (displayStyle === DisplayStyle.BLOCK) return "block";
    if (displayStyle === DisplayStyle.NONE) return "none";
    if (displayStyle === DisplayStyle.INLINE) return "flex";
    throw new Error("Invalid display styled provided");
  }};

  & > label {
    display: ${({ displayStyle }) => {
      if (displayStyle === DisplayStyle.BLOCK) return "block";
      if (displayStyle === DisplayStyle.NONE) return "none";
      if (displayStyle === DisplayStyle.INLINE) return "inline";
      throw new Error("Invalid display styled provided");
    }};

    margin-right: ${({ displayStyle }) => {
      if (displayStyle === DisplayStyle.BLOCK) return "0px";
      if (displayStyle === DisplayStyle.NONE) return "0px";
      if (displayStyle === DisplayStyle.INLINE) return "5px";
      throw new Error("Invalid display styled provided");
    }};
  }

  & > input {
    width: ${({ displayStyle }) => {
      if (displayStyle === DisplayStyle.BLOCK) return "";
      if (displayStyle === DisplayStyle.NONE) return "auto";
      if (displayStyle === DisplayStyle.INLINE) return "auto";
      throw new Error("Invalid display styled provided");
    }};
  }
`;

const InputGroupError = styled.p`
  text-align: left;
  margin-left: 5px;
  color: ${props => props.theme.red_danger};
`;

const InputGroup = ({ children, error, displayStyle, ...rest }) => (
  <StyledInputGroup displayStyle={displayStyle} {...rest}>
    {children}
    {error && <InputGroupError>{error}</InputGroupError>}
  </StyledInputGroup>
);

InputGroup.defaultProps = {
  error: "",
  displayStyle: DisplayStyle.BLOCK
};

InputGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  error: PropTypes.string,
  displayStyle: PropTypes.string
};

export default InputGroup;
