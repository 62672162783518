import React from "react";
import { Dialog, Intent } from "@blueprintjs/core";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import Validator from "validator";
import { InputGroup, Input, Label } from "../../../components/forms";
import { Flex } from "../../../components";
import { LoadingOverlay } from "../../../components/overlays";
import messages from "./ProfileForm.messages";
import { Button } from "../../../components/buttons";

const Container = styled.div`
  position: relative;
  padding: 20px;
`;

class PasswordDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
      errors: {}
    };
  }

  onClose = () => {
    const { closeDialog } = this.props;

    this.setState({
      oldPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
      errors: {}
    });

    closeDialog();
  };

  onChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  submit = () => {
    const { updatePassword } = this.props;
    const { oldPassword, newPassword, newPasswordConfirmation } = this.state;
    const errors = this.validate();
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      updatePassword({
        oldPassword,
        newPassword,
        newPasswordConfirmation
      }).then(
        this.setState({
          oldPassword: "",
          newPassword: "",
          newPasswordConfirmation: "",
          errors: {}
        })
      );
    }
  };

  validate = () => {
    const { oldPassword, newPassword, newPasswordConfirmation } = this.state;
    const { intl } = this.props;

    const errors = {};

    if (!oldPassword)
      errors.oldPassword = intl.formatMessage(messages.errors.fieldIsRequired);
    if (!Validator.isLength(newPassword, { min: 6 }))
      errors.newPassword = intl.formatMessage(messages.errors.fieldIsTooShort, {
        min: 6
      });
    if (!newPassword)
      errors.newPassword = intl.formatMessage(messages.errors.fieldIsRequired);
    if (!newPasswordConfirmation)
      errors.newPasswordConfirmation = intl.formatMessage(
        messages.errors.fieldIsRequired
      );
    if (newPassword !== newPasswordConfirmation)
      errors.newPasswordConfirmation = intl.formatMessage(
        messages.errors.confirmPassword
      );

    return errors;
  };

  render() {
    const {
      oldPassword,
      newPassword,
      newPasswordConfirmation,
      errors
    } = this.state;
    const { isOpen, loading, intl } = this.props;
    return (
      <Dialog
        isOpen={isOpen}
        onClose={() => this.onClose()}
        canOutsideClickClose={false}
        title={intl.formatMessage(messages.profile.updatePassword)}
      >
        <Container>
          {loading && <LoadingOverlay />}

          <Flex direction="column">
            <InputGroup error={errors.oldPassword} grow="1">
              <Label>
                <FormattedMessage {...messages.fields.oldPassword} />
              </Label>
              <Input
                name="oldPassword"
                type="password"
                value={oldPassword}
                onChange={this.onChange}
                autocomplete="new-password"
              />
            </InputGroup>

            <InputGroup error={errors.newPassword} grow="1">
              <Label>
                <FormattedMessage {...messages.fields.newPassword} />
              </Label>
              <Input
                name="newPassword"
                type="password"
                value={newPassword}
                onChange={this.onChange}
                autocomplete="new-password"
              />
            </InputGroup>

            <InputGroup error={errors.newPasswordConfirmation} grow="1">
              <Label>
                <FormattedMessage
                  {...messages.fields.newPasswordConfirmation}
                />
              </Label>
              <Input
                name="newPasswordConfirmation"
                type="password"
                value={newPasswordConfirmation}
                onChange={this.onChange}
                autocomplete="new-password"
              />
            </InputGroup>
            <Button intent={Intent.PRIMARY} onClick={this.submit}>
              <FormattedMessage {...messages.profile.updatePassword} />
            </Button>
          </Flex>
        </Container>
      </Dialog>
    );
  }
}

PasswordDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(PasswordDialog);
