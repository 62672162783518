import React from "react";
import styled from "styled-components";
import { SortableHandle } from "react-sortable-hoc";
import { ItemType } from "../../../..";

const StyledTitle = styled.h3`
  color: ${({ itemType, theme }) => {
    if (itemType === ItemType.OBJECTIVE) return theme.green_regular;
    if (itemType === ItemType.STRATEGY) return theme.blue_regular;
    if (itemType === ItemType.ACTION) return theme.purple_regular;
    return theme.green_regular;
  }};
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const ItemTitle = ({ name, ...rest }) => (
  <StyledTitle {...rest}>{name}</StyledTitle>
);

const ItemTitleHandle = SortableHandle(props => <ItemTitle {...props} />);

export default ItemTitleHandle;
