import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectStripe } from "react-stripe-elements";
import { Button, Intent, Alert } from "@blueprintjs/core";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import PageLayout from "../PageLayout";
import * as licenceActions from "../../../../actions/licence";
import { H2 } from "../../../../components/text/Headers";
import AppToaster from "../../../../components/toasters";
import { LoadingOverlay } from "../../../../components/overlays";
import messages from "./PaymentOptionsPage.messages";
import * as PaymentOptionsActions from "../../../../actions/paymentOptions";
import {
  CreditCardForm,
  SavedCreditCard
} from "../../../../components/creditCard";
import CollapseContainer from "./CollapseContainer";
import { Flex } from "../../../../components";
import { toLocalizedDateString } from "../../../../helpers/DateHelper";
import { SectionHeader, SectionContainer } from "./Styles";

class PaymentOptionsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alertMessageOpened: false,
      disableBillingOpened: false,
      updateCreditCardOpened: false,

      creditCardForm: {
        data: {
          cardHolderName: ""
        },
        stripe: {
          cardNumber: false,
          cardExpiry: false,
          cardCvc: false
        },
        errors: {}
      }
    };
  }

  componentDidMount() {
    const { fetchPaymentOptions, fetchCreditCard } = this.props;

    fetchPaymentOptions();
    fetchCreditCard();
  }

  onChange = ({ target: { value, name } }) => {
    const inputName = name;
    const inputValue = value;

    this.setState(prevState => ({
      ...prevState,
      creditCardForm: {
        ...prevState.creditCardForm,
        data: {
          ...prevState.data,
          [inputName]: inputValue
        }
      }
    }));
  };

  saveCreditCard = () => {
    const { updateCreditCard, stripe, intl } = this.props;
    const {
      creditCardForm: {
        data: { cardHolderName }
      }
    } = this.state;

    if (this.validateCreditCardToken()) {
      updateCreditCard(stripe, cardHolderName)
        .then(() =>
          this.showSuccessMessage(
            intl.formatMessage(messages.options.updateCreditCardSuccess)
          )
        )
        .catch(error => {
          this.showErrorMessage(error);
        });
    }
  };

  stripeElementChange = element => {
    this.setState(prevState => ({
      ...prevState,
      creditCardForm: {
        ...prevState.creditCardForm,
        stripe: {
          ...prevState.creditCardForm.stripe,
          [element.elementType]: element.complete
        }
      }
    }));
  };

  showSuccessMessage = message => {
    AppToaster.show({
      message,
      intent: Intent.SUCCESS
    });
  };

  showErrorMessage = err => {
    AppToaster.show({
      message: err,
      intent: Intent.DANGER
    });
  };

  toggleAlertMessage = isOpen => {
    this.setState(prevState => ({
      ...prevState,
      alertMessageOpened: isOpen
    }));
  };

  toggleCollapse = name => {
    this.setState(prevState => ({
      ...prevState,
      [name]: !prevState[name]
    }));
  };

  disableRecuringBilling = () => {
    const { disableRecuringBilling, intl } = this.props;
    this.setState({ alertMessageOpened: false });
    disableRecuringBilling()
      .then(() => {
        this.showSuccessMessage(
          intl.formatMessage(messages.options.disableBillingSuccess)
        );
      })
      .catch(error => this.showErrorMessage(error));
  };

  validateCreditCardToken = () => {
    const {
      creditCardForm: {
        data: { cardHolderName },
        stripe
      }
    } = this.state;
    const { intl } = this.props;
    const errors = {};

    if (!stripe.cardNumber)
      errors.cardNumber = intl.formatMessage(messages.errors.invalidCardNumber);
    if (!stripe.cardExpiry)
      errors.cardExpiry = intl.formatMessage(messages.errors.invalidCardExpiry);
    if (!stripe.cardCvc)
      errors.cardCvc = intl.formatMessage(messages.errors.invalidCardCVC);
    if (!cardHolderName)
      errors.cardHolderName = intl.formatMessage(
        messages.errors.fieldIsRequired
      );

    this.setState(prevState => ({
      ...prevState,
      creditCardForm: { ...prevState.creditCardForm, errors }
    }));

    return (
      stripe.cardNumber &&
      stripe.cardExpiry &&
      stripe.cardCvc &&
      cardHolderName !== ""
    );
  };

  renderCurrentCardSection = () => {
    const { card } = this.props;

    if (Object.keys(card).length !== 0) {
      return (
        <SectionContainer>
          <SectionHeader>
            <FormattedMessage {...messages.options.currentCreditCard} />
          </SectionHeader>
          <SavedCreditCard
            nameOnCard={card.nameOnCard}
            lastFour={card.last4}
            brand={card.brand}
            expiry={card.expiry}
          />
        </SectionContainer>
      );
    }

    return null;
  };

  renderLicenceBillingSection = () => {
    const {
      props: { licenceInformations: infos }
    } = this;

    if (infos.isActive && infos.recurringBillingActivated) {
      return (
        <Button
          onClick={() => this.toggleAlertMessage(true)}
          className="default-button"
        >
          <FormattedMessage {...messages.options.disableBilling} />
        </Button>
      );
    }
    if (infos.isActive && !infos.recurringBillingActivated) {
      return (
        <FormattedMessage
          {...messages.options.subscriptionEndsOn}
          values={{
            date: toLocalizedDateString(infos.currentPeriodEnd)
          }}
        />
      );
    }
    return <FormattedMessage {...messages.options.noActiveLicence} />;
  };

  renderUpdateCreditCardSection = () => {
    const { creditCardForm } = this.state;
    return (
      <Flex direction="column" align="flex-start">
        <CreditCardForm
          onChange={this.onChange}
          stripeElementChange={this.stripeElementChange}
          data={creditCardForm.data}
          errors={creditCardForm.errors}
        />
        <Button onClick={this.saveCreditCard} className="default-button">
          <FormattedMessage {...messages.general.save} />
        </Button>
      </Flex>
    );
  };

  render() {
    const {
      props: { loading, intl },
      state: {
        alertMessageOpened,
        disableBillingOpened,
        updateCreditCardOpened
      }
    } = this;

    return (
      <PageLayout>
        {loading && <LoadingOverlay />}
        <H2>
          <FormattedMessage {...messages.general.title} />
        </H2>

        {this.renderCurrentCardSection()}

        <CollapseContainer
          isOpen={updateCreditCardOpened}
          toggleCollapse={() => this.toggleCollapse("updateCreditCardOpened")}
          message={messages.options.updateMyCreditCard}
        >
          {this.renderUpdateCreditCardSection()}
        </CollapseContainer>

        <CollapseContainer
          isOpen={disableBillingOpened}
          toggleCollapse={() => this.toggleCollapse("disableBillingOpened")}
          message={messages.options.automaticRenewOfMyLicence}
        >
          {this.renderLicenceBillingSection()}
        </CollapseContainer>

        <Alert
          cancelButtonText={intl.formatMessage(messages.general.cancel)}
          confirmButtonText={intl.formatMessage(messages.general.confirm)}
          icon="disable"
          intent={Intent.DANGER}
          isOpen={alertMessageOpened}
          onCancel={() => this.toggleAlertMessage(false)}
          onConfirm={this.disableRecuringBilling}
        >
          <FormattedMessage {...messages.options.disableBillingConfirmation} />{" "}
        </Alert>
      </PageLayout>
    );
  }
}

PaymentOptionsPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  fetchPaymentOptions: PropTypes.func.isRequired,
  disableRecuringBilling: PropTypes.func.isRequired,
  licenceInformations: PropTypes.shape({}).isRequired,
  intl: intlShape.isRequired
};

const mapStateToProps = ({ licence, paymentOptions }) => ({
  licenceInformations: licence.informations,
  loading:
    licence.loading ||
    licence.updating ||
    paymentOptions.loading ||
    paymentOptions.fetchingCreditCard,
  card: paymentOptions.card
});

export default injectStripe(
  injectIntl(
    connect(
      mapStateToProps,
      {
        disableRecuringBilling: licenceActions.disableRecuringBilling,
        fetchPaymentOptions: PaymentOptionsActions.fetchPaymentOptions,
        fetchCreditCard: PaymentOptionsActions.fetchCreditCard,
        updateCreditCard: PaymentOptionsActions.updateCreditCard
      }
    )(PaymentOptionsPage)
  )
);
