import React from "react";
import { connect } from "react-redux";
import NavItem from "./NavItem";
import messages from "./NavItems.messages";
import { roles } from "../../../roles/roles";

const getNavItems = (lang) => [
  {
    name: messages.items.projects,
    minRole: roles.RegularUser,
    subNavItems: [
      {
        name: messages.items.projectsDetails,
        path: "/project-summary",
        minRole: roles.RegularUser,
      },
      {
        name: messages.items.projectsManagement,
        path: "/projects-management",
        minRole: roles.ProjectManager,
      },
    ],
  },
  {
    name: messages.items.dashboard,
    minRole: roles.RegularUser,
    subNavItems: [
      {
        name: messages.items.summary,
        path: "/dashboard-summary",
        minRole: roles.RegularUser,
      },
      {
        name: messages.items.task,
        path: "/user-task",
        minRole: roles.RegularUser,
      },
      {
        name: messages.items.log,
        path: "/logs",
        minRole: roles.ProjectManager,
      },
    ],
  },
  {
    name: messages.items.usersManagement,
    minRole: roles.Administrator,
    subNavItems: [
      {
        name: messages.items.users,
        path: "/users-management",
        minRole: roles.Administrator,
      },
    ],
  },
  {
    name: messages.items.preferences,
    minRole: roles.RegularUser,
    subNavItems: [
      {
        name: messages.items.profile,
        path: "/profile",
        minRole: roles.RegularUser,
      },
      {
        name: messages.items.licence,
        path: "/licence",
        minRole: roles.Administrator,
      },
      {
        name: messages.items.paymentOptions,
        path: "/payment-options",
        minRole: roles.Administrator,
      },
      {
        name: messages.items.help,
        path: `/${lang}/help`,
        minRole: roles.RegularUser,
        target: "_blank",
      },
      {
        name: messages.items.logout,
        path: "/logout",
        minRole: roles.RegularUser,
      },
    ],
  },
];

const renderNavigationItems = (lang) =>
  getNavItems(lang).map((navItem) => (
    <NavItem navItem={navItem} key={navItem.name.id} />
  ));

const NavItems = ({ lang }) => (
  <React.Fragment>{renderNavigationItems(lang)}</React.Fragment>
);

const mapStateToProps = ({ locales }) => ({
  lang: locales.lang,
});

export default connect(mapStateToProps)(NavItems);
