export const attachmentComponentFromProjects = (
  projects,
  attachmentResponse,
  hasItem
) => {
  let updatedProject;
  const projectIndex = projects.findIndex(
    (x) => x.id === attachmentResponse.itemParents.projectId
  );

  if (projectIndex >= 0 && attachmentResponse.itemParents.goalId == null) {
    updatedProject = attachmentComponent(projects[projectIndex], hasItem);
  } else {
    updatedProject = {
      ...projects[projectIndex],
      goals: attachmentGoals(
        projects[projectIndex].goals,
        attachmentResponse,
        hasItem
      ),
    };
  }

  return [
    ...projects.slice(0, projectIndex),
    updatedProject,
    ...projects.slice(projectIndex + 1),
  ];
};

const attachmentGoals = (goals, attachmentResponse, hasItem) => {
  let updatedGoal = null;
  const goalId =
    attachmentResponse.itemParents.goalId === 0
      ? attachmentResponse.itemId
      : attachmentResponse.itemParents.goalId;
  const index = goals.findIndex((x) => x.id === goalId);

  if (attachmentResponse.itemParents.goalId === 0) {
    updatedGoal = attachmentComponent(goals[index], hasItem);
  } else {
    updatedGoal = {
      ...goals[index],
      strategies: attachmentStrategies(
        goals[index].strategies,
        attachmentResponse,
        hasItem
      ),
    };
  }
  return [...goals.slice(0, index), updatedGoal, ...goals.slice(index + 1)];
};

const attachmentStrategies = (strategies, attachmentResponse, hasItem) => {
  let updatedStrategy = null;
  const strategyId =
    attachmentResponse.itemParents.strategyId === 0
      ? attachmentResponse.itemId
      : attachmentResponse.itemParents.strategyId;
  const index = strategies.findIndex((x) => x.id === strategyId);

  if (attachmentResponse.itemParents.strategyId === 0) {
    updatedStrategy = attachmentComponent(strategies[index], hasItem);
  } else {
    updatedStrategy = {
      ...strategies[index],
      actions: attachmentActions(
        strategies[index].actions,
        attachmentResponse,
        hasItem
      ),
    };
  }

  return [
    ...strategies.slice(0, index),
    updatedStrategy,
    ...strategies.slice(index + 1),
  ];
};

const attachmentActions = (actions, attachmentResponse, hasItem) => {
  const actionId = attachmentResponse.itemId;
  const index = actions.findIndex((x) => x.id === actionId);

  return [
    ...actions.slice(0, index),
    attachmentComponent(actions[index], hasItem),
    ...actions.slice(index + 1),
  ];
};
const attachmentComponent = (component, hasItem) => ({
  ...component,
  hasAttachments: hasItem,
});
