import {
  FETCH_PROJECTS_BEGIN,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILURE,
  PUT_PROJECT_BEGIN,
  PUT_PROJECT_SUCCESS,
  PUT_PROJECT_FAILURE,
  POST_PROJECT_BEGIN,
  POST_PROJECT_SUCCESS,
  POST_PROJECT_FAILURE,
  DELETE_PROJECT_BEGIN,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  SET_SELECTED_PROJECT,
  ORDER_ITEM_BEGIN,
  ORDER_ITEM_SUCCESS,
  ORDER_ITEM_FAILURE,
  OPEN_PROJECT_DIALOG,
  CLOSE_PROJECT_DIALOG,
  SAVE_COMMENT_SUCCESS,
  SAVE_ATTACHMENT_SUCCESS,
  REMOVE_ATTACHMENT_SUCCESS,
  SAVE_CHECKITEM_SUCCESS,
  REMOVE_CHECKITEM_SUCCESS,
  POST_CHECKITEM_EDIT_SUCCESS,
  DOWNLOAD_PROJECT_BEGIN,
  DOWNLOAD_PROJECT_SUCCESS,
  DOWNLOAD_PROJECT_FAILURE,
} from "../actions/types";
import { attachmentComponentFromProjects } from "./SubListUpdater/attachmentListUpdater.js";
import { checkItemComponentFromProjects } from "./SubListUpdater/checkItemListUpdater.js";
import { commentComponentFromProjects } from "./SubListUpdater/commentListUpdater.js";

const initialState = {
  projects: [],
  selectedProjectId: localStorage.selectedProjectId || 0,
  loading: false,
  error: null,
  isSavingProject: false,
  projectDialogOpened: false,
};

export default function project(state = initialState, action) {
  switch (action.type) {
    case DOWNLOAD_PROJECT_BEGIN:
    case FETCH_PROJECTS_BEGIN:
    case DELETE_PROJECT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DOWNLOAD_PROJECT_SUCCESS:
    case DOWNLOAD_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: action.payload,
        selectedProjectId: getSelectedProjectId(
          action.payload,
          state.selectedProjectId
        ),
      };
    case FETCH_PROJECTS_FAILURE:
    case DELETE_PROJECT_FAILURE:
    case ORDER_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case PUT_PROJECT_BEGIN:
    case POST_PROJECT_BEGIN: {
      return {
        ...state,
        isSavingProject: true,
      };
    }
    case PUT_PROJECT_SUCCESS: {
      const index = state.projects.findIndex((x) => x.id === action.payload.id);
      return {
        ...state,
        projects: [
          ...state.projects.slice(0, index),
          action.payload,
          ...state.projects.slice(index + 1),
        ],
        isSavingProject: false,
        projectDialogOpened: false,
      };
    }
    case POST_PROJECT_SUCCESS: {
      return {
        ...state,
        projects: [...state.projects, action.payload],
        isSavingProject: false,
        projectDialogOpened: false,
      };
    }
    case PUT_PROJECT_FAILURE:
    case POST_PROJECT_FAILURE: {
      return {
        ...state,
        isSavingProject: false,
        error: action.payload,
      };
    }
    case DELETE_PROJECT_SUCCESS: {
      const projectIndex = state.projects.findIndex(
        (x) => x.id === action.payload
      );
      return {
        ...state,
        projects: [
          ...state.projects.slice(0, projectIndex),
          ...state.projects.slice(projectIndex + 1),
        ],
        loading: false,
        error: null,
      };
    }
    case SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProjectId: action.payload,
      };
    case ORDER_ITEM_BEGIN:
    case ORDER_ITEM_SUCCESS: {
      const index = state.projects.findIndex((x) => x.id === action.payload.id);
      return {
        ...state,
        projects: [
          ...state.projects.slice(0, index),
          action.payload,
          ...state.projects.slice(index + 1),
        ],
      };
    }
    case OPEN_PROJECT_DIALOG:
      return {
        ...state,
        projectDialogOpened: true,
      };
    case CLOSE_PROJECT_DIALOG:
      return {
        ...state,
        projectDialogOpened: false,
      };
    case SAVE_COMMENT_SUCCESS: {
      const projects = commentComponentFromProjects(
        state.projects,
        action.payload
      );
      return {
        ...state,
        projects,
      };
    }
    case SAVE_ATTACHMENT_SUCCESS: {
      const projects = attachmentComponentFromProjects(
        state.projects,
        action.payload,
        true
      );
      return {
        ...state,
        projects,
      };
    }
    case REMOVE_ATTACHMENT_SUCCESS: {
      const projects = attachmentComponentFromProjects(
        state.projects,
        action.payload,
        action.payload.hasItem
      );
      return {
        ...state,
        projects,
      };
    }
    case POST_CHECKITEM_EDIT_SUCCESS: {
      const projects = checkItemComponentFromProjects(
        state.projects,
        action.payload,
        true
      );
      return {
        ...state,
        projects,
      };
    }
    case SAVE_CHECKITEM_SUCCESS: {
      const projects = checkItemComponentFromProjects(
        state.projects,
        action.payload,
        true
      );
      return {
        ...state,
        projects,
      };
    }
    case REMOVE_CHECKITEM_SUCCESS: {
      const projects = checkItemComponentFromProjects(
        state.projects,
        action.payload,
        action.payload.hasItems
      );
      return {
        ...state,
        projects,
      };
    }
    default:
      return state;
  }
}

const getSelectedProjectId = (projects, selectedStringValue) => {
  const selectedProjectId = parseInt(selectedStringValue, 10);

  if (projects.length === 0) {
    return 0;
  }

  if (projects.some((x) => x.id === selectedProjectId)) {
    return selectedProjectId;
  }

  return projects[0].id;
};
