import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { Button } from "@blueprintjs/core";
import { H2 } from "../text/Headers";
import { Flex } from "..";
import messages from "./Projects.messages";

const LicenceText = styled.p`
  text-align: center;
`;

const Header = ({ intl, addProjectClicked, licenceInfos }) => (
  <Flex justify="space-between" flow="row" align="center" margin="10px 0px">
    <H2>
      <FormattedMessage {...messages.general.projectsManagement} />
    </H2>
    <Flex direction="column" align="center" justify="center">
      {licenceInfos.isActive && (
        <Button
          onClick={addProjectClicked}
          text={intl.formatMessage(messages.general.addProject)}
          className="default-button"
        />
      )}

      {licenceInfos && Object.keys(licenceInfos).length > 0 && (
        <LicenceText>
          {licenceInfos.activeProjectCount}
          {licenceInfos.maximumProjectCount > 0
            ? ` / ${licenceInfos.maximumProjectCount}`
            : ""}{" "}
          {intl.formatMessage(messages.general.projects)}
        </LicenceText>
      )}
    </Flex>
  </Flex>
);

export default Header;
