import React from "react";
import { Dialog } from "@blueprintjs/core";
import PropTypes from "prop-types";
import Validator from "validator";
import styled from "styled-components";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { Button, ButtonSize } from "../../buttons";
import messages from "../Users.messages";
import RoleSelect from "../RoleSelect";
import { roles } from "../../roles/roles";
import FormContainer from "../../forms/formContainer/FormContainer";
import {
  FormSectionContainer,
  FormSection,
  ButtonSection,
} from "../../forms/formSection";
import { InputGroup, Input, Label } from "../../forms";
import { DisplayStyle } from "../../forms/styles/Styles";
import { LoadingOverlay } from "../../overlays";

const StyledDialog = styled(Dialog)`
  position: relative;
  max-width: 1000px !important;
  width: 80% !important;
`;

class UserDialog extends React.Component {
  constructor(props) {
    super(props);
    const { user } = this.props;
    this.state = {
      data: {
        id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        role: user.role,
        phoneNumber: user.phoneNumber !== null ? user.phoneNumber : "",
        position: user.position !== null ? user.position : "",
        isActive: user.isActive,
        isUnsubscribe: user.isUnsubscribe,
      },
      errors: {},
    };
  }

  onChange = ({ target: { value, name } }) => {
    const inputName = name;
    const inputValue = value;

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [inputName]: inputValue,
      },
    }));
  };

  onSubmit = (event) => {
    event.preventDefault();
    const {
      props: { submit },
      state: { data },
    } = this;

    const errors = this.validate(data);

    this.setState((prevState) => ({
      ...prevState,
      errors,
    }));

    if (Object.keys(errors).length === 0) {
      submit(data);
    }
  };

  validate = (data) => {
    const { intl } = this.props;
    const errors = {};

    if (!data.firstname)
      errors.firstname = intl.formatMessage(messages.errors.fieldIsRequired);
    if (!data.lastname)
      errors.lastname = intl.formatMessage(messages.errors.fieldIsRequired);
    if (!data.email)
      errors.email = intl.formatMessage(messages.errors.fieldIsRequired);
    if (data.email && !Validator.isEmail(data.email))
      errors.email = intl.formatMessage(messages.errors.invalidEmail);
    if (data.phoneNumber && !Validator.isMobilePhone(data.phoneNumber))
      errors.phoneNumber = intl.formatMessage(
        messages.errors.invalidPhoneNumber
      );

    return errors;
  };

  userRoleSelected = (role) => {
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        role: role.role,
      },
    }));
  };

  render() {
    const {
      props: { isOpen, onClose, intl, loading },
      state: {
        data: {
          firstname,
          lastname,
          email,
          phoneNumber,
          position,
          isActive,
          role,
          isUnsubscribe,
        },
        errors,
      },
    } = this;
    return (
      <StyledDialog
        isOpen={isOpen}
        onSubmit={this.onSubmit}
        onClose={onClose}
        title={intl.formatMessage(messages.general.userManagement)}
        canOutsideClickClose={false}
      >
        {loading && <LoadingOverlay />}
        <FormContainer onSubmit={this.onSubmit}>
          <FormSectionContainer>
            <FormSection
              title={intl.formatMessage(messages.general.generalInformations)}
            >
              <InputGroup error={errors.firstname}>
                <Label>
                  <FormattedMessage {...messages.fields.firstname} />
                </Label>
                <Input
                  name="firstname"
                  value={firstname}
                  onChange={this.onChange}
                />
              </InputGroup>

              <InputGroup error={errors.lastname}>
                <Label>
                  <FormattedMessage {...messages.fields.lastname} />
                </Label>
                <Input
                  name="lastname"
                  value={lastname}
                  onChange={this.onChange}
                />
              </InputGroup>

              <InputGroup error={errors.email}>
                <Label>
                  <FormattedMessage {...messages.fields.email} />
                </Label>
                <Input
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  type="email"
                />
              </InputGroup>

              <InputGroup>
                <Label>
                  <FormattedMessage {...messages.fields.role} />
                </Label>
                <RoleSelect
                  defaultRoleId={role}
                  userRoleSelected={this.userRoleSelected}
                  intl={intl}
                />
              </InputGroup>
            </FormSection>
            <FormSection
              title={intl.formatMessage(
                messages.general.additionalInformations
              )}
            >
              <InputGroup error={errors.phoneNumber}>
                <Label>
                  <FormattedMessage {...messages.fields.phoneNumber} />
                </Label>
                <Input
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={this.onChange}
                />
              </InputGroup>

              <InputGroup>
                <Label>
                  <FormattedMessage {...messages.fields.position} />
                </Label>
                <Input
                  name="position"
                  value={position}
                  onChange={this.onChange}
                />
              </InputGroup>

              <InputGroup displayStyle={DisplayStyle.INLINE}>
                <Label>
                  <FormattedMessage {...messages.fields.active} />
                </Label>
                <Input
                  name="isActive"
                  checked={isActive}
                  value={`${isActive}`}
                  onChange={(e) => {
                    this.onChange({
                      target: {
                        name: e.target.name,
                        value: e.target.checked,
                      },
                    });
                  }}
                  type="checkbox"
                />
              </InputGroup>

              <InputGroup displayStyle={DisplayStyle.INLINE}>
                <Label>
                  <FormattedMessage {...messages.fields.unsubscribe} />
                </Label>
                <Input
                  name="isUnsubscribe"
                  checked={isUnsubscribe}
                  value={`${isUnsubscribe}`}
                  onChange={(e) => {
                    this.onChange({
                      target: {
                        name: e.target.name,
                        value: e.target.checked,
                      },
                    });
                  }}
                  type="checkbox"
                />
              </InputGroup>
            </FormSection>
          </FormSectionContainer>
          <ButtonSection>
            <Button size={ButtonSize.MEDIUM} type="submit">
              <FormattedMessage {...messages.general.save} />
            </Button>
          </ButtonSection>
        </FormContainer>
      </StyledDialog>
    );
  }
}

UserDialog.defaultProps = {
  user: {
    id: 0,
    firstname: "",
    lastname: "",
    email: "",
    role: roles.RegularUser,
    phoneNumber: "",
    position: "",
    isActive: true,
    isUnsubscribe: false,
  },
};

UserDialog.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.number,
    phoneNumber: PropTypes.string,
    position: PropTypes.string,
    isActive: PropTypes.bool,
    isUnsubscribe: PropTypes.bool,
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(UserDialog);
