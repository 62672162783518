import {
  FETCH_LICENCE_TYPES_BEGIN,
  FETCH_LICENCE_TYPES_SUCCESS,
  FETCH_LICENCE_TYPES_FAILURE,
  FETCH_PRORATION_BEGIN,
  FETCH_PRORATION_SUCCESS,
  FETCH_PRORATION_FAILURE,
  STRIPE_CREATE_TOKEN_BEGIN,
  STRIPE_CREATE_TOKEN_SUCCESS,
  STRIPE_CREATE_TOKEN_FAILURE,
  UPDATE_LICENCE_BEGIN,
  UPDATE_LICENCE_SUCCESS,
  UPDATE_LICENCE_FAILURE,
  DISABLE_RECURING_BILLING_BEGIN,
  DISABLE_RECURING_BILLING_SUCCESS,
  DISABLE_RECURING_BILLING_FAILURE
} from "./types";
import api from "../api";

const disableBillingBegin = () => ({
  type: DISABLE_RECURING_BILLING_BEGIN
});

const disableBillingSuccess = () => ({
  type: DISABLE_RECURING_BILLING_SUCCESS
});

const disableBillingFailure = error => ({
  type: DISABLE_RECURING_BILLING_FAILURE,
  payload: error
});

const updateLicenceBegin = () => ({
  type: UPDATE_LICENCE_BEGIN
});

const updateLicenceSuccess = () => ({
  type: UPDATE_LICENCE_SUCCESS
});

const updateLicenceFailure = error => ({
  type: UPDATE_LICENCE_FAILURE,
  payload: error
});

const fetchLicenceTypesBegin = () => ({
  type: FETCH_LICENCE_TYPES_BEGIN
});

const fetchLicenceTypesSuccess = licenceTypes => ({
  type: FETCH_LICENCE_TYPES_SUCCESS,
  payload: licenceTypes
});

const fetchLicenceTypesFailure = error => ({
  type: FETCH_LICENCE_TYPES_FAILURE,
  payload: error
});

const fetchProrationBegin = () => ({
  type: FETCH_PRORATION_BEGIN
});

const fetchProrationSuccess = proration => ({
  type: FETCH_PRORATION_SUCCESS,
  payload: proration
});

const fetchProrationFailure = error => ({
  type: FETCH_PRORATION_FAILURE,
  payload: error
});

const createTokenBegin = () => ({
  type: STRIPE_CREATE_TOKEN_BEGIN
});

const createTokenSuccess = () => ({
  type: STRIPE_CREATE_TOKEN_SUCCESS
});

const createTokenFailure = () => ({
  type: STRIPE_CREATE_TOKEN_FAILURE
});

export const fetchLicenceTypes = () => dispatch => {
  dispatch(fetchLicenceTypesBegin());

  return api.licenceTypes
    .get()
    .then(res => {dispatch(fetchLicenceTypesSuccess(res.data))})
    .catch(error => {
      dispatch(fetchLicenceTypesFailure(error));

      throw error;
    });
};

export const fetchProration = (licenceType, billingMode) => dispatch => {
  dispatch(fetchProrationBegin());

  return api.proration
    .get(billingMode, licenceType)
    .then(res => dispatch(fetchProrationSuccess(res.data)))
    .catch(error => {
      dispatch(fetchProrationFailure(error));
    });
};

export const updateToken = token => dispatch => {
  dispatch(createTokenBegin());

  return api.creditCard
    .update(token)
    .then(res => dispatch(createTokenSuccess()))
    .catch(error => {
      dispatch(createTokenFailure(error));
      throw error;
    });
};

export const updateLicence = (licenceName, updateInformations) => dispatch => {
  dispatch(updateLicenceBegin());

  return api.licence
    .update(licenceName, updateInformations)
    .then(res => dispatch(updateLicenceSuccess()))
    .catch(error => {
      dispatch(dispatch(updateLicenceFailure(error)));
      throw error;
    });
};

export const disableRecuringBilling = () => dispatch => {
  dispatch(disableBillingBegin());

  return api.licence
    .disableBilling()
    .then(() => dispatch(disableBillingSuccess()))
    .catch(error => {
      dispatch(disableBillingFailure(error));
      throw error;
    });
};
