import React from "react";
import PropTypes from "prop-types";
import { ItemContainer } from "../items/index";
import { ItemType } from "../..";
import ActionList from "../actionList/ActionList";
import ItemSortableContainer from "../itemSortableContainer/ItemSortableContainer";
import { roles } from "../../../roles";

const StrategyList = ({
  strategies,
  userActions,
  goal,
  queryValues,
  currentUser,
  isParentManager,
  project
}) => {
  if (strategies) {
    return strategies
      .sort((a, b) => a.rank - b.rank)
      .map((strategy, index) => (
        <ItemContainer
          item={strategy}
          key={`strategy-${strategy.id}`}
          index={index}
          itemType={ItemType.STRATEGY}
          collection={ItemType.STRATEGY}
          userActions={userActions}
          goalId={goal.id}
          hasChildren={strategy.actions.length > 0}
          queryValues={queryValues}
          currentUser={currentUser}
          disabled={
            currentUser.role !== roles.Administrator &&
            currentUser.role !== roles.Management &&
            project.manager.id !== currentUser.id &&
            goal.manager.id !== currentUser.id
          }
          isParentManager={isParentManager}
        >
          <ItemSortableContainer
            itemOrderChanged={userActions.itemOrderChanged}
            goalId={goal.id}
            strategyId={strategy.id}
          >
            <ActionList
              actions={strategy.actions}
              userActions={userActions}
              goal={goal}
              strategy={strategy}
              queryValues={queryValues}
              isParentManager={
                strategy.manager.id === currentUser.id || isParentManager
              }
              currentUser={currentUser}
              project={project}
            />
          </ItemSortableContainer>
        </ItemContainer>
      ));
  }

  return <div>No strategy</div>;
};

StrategyList.defaultProps = {
  strategies: []
};

StrategyList.propTypes = {
  strategies: PropTypes.arrayOf(PropTypes.shape().isRequired),
  userActions: PropTypes.shape({}).isRequired,
  goal: PropTypes.shape({}).isRequired,
};

export default StrategyList;
