import { defineMessages } from "react-intl";

const messages = {
  title: {
    id: "ProjectSummary.title"
  }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
