import React from "react";
import { injectIntl, intlShape } from "react-intl";
import { Button, MenuItem, PopoverPosition } from "@blueprintjs/core";
import PropTypes from "prop-types";
import { Select } from "../forms";
import messages from "./Users.messages";

class UserSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: null
    };
  }

  handleActiveItemChange = activeItem => {
    this.setState({ activeItem });
  };

  userPredicate = (userQuery, user) => {
    const fullname = `${user.firstname} ${user.lastname}`.toLowerCase();
    const query = userQuery.toLowerCase();

    return fullname.includes(query);
  };

  userItemRenderer = (user, { handleClick, modifiers: { active } }) => (
    <MenuItem
      key={user.id}
      label={user.email}
      text={user.fullname}
      onClick={handleClick}
      active={active}
    />
  );

  render() {
    const { activeUser, users, onUserSelected, intl } = this.props;
    const { activeItem } = this.state;
    return (
      <Select
        activeItem={activeItem}
        onActiveItemChange={this.handleActiveItemChange}
        items={users}
        itemListRenderer={this.userListRenderer}
        itemPredicate={this.userPredicate}
        itemRenderer={this.userItemRenderer}
        onItemSelect={onUserSelected}
        filterable
        resetOnClose
        text={
          activeUser
            ? activeUser.fullname
            : intl.formatMessage(messages.general.userIsDeleted)
        }
        initialContent={`${users.length} ${intl.formatMessage(
          messages.general.users
        )}`}
        inputProps={{
          placeholder: intl.formatMessage(messages.general.filter)
        }}
        popoverProps={{
          position: PopoverPosition.BOTTOM,
          boundary: "viewport",
          popoverClassName: "user-select__scrollable"
        }}
      />
    );
  }
}

UserSelect.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeUser: PropTypes.shape({}).isRequired,
  onUserSelected: PropTypes.func.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(UserSelect);
