import {
  FETCH_PROJECTS_BEGIN,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILURE,
  SET_SELECTED_PROJECT,
  POST_PROJECT_BEGIN,
  POST_PROJECT_SUCCESS,
  POST_PROJECT_FAILURE,
  PUT_PROJECT_BEGIN,
  PUT_PROJECT_SUCCESS,
  PUT_PROJECT_FAILURE,
  DELETE_PROJECT_BEGIN,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  OPEN_PROJECT_DIALOG,
  CLOSE_PROJECT_DIALOG,
  DOWNLOAD_PROJECT_BEGIN,
  DOWNLOAD_PROJECT_SUCCESS,
  DOWNLOAD_PROJECT_FAILURE
} from "./types";
import api from "../api";

export const fetchProjectsBegin = () => ({
  type: FETCH_PROJECTS_BEGIN
});

export const fetchProjectsSuccess = projects => ({
  type: FETCH_PROJECTS_SUCCESS,
  payload: projects
});

export const fetchProjectsFailure = error => ({
  type: FETCH_PROJECTS_FAILURE,
  payload: error
});

export const postProjectBegin = () => ({
  type: POST_PROJECT_BEGIN
});

export const postProjectSuccess = project => ({
  type: POST_PROJECT_SUCCESS,
  payload: project
});

export const postProjectFailure = error => ({
  type: POST_PROJECT_FAILURE,
  payload: error
});

export const putProjectBegin = () => ({
  type: PUT_PROJECT_BEGIN
});

export const putProjectSuccess = project => ({
  type: PUT_PROJECT_SUCCESS,
  payload: project
});

export const putProjectFailure = error => ({
  type: PUT_PROJECT_FAILURE,
  payload: error
});

export const deleteProjectBegin = () => ({
  type: DELETE_PROJECT_BEGIN
});

export const deleteProjectSuccess = projectId => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: projectId
});

export const deleteProjectFailure = error => ({
  type: DELETE_PROJECT_FAILURE,
  payload: error
});

export const setSelectedProjectBegin = projectId => ({
  type: SET_SELECTED_PROJECT,
  payload: projectId
});

const downloadProjectBegin = () => ({
  type: DOWNLOAD_PROJECT_BEGIN
});

const downloadProjectSuccess = () => ({
  type: DOWNLOAD_PROJECT_SUCCESS
});

const downloadProjectFailure = () => ({
  type: DOWNLOAD_PROJECT_FAILURE
});

export const fetchProjects = (activeProjectOnly = true) => dispatch => {
  dispatch(fetchProjectsBegin());

  return api.projects
    .get(activeProjectOnly)
    .then(res => {
      dispatch(fetchProjectsSuccess(res.data));
      return res.data;
    })
    .catch(error => {
      dispatch(fetchProjectsFailure(error));
    });
};

export const setSelectedProject = projectId => dispatch => {
  localStorage.selectedProjectId = projectId;
  dispatch(setSelectedProjectBegin(projectId));
};

export const projectUpdated = project => dispatch => {
  dispatch(putProjectSuccess(project));
};

export const updateProject = project => dispatch => {
  dispatch(putProjectBegin());

  return api.projects
    .put(project, project.id)
    .then(res => dispatch(putProjectSuccess(res.data)))
    .catch(error => {
      dispatch(putProjectFailure(error));
      throw error;
    });
};

export const saveProject = project => dispatch => {
  dispatch(postProjectBegin());

  return api.projects
    .post(project, project.id)
    .then(res => dispatch(postProjectSuccess(res.data)))
    .catch(error => {
      dispatch(postProjectFailure(error));

      throw error;
    });
};

export const deleteProject = projectId => dispatch => {
  dispatch(deleteProjectBegin());

  return api.projects
    .delete(projectId)
    .then(() => dispatch(deleteProjectSuccess(projectId)))
    .catch(error => {
      dispatch(deleteProjectFailure(error));
      throw error;
    });
};

export const openProjectDialog = () => dispatch => {
  dispatch({ type: OPEN_PROJECT_DIALOG });
};

export const closeProjectDialog = () => dispatch => {
  dispatch({ type: CLOSE_PROJECT_DIALOG });
};

export const downloadProjectReport = (projectId, projectName) => dispatch => {
  dispatch(downloadProjectBegin());
  api.projects
    .report(projectId, projectName)
    .then(() => dispatch(downloadProjectSuccess()))
    .catch(() => dispatch(downloadProjectFailure()));
};
