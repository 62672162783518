import { defineMessages } from "react-intl";

const messages = {
  types: {
    basic: { id: "Licences.type.basic" },
    regular: { id: "Licences.type.regular" },
    premium: { id: "Licences.type.premium" },
    soho: { id: "Licences.type.soho" },
    pro: { id: "Licences.type.pro" }
  },
  errors: {
    fieldIsRequired: { id: "Form.errors.fieldIsRequired" },
    invalidCardNumber: { id: "Form.errors.invalidCardNumber" },
    invalidCardExpiry: { id: "Form.errors.invalidCardExpiry" },
    invalidCardCVC: { id: "Form.errors.invalidCardCVC" },
    billingMethodNotSelected: { id: "Form.errors.billingMethodNotSelected" }
  },
  general: {
    beforeTaxes: {id: "Licences.general.beforeTaxes"},
    currentPackage: { id: "Licences.general.currentPackage" },
    updatePackage: { id: "Licences.general.updatePackage" },
    project: { id: "Licences.general.project" },
    maxUsers: { id: "Licences.general.maxUsers" },
    projectArchiving: { id: "Licences.general.projectArchiving" },
    unlimitedUsers: { id: "Licences.general.unlimitedUsers" },
    selectLicence: { id: "Licences.general.selectLicence" },
    select: { id: "Licences.general.select" },
    save: { id: "General.save" }
  },
  dialog: {
    updateCurrentLicence: { id: "Licences.dialog.updateCurrentLicence" },
    usePreviousCreditCard: { id: "Licences.dialog.usePreviousCreditCard" },
    monthlyPayment: { id: "Licences.dialog.monthlyPayment" },
    yearlyPayment: { id: "Licences.dialog.yearlyPayment" },
    prorationCredit: { id: "Licences.dialog.prorationCredit" },
    prorationCost: { id: "Licences.dialog.prorationCost" },
    monthlyBilling: { id: "Licences.dialog.monthlyBilling" },
    yearlyBilling: { id: "Licences.dialog.yearlyBilling" },
    promoCode: { id: "Licences.dialog.promoCode" },
    newLicence: { id: "Licences.dialog.newLicence" }
  },
  creditCard: {
    nameOnCard: { id: "Licences.creditCard.nameOnCard" },
    cardNumber: { id: "Licences.creditCard.cardNumber" },
    cardExpiry: { id: "Licences.creditCard.cardExpiry" },
    cardCVC: { id: "Licences.creditCard.cardCVC" }
  }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
