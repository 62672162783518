import React from "react";
import { connect } from "react-redux";
import PropTypes, { array } from "prop-types";
import { FormattedMessage } from "react-intl";
import { Intent } from "@blueprintjs/core";
import queryString from "query-string";
import * as projectActions from "../../../../actions/project";
import * as itemActions from "../../../../actions/item";
import * as userAction from "../../../../actions/user";
import * as commentAction from "../../../../actions/comment";
import * as attachmentAction from "../../../../actions/attachment";
import * as checkItemAction from "../../../../actions/checkItem";
import {
  ProjectSummaryHeader,
  ProjectSummarySection,
  ItemDialog,
  ItemType,
  UserDialog,
} from "../../../../components/projects";
import PageLayout from "../PageLayout";
import { H2 } from "../../../../components/text/Headers";
import { LoadingOverlay } from "../../../../components/overlays";
import messages from "./ProjectSummaryPage.messages";
import AppToaster from "../../../../components/toasters";
import CommentDialog from "../../../../components/comments/CommentDialog";
import AttachmentDialog from "../../../../components/attachments/AttachmentDialog";
import CheckItemDialog from "../../../../components/checkItems/CheckItemDialog";

class ProjectSummaryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      itemDialog: {
        itemType: "",
        item: undefined,
        itemParents: {},
      },
      userDialog: {
        isOpen: false,
        itemType: "",
        item: undefined,
        itemParents: {},
      },
      commentDialog: {
        isOpen: false,
        componentGuid: "",
        paging: {
          pageIndex: 0,
          pageSize: 50,
        },
      },
      attachmentDialog: {
        isOpen: false,
        componentGuid: "",
        paging: {
          pageIndex: 0,
          pageSize: 50,
        },
      },
      checkItemDialog: {
        isOpen: false,
        componentGuid: "",
        canEdit: false,
        paging: {
          pageIndex: 0,
          pageSize: 50,
        },
      },
    };
  }

  componentDidMount() {
    const { fetchProjects, fetchUsers } = this.props;
    const queryValues = this.getQueryValues();

    const { comment, attachment, checkItem, project } = queryValues;

    fetchUsers();

    fetchProjects().then(() => {
      const { projects } = this.props;

      if (comment && projects.some((p) => p.id === parseInt(project, 10))) {
        this.openCommentDialogFromQueryValues(queryValues);
      }
      if (attachment && projects.some((p) => p.id === parseInt(project, 10))) {
        this.openAttachmentDialogFromQueryValues(queryValues);
      }
      if (checkItem && projects.some((p) => p.id === parseInt(project, 10))) {
        this.openCheckItemDialogFromQueryValues(queryValues);
      }
    });

    if (project) {
      this.projectSelected(parseInt(project, 10));
    }
  }

  openCommentDialogFromQueryValues = (queryValues) => {
    const { action, strategy, goal, project } = queryValues;
    let itemID = 0;

    if (parseInt(action, 10) !== 0) {
      itemID = parseInt(action, 10);
    } else if (parseInt(strategy, 10) !== 0) {
      itemID = parseInt(strategy, 10);
    } else if (parseInt(goal, 10) !== 0) {
      itemID = parseInt(goal, 10);
    } else {
      itemID = parseInt(project, 10);
    }

    this.openCommentDialog(
      queryValues.comment,
      itemID,
      {
        goalId: parseInt(strategy, 10) !== 0 ? parseInt(goal, 10) : 0,
        strategyId: parseInt(action, 10) !== 0 ? parseInt(strategy, 10) : 0,
      },
      parseInt(project, 10)
    );
  };

  openAttachmentDialogFromQueryValues = (queryValues) => {
    const { action, strategy, goal, project } = queryValues;
    let itemID = 0;

    if (parseInt(action, 10) !== 0) {
      itemID = parseInt(action, 10);
    } else if (parseInt(strategy, 10) !== 0) {
      itemID = parseInt(strategy, 10);
    } else {
      itemID = parseInt(goal, 10);
    }

    this.openAttachmentDialog(
      queryValues.attachment,
      itemID,
      {
        goalId: parseInt(strategy, 10) !== 0 ? parseInt(goal, 10) : 0,
        strategyId: parseInt(action, 10) !== 0 ? parseInt(strategy, 10) : 0,
      },
      parseInt(project, 10)
    );
  };

  openCheckItemDialogFromQueryValues = (queryValues) => {
    const { action, strategy, goal, project } = queryValues;
    let itemID = 0;

    if (parseInt(action, 10) !== 0) {
      itemID = parseInt(action, 10);
    } else if (parseInt(strategy, 10) !== 0) {
      itemID = parseInt(strategy, 10);
    } else {
      itemID = parseInt(goal, 10);
    }

    this.openCheckItemDialog(
      queryValues.checkItem,
      itemID,
      {
        goalId: parseInt(strategy, 10) !== 0 ? parseInt(goal, 10) : 0,
        strategyId: parseInt(action, 10) !== 0 ? parseInt(strategy, 10) : 0,
      },
      parseInt(project, 10)
    );
  };

  getQueryValues = () => {
    const {
      location: { search },
    } = this.props;
    const values = queryString.parse(search);

    return values;
  };

  initializeEmptyState = () => {
    this.setState({
      itemDialog: {
        itemType: "",
        item: undefined,
        itemParents: {},
      },
      userDialog: {
        isOpen: false,
      },
      commentDialog: {
        isOpen: false,
        componentGuid: "",
        itemId: null,
        itemParents: null,
        paging: {
          pageIndex: 0,
          pageSize: 10,
        },
      },
      attachmentDialog: {
        isOpen: false,
        componentGuid: "",
        itemId: null,
        itemParents: null,
        paging: {
          pageIndex: 0,
          pageSize: 10,
        },
      },
      checkItemDialog: {
        isOpen: false,
        componentGuid: "",
        canEdit: false,
        itemId: null,
        itemParents: null,
        paging: {
          pageIndex: 0,
          pageSize: 20,
        },
      },
    });
  };

  editItemClicked = (item, itemType, goalId, strategyId) => {
    const { selectedProject } = this.props;
    const parents = {
      projectId: selectedProject.id,
      goalId,
      strategyId,
    };

    this.openComponentDialog(itemType, item, parents);
  };

  addItemClicked = (clickedItemType, itemInformations) => {
    const { selectedProject } = this.props;
    const itemType =
      clickedItemType === ItemType.OBJECTIVE
        ? ItemType.STRATEGY
        : ItemType.ACTION;

    const parents = {
      projectId: selectedProject.id,
      goalId:
        clickedItemType === ItemType.OBJECTIVE
          ? itemInformations.currentItemId
          : itemInformations.parents.goalId,
      strategyId:
        clickedItemType === ItemType.STRATEGY
          ? itemInformations.currentItemId
          : itemInformations.parents.strategyId,
    };

    this.openComponentDialog(itemType, undefined, parents);
  };

  itemUsersClicked = (item, itemType, itemParents) => {
    this.setState({
      userDialog: {
        item,
        isOpen: true,
        itemType,
        itemParents,
      },
    });
  };

  deleteItemClicked = (item, itemType, itemParents) => {
    const { deleteItem, selectedProject } = this.props;
    deleteItem(item.id, itemType, {
      projectId: selectedProject.id,
      goalId: itemParents.goalId,
      strategyId: itemParents.strategyId,
    });
  };

  openComponentDialog = (itemType, item = undefined, itemParents = {}) => {
    const { openItemDialog } = this.props;
    this.setState({
      itemDialog: {
        itemDialogIsOpen: true,
        itemType,
        item,
        itemParents,
      },
    });

    openItemDialog();
  };

  openCommentDialog = (
    componentGuid,
    itemId,
    itemParents,
    projectId = null
  ) => {
    const { fetchComments, selectedProject } = this.props;
    const { commentDialog } = this.state;

    this.setState((prevState) => ({
      ...prevState,
      commentDialog: {
        isOpen: true,
        componentGuid,
        itemId,
        itemParents: {
          ...itemParents,
          projectId: projectId !== null ? projectId : selectedProject.id,
        },
      },
    }));

    fetchComments(componentGuid, commentDialog.paging);
  };

  openAttachmentDialog = (
    componentGuid,
    itemId,
    itemParents,
    projectId = null
  ) => {
    const { fetchAttachments, selectedProject } = this.props;
    const { attachmentDialog } = this.state;

    this.setState((prevState) => ({
      ...prevState,
      attachmentDialog: {
        isOpen: true,
        componentGuid,
        itemId,
        itemParents: {
          ...itemParents,
          projectId: projectId !== null ? projectId : selectedProject.id,
        },
      },
    }));

    fetchAttachments(componentGuid, attachmentDialog.paging);
  };

  openCheckItemDialog = (
    canEdit,
    componentGuid,
    itemId,
    itemParents,
    projectId = null
  ) => {
    const { fetchCheckItems, selectedProject } = this.props;
    const { checkItemDialog } = this.state;

    this.setState((prevState) => ({
      ...prevState,
      checkItemDialog: {
        isOpen: true,
        componentGuid,
        canEdit,
        itemId,
        itemParents: {
          ...itemParents,
          projectId: projectId !== null ? projectId : selectedProject.id,
        },
      },
    }));

    fetchCheckItems(componentGuid, checkItemDialog.paging);
  };

  getItemValidationDates = (itemParents) => {
    const { selectedProject } = this.props;
    const dates = {};

    let parent = null;

    if (!itemParents.projectId || !itemParents.goalId) {
      parent = selectedProject;
    } else if (itemParents.goalId && !itemParents.strategyId) {
      parent = selectedProject.goals.find((x) => x.id === itemParents.goalId);
    } else {
      parent = selectedProject.goals
        .find((x) => x.id === itemParents.goalId)
        .strategies.find((x) => x.id === itemParents.strategyId);
    }

    dates.minDate = new Date(parent.beginningDate);
    dates.maxDate = new Date(parent.deadlineDate);

    return dates;
  };

  assignUsers = (assignedUsers) => {
    const {
      props: { assignUsers, selectedProject },
      state: { userDialog },
    } = this;
    assignUsers(
      userDialog.item.id,
      userDialog.itemType,
      {
        projectId: selectedProject.id,
        goalId: userDialog.itemParents.goalId,
        strategyId: userDialog.itemParents.strategyId,
      },
      assignedUsers
    ).then(() => {
      this.initializeEmptyState();
    });
  };

  closeComponentDialog = () => {
    const { closeItemDialog } = this.props;
    this.initializeEmptyState();
    closeItemDialog();
  };

  closeUserDialog = () => {
    this.initializeEmptyState();
  };

  closeCommentDialog = () => {
    this.initializeEmptyState();
  };

  closeAttachmentDialog = () => {
    this.initializeEmptyState();
  };

  closeCheckItemDialog = () => {
    this.initializeEmptyState();
  };

  projectItemUpdated = (item, itemType) => {
    const {
      props: { updateItem, selectedProject },
      state: {
        itemDialog: { itemParents },
      },
    } = this;

    updateItem(item, itemType, {
      projectId: selectedProject.id,
      goalId: itemParents.goalId,
      strategyId: itemParents.strategyId,
    })
      .then(() => this.initializeEmptyState())
      .catch((error) => {
        this.showErrorMessage(error);
      });
  };

  projectSelected = (projectId) => {
    const { setSelectedProject } = this.props;

    setSelectedProject(projectId);
  };

  itemOrderChanged = (sortValues, parents) => {
    const { orderItems, selectedProject } = this.props;

    orderItems(
      sortValues,
      {
        projectId: selectedProject.id,
        goalId: parents.goalId,
        strategyId: parents.strategyId,
      },
      selectedProject
    );
  };

  saveUserComment = (message) => {
    const { saveComment } = this.props;
    const {
      commentDialog: { componentGuid, itemId, itemParents },
    } = this.state;

    return saveComment(componentGuid, message, itemId, itemParents);
  };

  saveUserAttachment = (fileName, file) => {
    const { saveAttachment } = this.props;
    const {
      attachmentDialog: { componentGuid, itemId, itemParents },
    } = this.state;

    return saveAttachment(componentGuid, fileName, file, itemId, itemParents);
  };

  removeUserAttachment = (attachmentId) => {
    const { removeAttachment, attachments } = this.props;

    const {
      attachmentDialog: { componentGuid, itemId, itemParents },
    } = this.state;

    const checkItemIndex = attachments.findIndex((x) => x.id === attachmentId);

    const attachmentsList = [
      ...attachments.slice(0, checkItemIndex),
      ...attachments.slice(checkItemIndex + 1),
    ];

    const hasItem = attachmentsList.length > 0;

    return removeAttachment(
      componentGuid,
      attachmentId,
      itemId,
      itemParents,
      hasItem
    );
  };

  fetchUserAttachmentFile = (fileId) => {
    const { fetchAttachmentFile } = this.props;
    const {
      attachmentDialog: { componentGuid, itemId, itemParents },
    } = this.state;

    return fetchAttachmentFile(componentGuid, fileId, itemId, itemParents);
  };

  saveUserCheckItem = (check, text) => {
    const { saveCheckItem } = this.props;
    const {
      checkItemDialog: { componentGuid, itemId, itemParents },
    } = this.state;

    return saveCheckItem(
      componentGuid,
      check,
      text,
      itemId,
      itemParents,
      false
    );
  };

  postUserCheckItemEdit = (checkId, check, text) => {
    const { postCheckItemEdit, checkItems } = this.props;

    const {
      checkItemDialog: { componentGuid, itemId, itemParents },
    } = this.state;

    const checkitem = checkItems.find((item) => item.id === checkId);
    checkitem.check = check;

    const allItemChecked = checkItems.every((item) => item.check === true);

    return postCheckItemEdit(
      componentGuid,
      checkId,
      check,
      text,
      itemId,
      itemParents,
      allItemChecked
    );
  };

  removeUserCheckItem = (checkId) => {
    const { removeCheckItem, checkItems } = this.props;

    const {
      checkItemDialog: { componentGuid, itemId, itemParents },
    } = this.state;

    const checkItemIndex = checkItems.findIndex((x) => x.id === checkId);

    const checkItemsList = [
      ...checkItems.slice(0, checkItemIndex),
      ...checkItems.slice(checkItemIndex + 1),
    ];

    const hasItem = checkItemsList.length > 0;
    const allItemChecked =
      hasItem && checkItemsList.every((item) => item.check === true);

    return removeCheckItem(
      componentGuid,
      checkId,
      itemId,
      itemParents,
      hasItem,
      allItemChecked
    );
  };

  showErrorMessage = (err) => {
    AppToaster.show({
      message: err,
      intent: Intent.DANGER,
    });
  };

  renderProjects = () => {
    const {
      props: {
        projects,
        users,
        selectedProject,
        savingItem,
        currentUser,
        comments,
        commentLoading,
        attachments,
        attachmentLoading,
        checkItems,
        checkItemLoading,
        itemDialogOpened,
      },
      state: {
        itemDialog: { itemType, item, itemParents },
        userDialog,
        commentDialog,
        attachmentDialog,
        checkItemDialog,
      },
    } = this;

    return (
      <React.Fragment>
        <ProjectSummaryHeader
          projects={projects}
          selectedProject={selectedProject}
          projectSelected={this.projectSelected}
          addGoalClicked={this.openComponentDialog}
          userActions={{
            itemOrderChanged: this.itemOrderChanged,
            editButtonClicked: this.editItemClicked,
            addButtonClicked: this.addItemClicked,
            itemUsersButtonClicked: this.itemUsersClicked,
            deleteButtonClicked: this.deleteItemClicked,
            commentButtonClick: this.openCommentDialog,
            attachmentButtonClick: this.openAttachmentDialog,
            checkItemButtonClick: this.openCheckItemDialog,
          }}
          currentUser={currentUser}
        />

        <ProjectSummarySection
          project={selectedProject}
          queryValues={this.getQueryValues()}
          userActions={{
            itemOrderChanged: this.itemOrderChanged,
            editButtonClicked: this.editItemClicked,
            addButtonClicked: this.addItemClicked,
            itemUsersButtonClicked: this.itemUsersClicked,
            deleteButtonClicked: this.deleteItemClicked,
            commentButtonClick: this.openCommentDialog,
            attachmentButtonClick: this.openAttachmentDialog,
            checkItemButtonClick: this.openCheckItemDialog,
          }}
          currentUser={currentUser}
        />

        <ItemDialog
          item={item}
          isOpen={itemDialogOpened}
          closeDialog={this.closeComponentDialog}
          itemType={itemType}
          users={users}
          projectItemUpdated={this.projectItemUpdated}
          loading={savingItem}
          validationDates={this.getItemValidationDates(itemParents)}
        />

        {userDialog.isOpen && (
          <UserDialog
            item={userDialog.item}
            loading={savingItem}
            isOpen={userDialog.isOpen}
            submit={this.assignUsers}
            closeDialog={this.closeUserDialog}
            users={users}
          />
        )}

        <CommentDialog
          comments={comments}
          loading={commentLoading}
          componentGuid={commentDialog.componentGuid}
          isOpen={commentDialog.isOpen}
          submit={this.saveUserComment}
          onClose={this.closeCommentDialog}
        />

        <AttachmentDialog
          attachments={attachments}
          loading={attachmentLoading}
          componentGuid={attachmentDialog.componentGuid}
          isOpen={attachmentDialog.isOpen}
          submit={this.saveUserAttachment}
          fetchFile={this.fetchUserAttachmentFile}
          removeAttachment={this.removeUserAttachment}
          onClose={this.closeAttachmentDialog}
        />

        <CheckItemDialog
          checkItems={checkItems}
          loading={checkItemLoading}
          componentGuid={checkItemDialog.componentGuid}
          isOpen={checkItemDialog.isOpen}
          submit={this.saveUserCheckItem}
          checkChange={this.postUserCheckItemEdit}
          checkRemove={this.removeUserCheckItem}
          onClose={this.closeCheckItemDialog}
          canEditCheck={checkItemDialog.canEdit}
        />
      </React.Fragment>
    );
  };

  renderNoProjects = () => <h3>Aucun projet ne vous a été associé</h3>;

  render() {
    const { projects, loading } = this.props;
    let content;

    if (loading) {
      content = <LoadingOverlay />;
    } else if (projects.length > 0) {
      content = this.renderProjects();
    } else {
      content = this.renderNoProjects();
    }

    return (
      <PageLayout>
        <H2>
          <FormattedMessage {...messages.title} />
        </H2>
        {content}
      </PageLayout>
    );
  }
}

const mapStateToProps = ({
  project,
  user,
  item,
  account,
  comment,
  attachment,
  checkItem,
}) => ({
  projects: project.projects,
  users: user.users,
  selectedProject: project.projects.find(
    (x) => x.id === project.selectedProjectId
  ),
  loading: project.loading || user.isFetchingData,
  savingItem: item.loading,
  currentUser: account,
  comments: comment.comments,
  commentLoading: comment.loading,
  attachments: attachment.attachments,
  attachmentLoading: attachment.loading,
  checkItems: checkItem.checkItems,
  checkItemLoading: checkItem.loading,
  itemDialogOpened: item.itemDialogOpened,
});

ProjectSummaryPage.defaultProps = {
  users: [],
  projects: [],
  selectedProject: {},
};

ProjectSummaryPage.propTypes = {
  selectedProject: PropTypes.shape(),
  users: PropTypes.arrayOf(PropTypes.object),
  projects: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  orderItems: PropTypes.func.isRequired,
  assignUsers: PropTypes.func.isRequired,
  saveComment: PropTypes.func.isRequired,
  fetchComments: PropTypes.func.isRequired,
  saveAttachment: PropTypes.func.isRequired,
  fetchAttachmentFile: PropTypes.func.isRequired,
  fetchAttachments: PropTypes.func.isRequired,
  removeAttachment: PropTypes.func.isRequired,
  saveCheckItem: PropTypes.func.isRequired,
  postCheckItemEdit: PropTypes.func.isRequired,
  removeCheckItem: PropTypes.func.isRequired,
  fetchCheckItems: PropTypes.func.isRequired,
  fetchProjects: PropTypes.func.isRequired,
  setSelectedProject: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  {
    fetchProjects: projectActions.fetchProjects,
    setSelectedProject: projectActions.setSelectedProject,
    orderItems: itemActions.reorderItem,
    updateItem: itemActions.updateItem,
    deleteItem: itemActions.deleteItem,
    assignUsers: itemActions.assignUsers,
    fetchUsers: userAction.fetchUsers,
    fetchComments: commentAction.fetchComments,
    saveComment: commentAction.saveComment,
    saveAttachment: attachmentAction.saveAttachment,
    removeAttachment: attachmentAction.removeAttachment,
    fetchAttachmentFile: attachmentAction.fetchAttachmentFile,
    fetchAttachments: attachmentAction.fetchAttachments,
    saveCheckItem: checkItemAction.saveCheckItem,
    postCheckItemEdit: checkItemAction.postCheckItemEdit,
    removeCheckItem: checkItemAction.removeCheckItem,
    fetchCheckItems: checkItemAction.fetchCheckItems,
    openItemDialog: itemActions.openItemDialog,
    closeItemDialog: itemActions.closeItemDialog,
  }
)(ProjectSummaryPage);
