import React from "react";
import styled from "styled-components";
import { Intent, Card, Elevation, Button as BpButton } from "@blueprintjs/core";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import Validator from "validator";
import { H2, H3 } from "../../../components/text/Headers";
import { InputGroup, Input, Label } from "../../../components/forms";
import { Button, ButtonSize } from "../../../components/buttons";
import AppToaster from "../../../components/toasters/AppToaster";
import messages from "./ProfileForm.messages";
import { Flex } from "../../../components";
import { DisplayStyle } from "../../../components/forms/styles/Styles";
import Avatar from "../../../components/avatar/Avatar";

const StyledCard = styled(Card)`
  margin: 10px 0px;
`;

const ProfileFormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

const ButtonSection = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
  width: 100%;
`;

const Action = styled.div`
  margin: 5px 0px;
`;

class ProfileForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        firstname: "",
        lastname: "",
        email: "",
        phoneNumber: "",
        avatarLink: "",
        position: "",
        isUnsubscribe: false,
      },
      loading: false,
      errors: {},
    };
  }

  componentWillReceiveProps(newProps) {
    const { profile, loading } = newProps;
    if (Object.keys(profile).length > 0) {
      console.log(profile);

      this.setState({
        data: {
          firstname: profile.firstname,
          lastname: profile.lastname,
          email: profile.email,
          phoneNumber: profile.phoneNumber != null ? profile.phoneNumber : "",
          position: profile.position != null ? profile.position : "",
          avatarLink: profile.avatarLink,
          isUnsubscribe: profile.isUnsubscribe,
        },
        loading,
      });
    } else {
      this.setState({ loading });
    }
  }

  onChange = ({ target: { value, name } }) => {
    const inputName = name;
    const inputValue = value;

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [inputName]: inputValue,
      },
    }));
  };

  onSubmit = (event) => {
    event.preventDefault();
    const {
      state: { data },
      props: { submit, intl },
    } = this;

    const errors = this.validate(data, intl);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      submit(data)
        .then(() => {
          this.showSuccessToaster(
            intl.formatMessage(messages.general.saveSuccess)
          );
        })
        .catch((error) => {
          this.showErrorToaster(error);
        });
    }
  };

  showSuccessToaster = (message) => {
    AppToaster.show({ message, intent: Intent.SUCCESS });
  };

  showErrorToaster = (error) => {
    AppToaster.show({ message: error, intent: Intent.DANGER });
  };

  validate = (data, intl) => {
    const errors = {};
    if (!data.email)
      errors.email = intl.formatMessage(messages.errors.fieldIsRequired);

    if (!data.firstname)
      errors.firstname = intl.formatMessage(messages.errors.fieldIsRequired);

    if (!data.lastname)
      errors.lastname = intl.formatMessage(messages.errors.fieldIsRequired);

    if (data.email && !Validator.isEmail(data.email))
      errors.email = intl.formatMessage(messages.errors.invalidEmail);

    if (data.phoneNumber && !Validator.isMobilePhone(data.phoneNumber))
      errors.phoneNumber = intl.formatMessage(
        messages.errors.invalidPhoneNumber
      );
    return errors;
  };

  render() {
    const {
      state: {
        data: {
          firstname,
          lastname,
          email,
          phoneNumber,
          position,
          avatarLink,
          isUnsubscribe,
        },
        loading,
        errors,
      },
      props: { updatePasswordClicked, updateAvatarClicked },
    } = this;

    return (
      <ProfileFormContainer onSubmit={this.onSubmit}>
        <Flex direction="column" align="center">
          <H2>
            <FormattedMessage {...messages.profile.myProfile} />
          </H2>

          {avatarLink && <Avatar avatarLink={avatarLink} />}
        </Flex>
        <StyledCard elevation={Elevation.TWO}>
          <H3>
            <FormattedMessage {...messages.profile.generalInformation} />
          </H3>
          <Flex mobile>
            <InputGroup error={errors.firstname} grow="2">
              <Label htmlFor="firstname">
                <FormattedMessage {...messages.fields.firstname} />
              </Label>
              <Input
                value={firstname}
                name="firstname"
                onChange={this.onChange}
                type="text"
                disabled={loading}
              />
            </InputGroup>

            <InputGroup error={errors.lastname} grow="2">
              <Label htmlFor="lastname">
                <FormattedMessage {...messages.fields.lastname} />
              </Label>
              <Input
                value={lastname}
                name="lastname"
                onChange={this.onChange}
                type="text"
                disabled={loading}
              />
            </InputGroup>
          </Flex>

          <Flex mobile>
            <InputGroup error={errors.email} grow="2">
              <Label htmlFor="email">
                <FormattedMessage {...messages.fields.email} />
              </Label>
              <Input
                value={email}
                name="email"
                onChange={this.onChange}
                type="text"
                disabled={loading}
              />
            </InputGroup>

            <InputGroup error={errors.phoneNumber} grow="2">
              <Label htmlFor="phoneNumber">
                <FormattedMessage {...messages.fields.phoneNumber} />
              </Label>
              <Input
                id="phoneNumber"
                value={phoneNumber}
                name="phoneNumber"
                onChange={this.onChange}
                type="text"
                disabled={loading}
              />
            </InputGroup>
          </Flex>

          <Flex mobile>
            <InputGroup error={errors.position} grow="2">
              <Label htmlFor="position">
                <FormattedMessage {...messages.fields.position} />
              </Label>
              <Input
                id="position"
                value={position}
                name="position"
                onChange={this.onChange}
                type="text"
                disabled={loading}
              />
            </InputGroup>
          </Flex>

          <InputGroup
            error={errors.isUnsubscribe}
            displayStyle={DisplayStyle.INLINE}
          >
            <Label htmlFor="isUnsubscribe">
              <FormattedMessage {...messages.fields.unsubscribe} />
            </Label>
            <Input
              name="isUnsubscribe"
              id="isUnsubscribe"
              checked={isUnsubscribe}
              value={`${isUnsubscribe}`}
              onChange={(e) => {
                this.onChange({
                  target: {
                    name: e.target.name,
                    value: e.target.checked,
                  },
                });
              }}
              disabled={loading}
              type="checkbox"
            />
          </InputGroup>
        </StyledCard>

        <StyledCard elevation={Elevation.TWO}>
          <H3>
            <FormattedMessage {...messages.profile.additionalActions} />
          </H3>
          <Action>
            <BpButton
              intent={Intent.PRIMARY}
              onClick={updatePasswordClicked}
              className="default-button"
            >
              <FormattedMessage {...messages.profile.updatePassword} />
            </BpButton>
          </Action>
          <Action>
            <BpButton
              intent={Intent.PRIMARY}
              onClick={updateAvatarClicked}
              className="default-button"
            >
              <FormattedMessage {...messages.profile.updateAvatar} />
            </BpButton>
          </Action>
        </StyledCard>

        <ButtonSection>
          <Button
            type="submit"
            intent={Intent.SUCCESS}
            loading={loading}
            size={ButtonSize.MEDIUM}
          >
            <FormattedMessage {...messages.general.save} />
          </Button>
        </ButtonSection>
      </ProfileFormContainer>
    );
  }
}

ProfileForm.propTypes = {
  submit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ProfileForm);
