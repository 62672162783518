import styled from "styled-components";

const ForgotPasswordFormContainer = styled.div`
  background: ${props => props.theme.white};
  width: 300px;
  border-radius: 20px;
  padding: 20px;

  text-align: center;
  box-shadow: 8px 8px 58px 0 rgba(0, 0, 0, 0.5);
`;

export default ForgotPasswordFormContainer;
