import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";
import { Provider } from "react-redux";
import decode from "jwt-decode";
import { ThemeProvider } from "styled-components";
import { StripeProvider } from "react-stripe-elements";
import store from "./store";
import theme from "./themes/theme";
import App from "./components/app";
import * as serviceWorker from "./serviceWorker";
import setAuthorizationHeader from "./utils/setAuthorizationHeader";
import { userLoggedIn, getAccountFromToken } from "./actions/auth";
import "normalize.css";
import "./index.scss";
import ConnectedIntlProvider from "./translations/ConnectedIntlProvider";
import ConnectedStripeElements from "./translations/ConnectedStripeElements";
import { setLanguage } from "./actions/locale";

const { token, lang } = localStorage;

if (lang) {
  store.dispatch(setLanguage(lang));
}

if (token) {
  const payload = decode(token);

  const expirationDate = new Date(payload.exp * 1000);

  if (expirationDate > Date.now()) {
    const account = getAccountFromToken(token);
    setAuthorizationHeader(token);
    store.dispatch(userLoggedIn(account));
  }
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedIntlProvider>
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_API_KEY}>
        <ConnectedStripeElements>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <Route component={App} />
            </ThemeProvider>
          </BrowserRouter>
        </ConnectedStripeElements>
      </StripeProvider>
    </ConnectedIntlProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
