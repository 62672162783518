import { defineMessages } from "react-intl";

const messages = {
  register: {
    id: "RegisterCompanyForm.register",
    description: "Register button text"
  },
  administrator: { id: "RegisterForm.administrator" },
  company: { id: "RegisterForm.company" },
  alreadyHaveAnAccount: {
    id: "RegisterForm.alreadyHaveAnAccount",
    description:
      "Text for the already have an account under the register button"
  },
  errors: {
    fieldIsRequired: { id: "Form.errors.fieldIsRequired" },
    confirmPassword: { id: "Form.errors.confirmPassword" },
    termsAndConditions: { id: "Form.errors.termsAndConditions" },
    invalidEmail: { id: "Form.errors.invalidEmail" },
    fieldIsTooShort: { id: "Form.errors.fieldIsTooShort" }
  },
  fields: {
    email: {
      id: "Form.fields.email",
      description: "Email field name"
    },
    firstname: {
      id: "Form.fields.firstname",
      description: "Firstname field name"
    },
    lastname: {
      id: "Form.fields.lastname",
      description: "Lastname field name"
    },
    password: {
      id: "Form.fields.password",
      description: "Password field name"
    },
    confirmPassword: {
      id: "Form.fields.confirmPassword",
      description: "Confirm password field name"
    },
    companyName: {
      id: "Form.fields.companyName",
      description: "Company name field name"
    },
    street: {
      id: "Form.fields.address",
      description: "Address field name"
    },
    city: {
      id: "Form.fields.city",
      description: "City field name"
    },
    zipCode: {
      id: "Form.fields.zipCode",
      description: "Zipcode field name"
    },
    state: {
      id: "Form.fields.state",
      description: "State field name"
    },
    country: {
      id: "Form.fields.country",
      description: "Country field name"
    },
    phoneNumber: {
      id: "Form.fields.phoneNumber",
      description: "Phonenumber field name"
    },
    termsAndConditions: { id: "Form.fields.termsAndConditions" }
  }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
