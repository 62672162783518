// *** User Types *** //
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";

export const FETCH_USERS_BEGIN = "FETCH_USERS_BEGIN";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

export const PUT_USER_BEGIN = "PUT_USERS_BEGIN";
export const PUT_USER_SUCCESS = "PUT_USERS_SUCCESS";
export const PUT_USER_FAILURE = "PUT_USERS_FAILURE";

export const POST_USER_BEGIN = "POST_USERS_BEGIN";
export const POST_USER_SUCCESS = "POST_USERS_SUCCESS";
export const POST_USER_FAILURE = "POST_USERS_FAILURE";

export const DELETE_USER_BEGIN = "DELETE_USERS_BEGIN";
export const DELETE_USER_SUCCESS = "DELETE_USERS_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USERS_FAILURE";

// *** Profile Types *** //

export const FETCH_PROFILE_BEGIN = "FETCH_PROFILE_BEGIN";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";

export const PUT_PROFILE_BEGIN = "PUT_PROFILE_BEGIN";
export const PUT_PROFILE_SUCCESS = "PUT_PROFILE_SUCCESS";
export const PUT_PROFILE_FAILURE = "PUT_PROFILE_FAILURE";

// ***  Project Types *** //

export const FETCH_PROJECTS_BEGIN = "FETCH_PROJECT_BEGIN";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECT_SUCCESS";
export const FETCH_PROJECTS_FAILURE = "FETCH_PROJECT_FAILURE";

export const POST_PROJECT_BEGIN = "POST_PROJECT_BEGIN";
export const POST_PROJECT_SUCCESS = "POST_PROJECT_SUCCESS";
export const POST_PROJECT_FAILURE = "POST_PROJECT_FAILURE";

export const PUT_PROJECT_BEGIN = "PUT_PROJECT_BEGIN";
export const PUT_PROJECT_SUCCESS = "PUT_PROJECT_SUCCESS";
export const PUT_PROJECT_FAILURE = "PUT_PROJECT_FAILURE";

export const DELETE_PROJECT_BEGIN = "DELETE_PROJECT_BEGIN";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAILURE = "DELETE_PROJECT_FAILURE";

export const DOWNLOAD_PROJECT_BEGIN = "DOWNLOAD_PROJECT_BEGIN";
export const DOWNLOAD_PROJECT_SUCCESS = "DOWNLOAD_PROJECT_SUCCESS";
export const DOWNLOAD_PROJECT_FAILURE = "DOWNLOAD_PROJECT_FAILURE";

export const SET_SELECTED_PROJECT = "SET_SELECTED_PROJECT";

export const PROJECT_ORDER_ITEM_BEGIN = "PROJECT_ORDER_ITEM_BEGIN";
export const PROJECT_ORDER_ITEM_SUCCESS = "PROJECT_ORDER_ITEM_SUCCESS";
export const PROJECT_ORDER_ITEM_FAILURE = "ORDER_ITEM_FAILURE";

export const OPEN_PROJECT_DIALOG = "OPEN_PROJECT_DIALOG";
export const CLOSE_PROJECT_DIALOG = "CLOSE_PROJECT_DIALOG";

// *** Item types *** //
export const POST_ITEM_BEGIN = "POST_ITEM_BEGIN";
export const POST_ITEM_SUCCESS = "POST_ITEM_SUCCESS";
export const POST_ITEM_FAILURE = "POST_ITEM_FAILURE";

export const PUT_ITEM_BEGIN = "PUT_ITEM_BEGIN";
export const PUT_ITEM_SUCCESS = "PUT_ITEM_SUCCESS";
export const PUT_ITEM_FAILURE = "PUT_ITEM_FAILURE";

export const DELETE_ITEM_BEGIN = "DELETE_ITEM_BEGIN";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_FAILURE = "DELETE_ITEM_FAILURE";

export const PUT_USERS_ITEM_BEGIN = "PUT_USERS_ITEM_BEGIN";
export const PUT_USERS_ITEM_SUCCESS = "PUT_USERS_ITEM_SUCCESS";
export const PUT_USERS_ITEM_FAILURE = "PUT_USERS_ITEM_FAILURE";

export const ORDER_ITEM_BEGIN = "ORDER_ITEM_BEGIN";
export const ORDER_ITEM_SUCCESS = "ORDER_ITEM_SUCCESS";
export const ORDER_ITEM_FAILURE = "ORDER_ITEM_FAILURE";

export const OPEN_ITEM_DIALOG = "OPEN_ITEM_DIALOG";
export const CLOSE_ITEM_DIALOG = "CLOSE_ITEM_DIALOG";

// *** Licence *** //
export const SET_LICENCE_INFORMATIONS = "SET_LICENCE_INFORMATIONS";

export const FETCH_LICENCE_TYPES_BEGIN = "FETCH_LICENCE_TYPES_BEGIN";
export const FETCH_LICENCE_TYPES_SUCCESS = "FETCH_LICENCE_TYPES_SUCCESS";
export const FETCH_LICENCE_TYPES_FAILURE = "FETCH_LICENCE_TYPES_FAILURE";

export const UPDATE_LICENCE_BEGIN = "UPDATE_LICENCE_BEGIN";
export const UPDATE_LICENCE_SUCCESS = "UPDATE_LICENCE_SUCCESS";
export const UPDATE_LICENCE_FAILURE = "UPDATE_LICENCE_FAILURE";

export const FETCH_PRORATION_BEGIN = "FETCH_PRORATION_BEGIN";
export const FETCH_PRORATION_SUCCESS = "FETCH_PRORATION_SUCCESS";
export const FETCH_PRORATION_FAILURE = "FETCH_PRORATION_FAILURE";

export const SET_LOCALE = "SET_LOCALE";

// *** Tasks *** //
export const FETCH_TASKS_BEGIN = "FETCH_TASKS_BEGIN";
export const FETCH_TASKS_SUCCESS = "FETCH_TASKS_SUCCESS";
export const FETCH_TASKS_FAILURE = "FETCH_TASKS_FAILURE";

// *** STRIPE *** //
export const STRIPE_CREATE_TOKEN_BEGIN = "STRIPE_CREATE_TOKEN_BEGIN";
export const STRIPE_CREATE_TOKEN_SUCCESS = "STRIPE_CREATE_TOKEN_SUCCESS";
export const STRIPE_CREATE_TOKEN_FAILURE = "STRIPE_CREATE_TOKEN_FAILURE";

export const UPDATE_PASSWORD_BEGIN = "UPDATE_PASSWORD_BEGIN";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";

export const UPDATE_AVATAR_BEGIN = "UPDATE_AVATAR_BEGIN";
export const UPDATE_AVATAR_SUCCESS = "UPDATE_AVATAR_SUCCESS";
export const UPDATE_AVATAR_FAILURE = "UPDATE_AVATAR_FAILURE";

export const DISABLE_RECURING_BILLING_BEGIN = "DISABLE_RECURING_BILLING_BEGIN";
export const DISABLE_RECURING_BILLING_SUCCESS =
  "DISABLE_RECURING_BILLING_SUCCESS";
export const DISABLE_RECURING_BILLING_FAILURE =
  "DISABLE_RECURING_BILLING_FAILURE";

export const FETCH_PAYMENT_OPTIONS_BEGIN = "FETCH_PAYMENT_OPTIONS_BEGIN";
export const FETCH_PAYMENT_OPTIONS_SUCCESS = "FETCH_PAYMENT_OPTIONS_SUCCESS";
export const FETCH_PAYMENT_OPTIONS_FAILURE = "FETCH_PAYMENT_OPTIONS_FAILURE";

export const FETCH_CREDIT_CARD_BEGIN = "FETCH_CREDIT_CARD_BEGIN";
export const FETCH_CREDIT_CARD_SUCCESS = "FETCH_CREDIT_CARD_SUCCESS";
export const FETCH_CREDIT_CARD_FAILURE = "FETCH_CREDIT_CARD_FAILURE";

export const UPDATE_CREDIT_CARD_BEGIN = "UPDATE_CREDIT_CARD_BEGIN";
export const UPDATE_CREDIT_CARD_SUCCESS = "UPDATE_CREDIT_CARD_SUCCESS";
export const UPDATE_CREDIT_CARD_FAILURE = "UPDATE_CREDIT_CARD_FAILURE";

export const FETCH_COMMENTS_BEGIN = "FETCH_COMMENTS_BEGIN";
export const FETCH_COMMENTS_SUCCESS = "FETCH_COMMENTS_SUCCESS";
export const FETCH_COMMENTS_FAILURE = "FETCH_COMMENTS_FAILURE";

export const SAVE_COMMENT_BEGIN = "SAVE_COMMENT_BEGIN";
export const SAVE_COMMENT_SUCCESS = "SAVE_COMMENT_SUCCESS";
export const SAVE_COMMENT_FAILURE = "SAVE_COMMENT_FAILURE";

export const FETCH_ATTACHMENTS_BEGIN = "FETCH_ATTACHMENTS_BEGIN";
export const FETCH_ATTACHMENTS_SUCCESS = "FETCH_ATTACHMENTS_SUCCESS";
export const FETCH_ATTACHMENTS_FAILURE = "FETCH_ATTACHMENTS_FAILURE";

export const FETCH_ATTACHMENT_FILE_BEGIN = "FETCH_ATTACHMENT_FILE_BEGIN";
export const FETCH_ATTACHMENT_FILE_SUCCESS = "FETCH_ATTACHMENT_FILE_SUCCESS";
export const FETCH_ATTACHMENT_FILE_FAILURE = "FETCH_ATTACHMENT_FILE_FAILURE";

export const SAVE_ATTACHMENT_BEGIN = "SAVE_ATTACHMENT_BEGIN";
export const SAVE_ATTACHMENT_SUCCESS = "SAVE_ATTACHMENT_SUCCESS";
export const SAVE_ATTACHMENT_FAILURE = "SAVE_ATTACHMENT_FAILURE";

export const REMOVE_ATTACHMENT_BEGIN = "REMOVE_ATTACHMENT_BEGIN";
export const REMOVE_ATTACHMENT_SUCCESS = "REMOVE_ATTACHMENT_SUCCESS";
export const REMOVE_ATTACHMENT_FAILURE = "REMOVE_ATTACHMENT_FAILURE";

export const FETCH_CHECKITEMS_BEGIN = "FETCH_CHECKITEMS_BEGIN";
export const FETCH_CHECKITEMS_SUCCESS = "FETCH_CHECKITEMS_SUCCESS";
export const FETCH_CHECKITEMS_FAILURE = "FETCH_CHECKITEMS_FAILURE";

export const POST_CHECKITEM_EDIT_BEGIN = "POST_CHECKITEM_EDIT_BEGIN";
export const POST_CHECKITEM_EDIT_SUCCESS = "POST_CHECKITEM_EDIT_SUCCESS";
export const POST_CHECKITEM_EDIT_FAILURE = "POST_CHECKITEM_EDIT_FAILURE";

export const SAVE_CHECKITEM_BEGIN = "SAVE_CHECKITEM_BEGIN";
export const SAVE_CHECKITEM_SUCCESS = "SAVE_CHECKITEM_SUCCESS";
export const SAVE_CHECKITEM_FAILURE = "SAVE_CHECKITEM_FAILURE";

export const REMOVE_CHECKITEM_BEGIN = "REMOVE_CHECKITEM_BEGIN";
export const REMOVE_CHECKITEM_SUCCESS = "REMOVE_CHECKITEM_SUCCESS";
export const REMOVE_CHECKITEM_FAILURE = "REMOVE_CHECKITEM_FAILURE";

// ***  LOG Types *** //

export const FETCH_LOGS_BEGIN = "FETCH_LOGS_BEGIN";
export const FETCH_LOGS_SUCCESS = "FETCH_LOGS_SUCCESS";
export const FETCH_LOGS_FAILURE = "FETCH_LOGS_FAILURE";
