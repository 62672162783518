import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Alignment } from "@blueprintjs/core";
import { H2 } from "../../text/Headers";
import { Flex } from "../..";
import messages from "../Users.messages";

const Header = ({ intl, inviteUserClicked, informations }) => (
  <Flex justify="space-between" align="center" margin="10px 0px">
    <H2>
      <FormattedMessage {...messages.general.userManagement} />
    </H2>
    <Flex justify="space-between" align="center" direction="column">
      <Button
        text={intl.formatMessage(messages.general.inviteUser)}
        onClick={inviteUserClicked}
        className="default-button"
        alignText={Alignment.CENTER}
      />
      {informations && Object.keys(informations).length > 0 && (
        <p>
          {informations.userCount}
          {informations.maximumUserCount > 0
            ? ` / ${informations.maximumUserCount}`
            : ""}{" "}
          {intl.formatMessage(messages.general.users)}
        </p>
      )}
    </Flex>
  </Flex>
);

export default Header;
