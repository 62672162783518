import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import PublicRoute from "../../routes/PublicRoute";
import LoginPage from "../../../containers/pages/publicPage/loginPage";
import UnsubscribePage from "../../../containers/pages/publicPage/unsubscribePage";
import RegisterPage from "../../../containers/pages/publicPage/registerPage";
import RegisterCompanyPage from "../../../containers/pages/publicPage/registerCompanyPage";
import ForgotPasswordPage from "../../../containers/pages/publicPage/forgotPasswordPage";
import ResetPasswordPage from "../../../containers/pages/publicPage/resetPasswordPage";

const AppPublicRoutes = ({ location }) => (
  <React.Fragment>
    <PublicRoute
      location={location}
      path="/"
      exact
      component={() => <Redirect to="/login" />}
    />
    <PublicRoute
      location={location}
      path="/login"
      exact
      component={LoginPage}
    />
    <PublicRoute
      location={location}
      path="/unsubscribe"
      dontRedirectIfAuthenticated
      exact
      component={UnsubscribePage}
    />
    <PublicRoute
      location={location}
      path="/register"
      exact
      component={RegisterPage}
    />
    <PublicRoute
      location={location}
      path="/register-company"
      exact
      component={RegisterCompanyPage}
    />
    <PublicRoute
      location={location}
      path="/forgot-password"
      exact
      component={ForgotPasswordPage}
    />
    <PublicRoute
      location={location}
      path="/reset-password"
      component={ResetPasswordPage}
    />
  </React.Fragment>
);

AppPublicRoutes.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default AppPublicRoutes;
