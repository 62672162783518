import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const RoleRoute = ({
  isAuthenticated,
  minRole,
  userRole,
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated && userRole >= minRole ? (
        <Component {...props} />
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

RoleRoute.propTypes = {
  component: PropTypes.func.isRequired,
  minRole: PropTypes.number.isRequired
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.account.token,
    userRole: state.account.role
  };
}

export default connect(mapStateToProps)(RoleRoute);
