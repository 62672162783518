import React from "react";
import { SortableContainer } from "react-sortable-hoc";
import PropTypes from "prop-types";

const Container = SortableContainer(({ children }) => <div>{children}</div>);

const onItemOrderChange = (values, itemOrderChanged, parents) => {
  itemOrderChanged(values, parents);
};

const ItemSortableContainer = ({
  children,
  itemOrderChanged,
  goalId,
  strategyId
}) => (
  <Container
    useDragHandle
    pressDelay={150}
    onSortEnd={values =>
      onItemOrderChange(values, itemOrderChanged, { goalId, strategyId })
    }
    helperClass="dragging-helper-class"
  >
    {children}
  </Container>
);

ItemSortableContainer.defaultProps = {
  children: null,
  goalId: 0,
  strategyId: 0
};

ItemSortableContainer.propTypes = {
  children: PropTypes.node,
  itemOrderChanged: PropTypes.func.isRequired,
  goalId: PropTypes.number,
  strategyId: PropTypes.number
};

export default ItemSortableContainer;
