import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const HasRole = ({ children, currentRole, minRole, maxRole, exact }) => {
  if (exact && currentRole === minRole) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  if (
    !exact &&
    currentRole >= minRole &&
    (maxRole === undefined || currentRole <= maxRole)
  ) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return null;
};

function mapStateToProps(state) {
  return {
    currentRole: parseInt(state.account.role, 10)
  };
}

HasRole.defaultProps = {
  exact: false,
  maxRole: undefined
};

HasRole.propTypes = {
  children: PropTypes.node.isRequired,
  currentRole: PropTypes.number.isRequired,
  minRole: PropTypes.number.isRequired,
  exact: PropTypes.bool,
  maxRole: PropTypes.number
};

export default connect(mapStateToProps)(HasRole);
