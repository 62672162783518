import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as actions from "../../../../actions/auth";
import LoginForm from "../../../forms/login";
import PageLayout from "../PageLayout";

class LoginPage extends React.Component {
  submit = data => {
    const {
      props: { login }
    } = this;

    return login(data);
  };

  render() {
    return (
      <PageLayout>
        <LoginForm submit={this.submit} />
      </PageLayout>
    );
  }
}

LoginPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  login: PropTypes.func.isRequired
};

export default connect(
  null,
  { login: actions.login }
)(LoginPage);
