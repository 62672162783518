import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Intent } from "@blueprintjs/core";
import { injectIntl, intlShape } from "react-intl";
import PageLayout from "../PageLayout";
import * as actions from "../../../../actions/user";
import { LoadingOverlay } from "../../../../components/overlays";
import {
  UsersTable,
  UserDialog,
  InviteUserDialog,
  Header,
  messages
} from "../../../../components/users";
import AppToaster from "../../../../components/toasters";
import DeleteAlert from "../../../../components/alerts/DeleteAlert";

class UsersManagementPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userDialog: {
        isOpen: false,
        user: undefined
      },
      inviteDialog: {
        isOpen: false
      },
      deleteAlert: {
        isOpen: false,
        userId: 0
      }
    };
  }

  componentDidMount() {
    const { fetchUsers } = this.props;

    fetchUsers();
  }

  closeDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      userDialog: {
        isOpen: false,
        user: undefined
      }
    }));
  };

  closeInviteDialog = () => {
    this.setState(prevState => ({
      ...prevState,
      inviteDialog: {
        isOpen: false
      }
    }));
  };

  userRoleSelected = ({ role }, userId) => {
    const { updateUserRole, users } = this.props;

    updateUserRole(role, users.find(x => x.id === userId));
  };

  userEditSubmit = user => {
    const { updateUser, intl } = this.props;

    updateUser(user)
      .then(() => {
        this.closeDialog();
        this.showSuccessToast(
          intl.formatMessage(messages.general.userEditSuccess)
        );
      })
      .catch(error => {
        this.showErrorToast(error);
      });
  };

  userDeleteClicked = userId => {
    this.setState(prevState => ({
      ...prevState,
      deleteAlert: {
        isOpen: true,
        userId
      }
    }));
  };

  closeDeleteAlert = () => {
    this.setState(prevState => ({
      ...prevState,
      deleteAlert: {
        isOpen: false,
        projectId: 0
      }
    }));
  };

  userInviteSubmit = user => {
    const { inviteUser, intl } = this.props;

    return inviteUser(user)
      .then(() => {
        this.closeInviteDialog();
        this.showSuccessToast(
          intl.formatMessage(messages.general.userInviteSuccess)
        );
      })
      .catch(error => {
        this.showErrorToast(error);
      });
  };

  userEditClicked = userId => {
    const { users } = this.props;

    this.setState(prevState => ({
      ...prevState,
      userDialog: {
        isOpen: true,
        user: users.find(x => x.id === userId)
      }
    }));
  };

  deleteUser = () => {
    const { deleteUser } = this.props;
    const { deleteAlert } = this.state;

    deleteUser(deleteAlert.userId);
    this.closeDeleteAlert();
  };

  inviteUserClicked = () => {
    this.setState(prevState => ({
      ...prevState,
      inviteDialog: {
        isOpen: true
      }
    }));
  };

  showSuccessToast = message => {
    AppToaster.show({
      message,
      intent: Intent.SUCCESS
    });
  };

  showErrorToast = error => {
    AppToaster.show({
      message: error,
      intent: Intent.DANGER
    });
  };

  render() {
    const {
      props: {
        fetchUserLoading,
        saveUserLoading,
        users,
        intl,
        currentUserId,
        isDeletingLoading,
        licence: { informations }
      },
      state: { userDialog, inviteDialog, deleteAlert }
    } = this;
    return (
      <PageLayout>
        {(fetchUserLoading || isDeletingLoading) && <LoadingOverlay />}
        <Header
          intl={intl}
          informations={informations}
          inviteUserClicked={this.inviteUserClicked}
        />

        <UsersTable
          users={users}
          userRoleSelected={this.userRoleSelected}
          userEditClicked={this.userEditClicked}
          userDeleteClicked={this.userDeleteClicked}
          currentUserId={currentUserId}
        />

        {userDialog.isOpen && (
          <UserDialog
            isOpen={userDialog.isOpen}
            user={userDialog.user}
            onClose={this.closeDialog}
            submit={this.userEditSubmit}
            loading={saveUserLoading}
          />
        )}

        <InviteUserDialog
          isOpen={inviteDialog.isOpen}
          onClose={this.closeInviteDialog}
          submit={this.userInviteSubmit}
          loading={saveUserLoading}
        />

        <DeleteAlert
          isOpen={deleteAlert.isOpen}
          onCancel={this.closeDeleteAlert}
          onConfirm={this.deleteUser}
        />
      </PageLayout>
    );
  }
}

const mapStateToProps = ({ account, user, licence }) => ({
  users: user.users,
  licence,
  fetchUserLoading: user.isFetchingData,
  isDeletingLoading: user.isDeleting,
  saveUserLoading: user.isSavingData,
  currentUserId: parseInt(account.id, 10)
});

UsersManagementPage.defaultProps = {
  users: []
};

UsersManagementPage.propTypes = {
  fetchUsers: PropTypes.func.isRequired,
  updateUserRole: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  inviteUser: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  fetchUserLoading: PropTypes.bool.isRequired,
  saveUserLoading: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  licence: PropTypes.shape({}).isRequired,
  currentUserId: PropTypes.number.isRequired,
  deleteUser: PropTypes.func.isRequired,
  isDeletingLoading: PropTypes.bool.isRequired
};

export default injectIntl(
  connect(
    mapStateToProps,
    {
      fetchUsers: actions.fetchUsers,
      updateUserRole: actions.updateUserRole,
      updateUser: actions.updateUser,
      inviteUser: actions.inviteUser,
      deleteUser: actions.deleteUser
    }
  )(UsersManagementPage)
);
