import styled from "styled-components";
import { ItemType } from "../../../..";

const ItemHeaderLeftBar = styled.div`
  position: absolute;
  width: 50px;
  height: 100%;
  background: ${({ itemType, theme }) => {
    if (itemType === ItemType.OBJECTIVE) return theme.green_regular;
    if (itemType === ItemType.STRATEGY) return theme.blue_regular;
    if (itemType === ItemType.ACTION) return theme.purple_regular;
    return theme.green_regular;
  }};
  opacity: ${props => (props.isOpen ? "1" : "0")};

  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  transform: translateZ(0);
  /* transition: opacity 0.25s ease-out; */
`;

export default ItemHeaderLeftBar;
