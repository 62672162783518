import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import {
  Input,
  InputGroup,
  BorderStyle,
  InputStyle
} from "../../../../components/forms";
import messages from "../LoginForm.messages";

const LoginFormInputsContainer = styled.div`
  margin-bottom: 30px;
`;

const LoginFormInputs = ({
  data: { email, password },
  loading,
  errors,
  onChange,
  intl
}) => (
  <LoginFormInputsContainer>
    <InputGroup error={errors.email}>
      <Input
        name="email"
        value={email}
        onChange={onChange}
        isDisabled={loading}
        placeholder={intl.formatMessage(messages.fields.email)}
        disabled={loading}
        borderStyle={BorderStyle.ROUND}
        inputStyle={InputStyle.COLORED}
      />
    </InputGroup>

    <InputGroup error={errors.password}>
      <Input
        name="password"
        value={password}
        onChange={onChange}
        type="password"
        placeholder={intl.formatMessage(messages.fields.password)}
        disabled={loading}
        borderStyle={BorderStyle.ROUND}
        inputStyle={InputStyle.COLORED}
      />
    </InputGroup>
  </LoginFormInputsContainer>
);

LoginFormInputs.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(LoginFormInputs);
