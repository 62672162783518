import React from "react";
import PropTypes from "prop-types";
import MaterialTable from "material-table";
import { Icon } from "@blueprintjs/core";
import { injectIntl, intlShape } from "react-intl";
import styled from "styled-components";
import RoleSelect from "../RoleSelect";
import messages from "../Users.messages";
import ActionButton from "../../projects/projectSummarySection/items/itemHeader/headerActions/ActionButton";

const StyledNameColumn = styled.span`
  color: ${({ theme }) => theme.blue_regular};
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`;

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => theme.gray_dark};

  &:hover {
    cursor: pointer;
  }
`;

class UsersTable extends React.Component {
  getUsername = (user) => `${user.firstname} ${user.lastname}`;

  isCurrentUser = (user) => {
    const { currentUserId } = this.props;

    return user.id === currentUserId;
  };

  renderNameColumn = (user) => {
    const { userEditClicked } = this.props;
    if (!this.isCurrentUser(user)) {
      return (
        <StyledNameColumn onClick={() => userEditClicked(user.id)}>
          {this.getUsername(user)}
        </StyledNameColumn>
      );
    }
    return <span>{this.getUsername(user)}</span>;
  };

  renderRoleColumn = (user) => {
    const { currentUserId, userRoleSelected, intl } = this.props;
    return (
      <RoleSelect
        defaultRoleId={user.role}
        userRoleSelected={(role) => userRoleSelected(role, user.id)}
        intl={intl}
        disabled={user.id === currentUserId}
      />
    );
  };

  renderStatusColumn = (user) => {
    const { intl } = this.props;
    return (
      <React.Fragment>
        {user.isActive
          ? intl.formatMessage(messages.fields.active)
          : intl.formatMessage(messages.fields.inactive)}
      </React.Fragment>
    );
  };

  renderActionsColumn = (user) => {
    const { userDeleteClicked, intl } = this.props;
    if (!this.isCurrentUser(user)) {
      return (
        <ActionButton
          title={intl.formatMessage(messages.general.delete)}
          iconSize={25}
          icon="remove"
          onClick={() => userDeleteClicked(user.id)}
        />
      );
    }
    return null;
  };

  render() {
    const { users, intl } = this.props;

    return (
      <React.Fragment>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <MaterialTable
          columns={[
            {
              title: intl.formatMessage(messages.fields.fullname),
              field: "fullname",
              render: (rowData) => this.renderNameColumn(rowData),
            },
            {
              title: intl.formatMessage(messages.fields.email),
              field: "email",
            },
            {
              title: intl.formatMessage(messages.fields.role),
              field: "role",
              render: (rowData) => this.renderRoleColumn(rowData),
            },
            {
              title: intl.formatMessage(messages.fields.status),
              field: "isActive",
              render: (rowData) => this.renderStatusColumn(rowData),
            },
            {
              title: intl.formatMessage(messages.fields.actions),
              sorting: false,
              render: (rowData) => this.renderActionsColumn(rowData),
            },
          ]}
          data={users}
          options={{ toolbar: false, paging: false }}
        />
      </React.Fragment>
    );
  }
}

UsersTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  intl: intlShape.isRequired,
  userRoleSelected: PropTypes.func.isRequired,
  userEditClicked: PropTypes.func.isRequired,
  currentUserId: PropTypes.number.isRequired,
  userDeleteClicked: PropTypes.func.isRequired,
};

export default injectIntl(UsersTable);
