import React from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement
} from "react-stripe-elements";
import { FormattedMessage } from "react-intl";

import { Input, Label, InputGroup } from "../forms";
import { Flex } from "..";
import CreditCardIconSet from "./CreditCardIconSet";
import messages from "./CreditCardForm.messages";

const CreditCardForm = ({ onChange, stripeElementChange, errors, data }) => (
  <Flex direction="column">
    <Flex align="center" mobile>
      <InputGroup error={errors.cardHolderName}>
        <Label>
          <FormattedMessage {...messages.creditCard.nameOnCard} />
        </Label>
        <Input
          name="cardHolderName"
          value={data.cardHolderName}
          onChange={onChange}
          className="StripeElement"
        />
      </InputGroup>

      <InputGroup error={errors.cardNumber}>
        <Label>
          <FormattedMessage {...messages.creditCard.cardNumber} />
        </Label>
        <CardNumberElement onChange={stripeElementChange} />
      </InputGroup>
    </Flex>

    <Flex align="center" mobile>
      <InputGroup error={errors.cardExpiry}>
        <Label>
          <FormattedMessage {...messages.creditCard.cardExpiry} />
        </Label>
        <CardExpiryElement onChange={stripeElementChange} />
      </InputGroup>
      <InputGroup error={errors.cardCvc}>
        <Label>
          <FormattedMessage {...messages.creditCard.cardCVC} />
        </Label>
        <CardCVCElement onChange={stripeElementChange} />
      </InputGroup>
    </Flex>

    <Flex>
      <CreditCardIconSet />
    </Flex>
  </Flex>
);

export default CreditCardForm;
