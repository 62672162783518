import {
  FETCH_TASKS_BEGIN,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAILURE
} from "./types";
import api from "../api";

const fetchTasksBegin = () => ({
  type: FETCH_TASKS_BEGIN
});

const fetchTasksSuccess = tasks => ({
  type: FETCH_TASKS_SUCCESS,
  payload: tasks
});

const fetchTasksFailure = error => ({
  type: FETCH_TASKS_FAILURE,
  payload: error
});

export const fetchTasks = userId => dispatch => {
  dispatch(fetchTasksBegin());

  return api.tasks
    .get(userId)
    .then(res => {
      dispatch(fetchTasksSuccess(res.data));
    })
    .catch(error => dispatch(fetchTasksFailure(error)));
};
