import { defineMessages } from "react-intl";

const messages = {
  recoverMyPassword: {
    id: "ForgotPassword.recoverMyPassword"
  },
  errors: {
    invalidEmail: {
      id: "Form.errors.invalidEmail",
      description: "Invalid email error"
    }
  },
  fields: {
    email: {
      id: "Form.fields.email",
      description: "Email field name"
    }
  },
  alreadyHaveAnAccount: { id: "RegisterForm.alreadyHaveAnAccount" },
  send: { id: "General.send" }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
