import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import messages from "../Licences.messages";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  position: absolute;
  top: -15px;
  left: -15px;
  background: ${({ theme, isActiveLicence }) =>
    isActiveLicence ? theme.blue_regular : theme.purple_regular};
  z-index: 1;
  padding: 15px 25px;
  border-radius: 16px;
  color: white;
  box-shadow: 1px 7px 20px 0 rgba(0, 0, 0, 0.28);
`;

const Name = styled.p`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.4em;
  margin: 0px;
`;

const PriceTag = styled.p`
  margin: 0px;
`;

const Price = styled.span`
  font-weight: bold;
  font-size: 1.2em;
`;

const Month = styled.span`
  font-size: 0.8em;
`;

const BeforeTaxes = styled.p`
  font-size: 0.7em;
`;

const PriceSection = ({ name, monthlyPrice, isActiveLicence }) => (
  <Container isActiveLicence={isActiveLicence}>
    <Name>
      <FormattedMessage {...messages.types[name]} />
    </Name>
    <PriceTag>
      <Price>{monthlyPrice} $</Price>
      <Month>/mois</Month>
    </PriceTag>
    <BeforeTaxes>
      <FormattedMessage {...messages.general.beforeTaxes}/>
    </BeforeTaxes>
  </Container>
);

PriceSection.propTypes = {
  name: PropTypes.string.isRequired,
  monthlyPrice: PropTypes.number.isRequired,
  isActiveLicence: PropTypes.bool.isRequired
};

export default PriceSection;
