import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import {
  Input,
  InputGroup,
  BorderStyle,
  InputStyle,
} from "../../../../components/forms";
import messages from "../UnsubscribeForm.messages";

const UnsubscribeFormInputsContainer = styled.div`
  margin-bottom: 30px;
`;

const UnsubscribeFormInputs = ({
  data: { email },
  loading,
  errors,
  onChange,
  intl,
}) => (
  <UnsubscribeFormInputsContainer>
    <InputGroup error={errors.email}>
      <Input
        name="email"
        value={email}
        onChange={onChange}
        isDisabled={loading}
        placeholder={intl.formatMessage(messages.fields.email)}
        disabled={loading}
        borderStyle={BorderStyle.ROUND}
        inputStyle={InputStyle.COLORED}
      />
    </InputGroup>
  </UnsubscribeFormInputsContainer>
);

UnsubscribeFormInputs.propTypes = {
  data: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(UnsubscribeFormInputs);
