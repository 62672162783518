import React from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { Button, Intent } from "@blueprintjs/core";
import { StyledDialog, Container, InputSection, TextInput } from "./Styles";
import { CheckItemSection } from "./CheckItemSection";
import { LoadingOverlay } from "../overlays";
import messages from "./CheckItem.messages";
import AppToaster from "../toasters";

class CheckItemDialogs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
    };
  }

  textChanged = (event) => {
    this.setState({ text: event.target.value });
  };

  onClose = () => {
    const { onClose } = this.props;
    this.setState({ text: "" });

    onClose();
  };

  onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      this.submit(e);
    }
  };

  showErrorMessage = (err) => {
    AppToaster.show({
      message: err,
      intent: Intent.DANGER,
    });
  };

  submit = (event) => {
    event.preventDefault();
    const {
      state: { text },
      props: { submit },
    } = this;

    if (text) {
      submit(false, text).then(() => this.setState({ text: "" }));
    }
  };

  render() {
    const {
      props: {
        isOpen,
        loading,
        checkItems,
        checkChange,
        checkRemove,
        canEditCheck,
        submit,
        intl,
      },
      state: { text },
    } = this;

    return (
      <StyledDialog
        title={intl.formatMessage(messages.general.title)}
        isOpen={isOpen}
        onClose={this.onClose}
        canOutsideClickClose={false}
      >
        {loading && <LoadingOverlay />}
        <Container>
          <CheckItemSection
            checkItems={checkItems}
            checkChange={checkChange}
            checkRemove={checkRemove}
            submit={submit}
            canEdit={canEditCheck}
            intl={intl}
          />
          {canEditCheck && (
            <InputSection onSubmit={this.submit}>
              <TextInput
                value={text}
                onChange={this.textChanged}
                rows="3"
                onKeyDown={this.onEnterPress}
              />
              <Button
                intent={Intent.PRIMARY}
                type="submit"
                onClick={this.submit}
                disabled={!text || !text.trim()}
              >
                <FormattedMessage {...messages.general.add} />
              </Button>
            </InputSection>
          )}
        </Container>
      </StyledDialog>
    );
  }
}

CheckItemDialogs.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  checkChange: PropTypes.func.isRequired,
  checkRemove: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  canEditCheck: PropTypes.bool.isRequired,
  checkItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(CheckItemDialogs);
