import styled from "styled-components";
import { ItemStatus } from "../..";

const ItemStatusBar = styled.div`
  position: absolute;
  width: 10px;
  height: 50%;
  top: 50%;
  right: 0px;
  border-radius: 35px;
  transform: translateY(-50%);

  background: ${({ theme, itemStatus }) => {
    if (itemStatus === ItemStatus.UP_TO_DATE) return theme.green_success;
    if (itemStatus === ItemStatus.SOON_EXPIRED) return theme.orange_warning;
    if (itemStatus === ItemStatus.OVERDUE) return theme.red_danger;

    return "";
  }};
`;

export default ItemStatusBar;
