import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import HeaderLogo from "./headerLogo/HeaderLogo";
import HamburgerMenu from "./nav/HamburgerMenu";

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  padding: 10px 20px;
  background: ${({ theme, showMenu }) => (showMenu ? theme.blue_regular : "")};
  flex-shrink: 0;
`;

const Header = ({ withColor, showMenu }) => (
  <StyledHeader showMenu={showMenu}>
    <HeaderLogo withColor={withColor} />
    {showMenu && <HamburgerMenu />}
  </StyledHeader>
);

Header.defaultProps = {
  withColor: true,
  showMenu: true
};

Header.propTypes = {
  withColor: PropTypes.bool,
  showMenu: PropTypes.bool
};

export default Header;
