import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { H2 } from "../../../../components/text/Headers";
import messages from "../ResetPasswordForm.messages";

const ResetPasswordFormHeaderContainer = styled.div`
  margin-bottom: 40px;
`;

const ResetPasswordFormHeader = () => (
  <ResetPasswordFormHeaderContainer>
    <H2>
      <FormattedMessage {...messages.title} />
    </H2>
  </ResetPasswordFormHeaderContainer>
);

export default ResetPasswordFormHeader;
