import React from "react";
import styled from "styled-components";

import Hamburger from "../hamburger/Hamburger";
import Nav from "./Nav";

const MenuContainer = styled.nav`
  display: flex;
  align-items: center;
`;

class HamburgerMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  handleClickOutside = () => {
    this.closeNavigation();
  };

  toggleNavigation = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  closeNavigation = () => {
    this.setState({
      isOpen: false
    });
  };

  render() {
    const { isOpen } = this.state;

    return (
      <MenuContainer>
        <Hamburger isOpen={isOpen} onClick={this.toggleNavigation} />
        <Nav
          isOpen={isOpen}
          handleClickOutside={this.handleClickOutside}
          enableOnClickOutside={isOpen}
        />
      </MenuContainer>
    );
  }
}

export default HamburgerMenu;
