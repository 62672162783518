import React, { useState } from "react";
import MaterialTable from "material-table";
import { injectIntl, intlShape } from "react-intl";
import messages from "./Logs.messages";
import api from "../../api";
import { toLocalizedDateTimeString } from "../../helpers/DateHelper";

const parseFilter = (filters) => {
  let finalFilter = [];

  filters.forEach((element) => {
    finalFilter.push({
      field: element.column.field,
      operator: element.operator,
      value: element.value,
    });
  });

  return finalFilter;
};

const getData = (query) => {
  return new Promise((resolve, reject) => {
    if (typeof query === "object") {
      query.page = query.page == null ? 0 : query.page;
      query.pageSize = query.pageSize == null ? 5 : query.pageSize;

      let filters = parseFilter(query.filters);
      let sort = query.orderBy == null ? "" : query.orderBy.field;
      let sortOrder = query.orderDirection == null ? "" : query.orderDirection;

      Promise.resolve(
        api.logs
          .get(query.page, query.pageSize, sort, sortOrder, filters)
          .then((data) => {
            resolve({
              data: data.data.items,
              page: query.page,
              totalCount: data.data.totalRow,
            });
          })
      );
    }
  });
};

const LogsTable = ({ intl }) => {
  const [columnObject] = useState([
    {
      title: intl.formatMessage(messages.fields.fullname),
      field: "user.fullname",
      customFilterAndSearch: getData,
      customSort: getData,
    },
    {
      title: intl.formatMessage(messages.fields.date),
      field: "actionDate",
      render: (rowData) => (
        <React.Fragment>
          {toLocalizedDateTimeString(rowData.actionDate)}
        </React.Fragment>
      ),
      customFilterAndSearch: getData,
      customSort: getData,
    },
    {
      title: intl.formatMessage(messages.fields.activity),
      render: (rowData) => {
        return intl.formatMessage({ id: rowData.actionType });
      },
      filtering: false,
      field: "actionType",
    },
  ]);

  return (
    <React.Fragment>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <MaterialTable
        localization={{
          body: {
            emptyDataSourceMessage: intl.formatMessage(messages.general.noLogs),
            filterRow: {
              filterTooltip: intl.formatMessage(messages.general.filter),
            },
          },
          pagination: {
            labelDisplayedRows: intl.formatMessage(
              messages.general.labelDisplayedRows
            ),
            labelRowsSelect: intl.formatMessage(
              messages.general.labelRowsSelect
            ),
            labelRowsPerPage: intl.formatMessage(
              messages.general.labelRowsPerPage
            ),
            firstTooltip: intl.formatMessage(messages.general.firstTooltip),
            previousTooltip: intl.formatMessage(
              messages.general.previousTooltip
            ),
            nextTooltip: intl.formatMessage(messages.general.nextTooltip),
            lastTooltip: intl.formatMessage(messages.general.lastTooltip),
          },
        }}
        columns={columnObject}
        options={{
          toolbar: false,
          paging: true,
          sorting: true,
          filtering: true,
          emptyRowsWhenPaging: false,
        }}
        data={getData}
        onChangePage={getData}
        onChangeRowsPerPage={getData}
        onSearchChange={getData}
        onOrderChange={getData}
      />
    </React.Fragment>
  );
};

LogsTable.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(LogsTable);
