import { defineMessages } from "react-intl";

const messages = {
  general: {
    title: { id: "Attachments.title" },
    save: { id: "General.save" },
  },
  fields: {
    browse: { id: "Attachments.browse" },
    selectAFile: { id: "Attachments.chooseFile" },
  },
  errors: {
    fileToLarge: { id: "Attachments.fileToLarge" },
  },
};

const definedMessages = defineMessages(messages);

export default definedMessages;
