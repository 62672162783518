import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PageLayout from "../PageLayout";
import * as TaskActions from "../../../../actions/task";
import * as UserActions from "../../../../actions/user";
import { Header, TasksTable } from "../../../../components/tasks";
import { LoadingOverlay } from "../../../../components/overlays";

class TaskPage extends React.Component {
  constructor(props) {
    super(props);

    const { currentUserId } = this.props;

    this.state = {
      selectedUserId: currentUserId
    };
  }

  componentDidMount() {
    const {
      props: { fetchTasks, fetchUsers },
      state: { selectedUserId }
    } = this;

    fetchUsers();
    fetchTasks(selectedUserId);
  }

  updateSelectedUser = user => {
    const { fetchTasks } = this.props;
    this.setState(prevState => ({
      ...prevState,
      selectedUserId: user.id
    }));

    fetchTasks(user.id);
  };

  render() {
    const {
      props: { tasks, users, loading, currentUserId },
      state: { selectedUserId }
    } = this;
    return (
      <PageLayout>
        {loading && <LoadingOverlay />}
        <Header
          users={users}
          selectedUser={users.find(x => x.id === selectedUserId)}
          updateSelectedUser={this.updateSelectedUser}
          isCurrentUserTasks={currentUserId === selectedUserId}
        />

        <TasksTable tasks={tasks} />
      </PageLayout>
    );
  }
}

TaskPage.propTypes = {
  fetchTasks: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentUserId: PropTypes.number.isRequired
};

const mapStateToProps = ({ account, task, user }) => ({
  loading: task.loading && user.isFetchingData,
  tasks: task.tasks,
  users: user.users,
  currentUserId: parseInt(account.id, 10)
});

export default connect(
  mapStateToProps,
  {
    fetchTasks: TaskActions.fetchTasks,
    fetchUsers: UserActions.fetchUsers
  }
)(TaskPage);
