import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "../../../../actions/auth";

class LogoutPage extends React.Component {
  componentWillMount() {
    const { logout } = this.props;
    logout();
  }

  render() {
    return <React.Fragment />;
  }
}

LogoutPage.propTypes = {
  logout: PropTypes.func.isRequired
};

export default connect(
  null,
  { logout: actions.logout }
)(LogoutPage);
