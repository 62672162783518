import { defineMessages } from "react-intl";

const messages = {
  unsubscribe: {
    id: "UnsubscribeForm.unsubscribe",
    description: "Unsubscribe button text",
  },
  headerText: {
    id: "UnsubscribeForm.headerText",
    description: "Text over the field for instruction to unsubscribe",
  },
  fields: {
    email: { id: "Form.fields.email" },
  },
  saveSuccess: {
    id: "Messages.informationSaved.updated",
  },
};

const definedMessages = defineMessages(messages);

export default definedMessages;
