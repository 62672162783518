import React from "react";
import {
  Popover,
  PopoverInteractionKind,
  PopoverPosition
} from "@blueprintjs/core";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";
import ItemPopoverContent from "./ItemPopoverContent";

class ItemHeaderPopover extends React.Component {
  renderPopoverContent = () => {
    const { item } = this.props;

    return <ItemPopoverContent item={item} />;
  };

  render() {
    const { children, position } = this.props;
    return (
      <Popover
        boundary="viewport"
        content={this.renderPopoverContent()}
        target={children}
        interactionKind={PopoverInteractionKind.HOVER}
        position={position}
      />
    );
  }
}

ItemHeaderPopover.defaultProps = {
  position: PopoverPosition.BOTTOM_RIGHT
};

ItemHeaderPopover.propTypes = {
  children: PropTypes.shape({}).isRequired,
  item: PropTypes.shape({}).isRequired,
  intl: intlShape.isRequired,
  position: PropTypes.string
};

export default injectIntl(ItemHeaderPopover);
