import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import GoalList from "./goalList/GoalList";
import ItemSortableContainer from "./itemSortableContainer/ItemSortableContainer";

import messages from "../Projects.messages";

const NoGoalMessage = styled.h3`
  color: ${({ theme }) => theme.gray_dark};
`;

const ProjectSummarySection = ({
  project,
  queryValues,
  userActions,
  currentUser,
}) => (
  <React.Fragment>
    <ItemSortableContainer itemOrderChanged={userActions.itemOrderChanged}>
      {project.goals && (
        <GoalList
          goals={project.goals}
          userActions={userActions}
          queryValues={queryValues}
          isParentManager={project.manager.id === currentUser.id}
          currentUser={currentUser}
          project={project}
        />
      )}
    </ItemSortableContainer>

    {!project.goals ||
      (!project.goals.length > 0 && (
        <NoGoalMessage>
          <FormattedMessage {...messages.general.noGoalCreated} />
        </NoGoalMessage>
      ))}
  </React.Fragment>
);

ProjectSummarySection.propTypes = {
  project: PropTypes.shape().isRequired,
  userActions: PropTypes.shape({}).isRequired,
  queryValues: PropTypes.shape().isRequired,
  currentUser: PropTypes.shape({}).isRequired,
};

export default ProjectSummarySection;
