import { defineMessages } from "react-intl";

const messages = {
  items: {
    projects: { id: "Nav.items.projects" },
    preferences: { id: "Nav.items.preferences" },
    projectsDetails: { id: "Nav.items.projectsDetails" },
    summary: { id: "Nav.items.summary" },
    profile: { id: "Nav.items.profile" },
    licence: { id: "Nav.items.licence" },
    paymentOptions: { id: "Nav.items.paymentOptions" },
    logout: { id: "Nav.items.logout" },
    users: { id: "Nav.items.users" },
    usersManagement: { id: "Nav.items.usersManagement" },
    projectsManagement: { id: "Nav.items.projectsManagement" },
    dashboard: { id: "Nav.items.dashboard" },
    task: { id: "Nav.items.task" },
    log: { id: "Nav.items.log" },
    help: { id: "Nav.items.help" },
  },
};

const definedMessages = defineMessages(messages);

export default definedMessages;
