import { defineMessages } from "react-intl";

const messages = {
  fields: {
    fullname: { id: "Form.fields.fullname" },
    date: { id: "Form.fields.Date" },
    activity: { id: "Form.fields.Activity" },
  },
  general: {
    title: { id: "Logs.title" },
    noLogs: { id: "Logs.noLogs" },
    labelDisplayedRows: { id: "MaterialTable.pagination.labelDisplayedRows" },
    labelRowsSelect: { id: "MaterialTable.pagination.labelRowsSelect" },
    labelRowsPerPage: { id: "MaterialTable.pagination.labelRowsPerPage" },
    firstTooltip: { id: "MaterialTable.pagination.firstTooltip" },
    previousTooltip: { id: "MaterialTable.pagination.previousTooltip" },
    nextTooltip: { id: "MaterialTable.pagination.nextTooltip" },
    lastTooltip: { id: "MaterialTable.pagination.lastTooltip" },
    filter: { id: "MaterialTable.filter" },
  },
};

const definedMessages = defineMessages(messages);

export default definedMessages;
