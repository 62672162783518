import React from "react";
import { Dialog, Intent, FileInput } from "@blueprintjs/core";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { InputGroup } from "../../../components/forms";
import { Flex } from "../../../components";
import AvatarBank from "./AvatarBank";
import { LoadingOverlay } from "../../../components/overlays";
import messages from "./ProfileForm.messages";
import { Button } from "../../../components/buttons";

const Container = styled.div`
  position: relative;
`;

const AvatarWarning = styled.p`
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 0.7rem;

  &::before {
    content: "*";
    padding-right: 2px;
  }
`;

class AvatarDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      selectedAvatar: null,
      errors: {}
    };
  }

  avatarSelected = selectedAvatar => {
    this.setState(prevState => ({ ...prevState, selectedAvatar }));
  };

  onChange = e => {
    this.setState({ file: e.target.files[0] });
  };

  onClose = () => {
    const { closeDialog } = this.props;

    this.setState({
      file: null,
      errors: {}
    });

    closeDialog();
  };

  submit = () => {
    const { updateAvatar } = this.props;
    const { file, selectedAvatar } = this.state;
    const errors = this.validate();
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      updateAvatar(file, selectedAvatar).then(
        this.setState({ errors: {}, file: null })
      );
    }
  };

  validate = () => {
    const { intl } = this.props;
    const { file, selectedAvatar } = this.state;
    const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
    const errors = {};

    if (selectedAvatar === null && file === null) {
      errors.avatar = intl.formatMessage(messages.errors.invalidImageType);
      return errors;
    }
    if (
      selectedAvatar === null &&
      (file === null || !validImageTypes.includes(file.type))
    ) {
      errors.file = intl.formatMessage(messages.errors.invalidImageType);
      return errors;
    }

    return errors;
  };

  render() {
    const { isOpen, loading, intl } = this.props;
    const { file, errors, selectedAvatar } = this.state;
    return (
      <Dialog
        isOpen={isOpen}
        onClose={this.onClose}
        canOutsideClickClose={false}
        title="Avatar"
      >
        <Container>
          {loading && <LoadingOverlay />}
          <Flex direction="column" margin="15px">
            <InputGroup grow="1" error={errors.file}>
              <FileInput
                type="file"
                onInputChange={this.onChange}
                text={
                  file
                    ? file.name
                    : intl.formatMessage(messages.profile.selectYourAvatar)
                }
              />
            </InputGroup>

            <AvatarBank
              avatarClicked={this.avatarSelected}
              selectedAvatar={selectedAvatar}
            />

            <Button intent={Intent.PRIMARY} onClick={this.submit}>
              <FormattedMessage {...messages.profile.updateAvatar} />
            </Button>

            <AvatarWarning>
              <FormattedMessage
                {...messages.profile.avatarSizeRecommendation}
              />
            </AvatarWarning>
          </Flex>
        </Container>
      </Dialog>
    );
  }
}

AvatarDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  updateAvatar: PropTypes.func.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(AvatarDialog);
