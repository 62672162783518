import { defineMessages } from "react-intl";

const messages = {
  login: {
    id: "LoginForm.login",
    description: "Login button text"
  },
  noAccount: {
    id: "LoginForm.noAccount",
    description: "Text for the register link under the login button"
  },
  forgotPassword: {
    id: "LoginForm.forgotPassword",
    description: "Text for the forgot password link under the login button"
  },
  fields: {
    email: { id: "Form.fields.email" },
    password: { id: "Form.fields.password" }
  }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
