import React from "react";
import PropTypes from "prop-types";
import { intlShape, injectIntl } from "react-intl";
import { ContentList, Content } from "./Styles";
import messages from "../../../../Projects.messages";
import { toLocalizedDateString } from "../../../../../../helpers/DateHelper";

const ItemPopoverContent = ({ item, intl }) => (
  <ContentList>
    <Content
      title={`${intl.formatMessage(messages.fields.name)} : `}
      value={item.name || item.taskName}
    />
    <Content
      title={`${intl.formatMessage(messages.fields.description)} : `}
      value={item.description || item.taskDescription}
    />

    {item.benefits && item.benefits.length > 0 && (
      <Content
        title={`${intl.formatMessage(messages.fields.benefits)} : `}
        value={
          <ContentList withStyle>
            {item.benefits.map(benefit => (
              <Content
                index={benefit.id}
                key={benefit.id}
                title=""
                value={benefit.name}
              />
            ))}
          </ContentList>
        }
      />
    )}

    {item.measuringTool && (
      <Content
        title={`${intl.formatMessage(messages.fields.measuringTools)} : `}
        value={item.measuringTool}
      />
    )}

    <Content
      title={`${intl.formatMessage(messages.fields.manager)} : `}
      value={item.manager.fullname}
    />
    <Content
      title={`${intl.formatMessage(messages.fields.beginningDate)} : `}
      value={toLocalizedDateString(item.beginningDate)}
    />
    <Content
      title={`${intl.formatMessage(messages.fields.deadlineDate)} : `}
      value={toLocalizedDateString(item.deadlineDate)}
    />

    {item.note && (
      <Content
        title={`${intl.formatMessage(messages.fields.notes)} : `}
        value={item.note}
      />
    )}
  </ContentList>
);

ItemPopoverContent.propTypes = {
  item: PropTypes.shape({}).isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(ItemPopoverContent);
