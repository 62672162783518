import styled from "styled-components";
import { ItemType } from "../projects";

const H1 = styled.h1`
  font-size: 4em;
  color: ${({ theme, itemType }) => {
    if (itemType === ItemType.OBJECTIVE) return theme.green_regular;
    if (itemType === ItemType.STRATEGY) return theme.blue_regular;
    if (itemType === ItemType.ACTION) return theme.purple_regular;
    return theme.blue_regular;
  }};
  margin: 5px 0px;
`;

const H2 = styled.h2`
  font-size: 2em;
  color: ${({ theme, itemType }) => {
    if (itemType === ItemType.OBJECTIVE) return theme.green_regular;
    if (itemType === ItemType.STRATEGY) return theme.blue_regular;
    if (itemType === ItemType.ACTION) return theme.purple_regular;
    return theme.blue_regular;
  }};
  margin: 5px 0px;
`;

const H3 = styled.h3`
  font-size: 1.3em;
  color: ${({ theme, itemType }) => {
    if (itemType === ItemType.OBJECTIVE) return theme.green_regular;
    if (itemType === ItemType.STRATEGY) return theme.blue_regular;
    if (itemType === ItemType.ACTION) return theme.purple_regular;
    return theme.blue_regular;
  }};
  margin: 5px 0px;
  margin-bottom: 10px;
`;

export { H1, H2, H3 };
