import React from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { Button, Intent } from "@blueprintjs/core";
import {
  StyledDialog,
  Container,
  InputSection,
  StyledFileInput,
  StyledButtonSave,
} from "./Styles";
import { AttachmentSection } from "./AttachmentSection";
import { LoadingOverlay } from "../overlays";
import messages from "./Attachment.messages";
import AppToaster from "../toasters";

class AttachmentDialogs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      attachment: null,
    };
  }

  onClose = () => {
    const { onClose } = this.props;
    this.setState({ attachment: null });

    onClose();
  };

  onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      this.submit(e);
    }
  };

  showErrorMessage = (err) => {
    AppToaster.show({
      message: err,
      intent: Intent.DANGER,
    });
  };

  onFileChange = (event) => {
    const {
      props: { intl },
    } = this;
    // Update the state
    if (
      event.target.files.length == 0 ||
      event.target.files[0].size > 40000000
    ) {
      this.setState({ attachment: null });
      this.showErrorMessage(intl.formatMessage(messages.errors.fileToLarge));
    } else {
      this.setState({ attachment: event.target.files[0] });
    }
  };

  submit = (event) => {
    event.preventDefault();
    const {
      state: { attachment },
      props: { submit },
    } = this;

    if (attachment) {
      const reader = new FileReader();

      reader.onload = (ev) => {
        submit(attachment.name, reader.result).then(() =>
          this.setState({ attachment: null })
        );
      };

      reader.readAsDataURL(attachment);
    }
  };

  render() {
    const {
      props: {
        isOpen,
        loading,
        attachments,
        fetchFile,
        removeAttachment,
        intl,
      },
      state: { attachment },
    } = this;

    const fileName =
      attachment != null && attachment.name != ""
        ? attachment.name
        : intl.formatMessage(messages.fields.selectAFile);

    return (
      <StyledDialog
        title={intl.formatMessage(messages.general.title)}
        isOpen={isOpen}
        onClose={this.onClose}
        canOutsideClickClose={false}
      >
        {loading && <LoadingOverlay />}
        <Container>
          <AttachmentSection
            attachments={attachments}
            fetchFile={fetchFile}
            removeAttachment={removeAttachment}
          />

          <InputSection onSubmit={this.submit}>
            <StyledFileInput
              large
              fill
              text={fileName}
              buttonText={intl.formatMessage(messages.fields.browse)}
              onChange={this.onFileChange}
              hasSelection={attachment != null}
            />
            <StyledButtonSave
              intent={Intent.PRIMARY}
              type="submit"
              onClick={this.onFileUpload}
              disabled={!attachment}
            >
              <FormattedMessage {...messages.general.save} />
            </StyledButtonSave>
          </InputSection>
        </Container>
      </StyledDialog>
    );
  }
}

AttachmentDialogs.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  removeAttachment: PropTypes.func.isRequired,
  fetchFile: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(AttachmentDialogs);
