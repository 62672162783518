import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Intent } from "@blueprintjs/core";
import { injectIntl, intlShape } from "react-intl";
import PageLayout from "../PageLayout";
import ProfileForm from "../../../forms/profileForm";
import * as actions from "../../../../actions/profile";
import { LoadingOverlay } from "../../../../components/overlays";
import PasswordDialog from "../../../forms/profileForm/PasswordDialog";
import AvatarDialog from "../../../forms/profileForm/AvatarDialog";
import AppToaster from "../../../../components/toasters";
import messages from "./ProfilePage.messages";

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordDialog: {
        isOpen: false
      },
      avatarDialog: {
        isOpen: false
      }
    };
  }

  componentDidMount() {
    const { fetchProfile } = this.props;

    fetchProfile();
  }

  submit = data => {
    const { saveProfile } = this.props;

    return saveProfile(data);
  };

  updatePassword = data => {
    const { updatePassword, intl } = this.props;

    return updatePassword(data)
      .then(() => {
        this.togglePasswordDialog(false);
        this.showSuccessMessage(
          intl.formatMessage(messages.alert.updatePasswordSuccess)
        );
      })
      .catch(err => this.showErrorMessage(err));
  };

  updateAvatar = (file, selectedAvatar) => {
    const { updateAvatar, intl } = this.props;

    return updateAvatar(file, selectedAvatar)
      .then(() => {
        this.toggleAvatarDialog(false);
        this.showSuccessMessage(
          intl.formatMessage(messages.alert.updateAvatarSuccess)
        );
      })
      .catch(err => this.showErrorMessage(err));
  };

  togglePasswordDialog = isOpen => {
    this.setState(prevState => ({
      ...prevState,
      passwordDialog: {
        isOpen
      }
    }));
  };

  toggleAvatarDialog = isOpen => {
    this.setState(prevState => ({
      ...prevState,
      avatarDialog: {
        isOpen
      }
    }));
  };

  showSuccessMessage = message => {
    AppToaster.show({
      message,
      intent: Intent.SUCCESS
    });
  };

  showErrorMessage = err => {
    AppToaster.show({
      message: err,
      intent: Intent.DANGER
    });
  };

  render() {
    const { profile, loading, updatingPassword, updatingAvatar } = this.props;
    const { passwordDialog, avatarDialog } = this.state;
    return (
      <PageLayout>
        {loading && <LoadingOverlay />}
        <ProfileForm
          profile={profile}
          submit={this.submit}
          updatePasswordClicked={() => this.togglePasswordDialog(true)}
          updateAvatarClicked={() => this.toggleAvatarDialog(true)}
        />
        <PasswordDialog
          isOpen={passwordDialog.isOpen}
          closeDialog={() => this.togglePasswordDialog(false)}
          updatePassword={this.updatePassword}
          loading={updatingPassword}
        />
        <AvatarDialog
          isOpen={avatarDialog.isOpen}
          closeDialog={() => this.toggleAvatarDialog(false)}
          updateAvatar={this.updateAvatar}
          loading={updatingAvatar}
        />
      </PageLayout>
    );
  }
}

ProfilePage.propTypes = {
  fetchProfile: PropTypes.func.isRequired,
  saveProfile: PropTypes.func.isRequired,
  profile: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  updatingPassword: PropTypes.bool.isRequired,
  updatingAvatar: PropTypes.bool.isRequired,
  updatePassword: PropTypes.func.isRequired,
  updateAvatar: PropTypes.func.isRequired,
  intl: intlShape.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile.profile,
  loading: state.profile.loading,
  updatingPassword: state.profile.updatingPassword,
  updatingAvatar: state.profile.updatingAvatar
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      fetchProfile: actions.fetchProfile,
      saveProfile: actions.saveProfile,
      updatePassword: actions.updatePassword,
      updateAvatar: actions.updateAvatar
    }
  )(ProfilePage)
);
