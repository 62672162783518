import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Intent, Checkbox } from "@blueprintjs/core";
import validator from "validator";
import messages from "./RegisterCompanyForm.messages";
import { Container, Section, Sections } from "./styles";
import { H3 } from "../../../components/text/Headers";
import {
  Input,
  InputGroup,
  BorderStyle,
  InputStyle
} from "../../../components/forms";
import AppToaster from "../../../components/toasters";
import { Button, ButtonSize } from "../../../components/buttons";

class RegisterCompanyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confirmPassword: "",
        companyName: "",
        street: "",
        city: "",
        zipCode: "",
        state: "",
        country: "",
        phoneNumber: "",
        termsAccepted: false
      },
      loading: false,
      errors: {}
    };
  }

  onSubmit = e => {
    e.preventDefault();
    const {
      state: { data },
      props: { submit }
    } = this;
    const errors = this.validate(data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      submit(data).catch(err => {
        this.showErrorMessage(err);
        this.setState({ loading: false });
      });
    }
  };

  showErrorMessage = err => {
    AppToaster.show({
      message: err,
      intent: Intent.DANGER
    });
  };

  onChange = ({ target: { value, name } }) => {
    const inputName = name;
    const inputValue = value;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [inputName]: inputValue
      }
    }));
  };

  handleTermsChange = event => {
    const termsAccepted = event.target.checked;
    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        termsAccepted
      }
    }));
  };

  validate = data => {
    const { intl } = this.props;
    const errors = {};

    if (!data.termsAccepted)
      errors.termsAccepted = intl.formatMessage(
        messages.errors.termsAndConditions
      );

    if (!data.email || !validator.isEmail(data.email)) {
      errors.email = intl.formatMessage(messages.errors.invalidEmail);
    }
    if (!data.firstname) {
      errors.firstname = intl.formatMessage(messages.errors.fieldIsRequired);
    }
    if (!data.lastname) {
      errors.lastname = intl.formatMessage(messages.errors.fieldIsRequired);
    }
    if (!data.password) {
      errors.password = intl.formatMessage(messages.errors.fieldIsRequired);
    }
    if (!validator.isLength(data.password, { min: 6 })) {
      errors.password = intl.formatMessage(messages.errors.fieldIsTooShort, {
        min: 6
      });
    }
    if (!validator.isLength(data.confirmPassword, { min: 6 })) {
      errors.confirmPassword = intl.formatMessage(
        messages.errors.fieldIsTooShort,
        { min: 6 }
      );
    }
    if (!data.confirmPassword) {
      errors.confirmPassword = intl.formatMessage(
        messages.errors.fieldIsRequired
      );
    }
    if (data.password !== data.confirmPassword) {
      errors.confirmPassword = intl.formatMessage(
        messages.errors.confirmPassword
      );
    }
    if (!data.companyName) {
      errors.companyName = intl.formatMessage(messages.errors.fieldIsRequired);
    }
    if (!data.street) {
      errors.street = intl.formatMessage(messages.errors.fieldIsRequired);
    }
    if (!data.city) {
      errors.city = intl.formatMessage(messages.errors.fieldIsRequired);
    }
    if (!data.zipCode) {
      errors.zipCode = intl.formatMessage(messages.errors.fieldIsRequired);
    }
    if (!data.state) {
      errors.state = intl.formatMessage(messages.errors.fieldIsRequired);
    }
    if (!data.country) {
      errors.country = intl.formatMessage(messages.errors.fieldIsRequired);
    }
    if (!data.phoneNumber) {
      errors.phoneNumber = intl.formatMessage(messages.errors.fieldIsRequired);
    }

    return errors;
  };

  render() {
    const { intl, lang } = this.props;
    const {
      data: {
        firstname,
        lastname,
        email,
        password,
        confirmPassword,
        companyName,
        street,
        city,
        zipCode,
        state,
        country,
        phoneNumber,
        termsAccepted
      },
      errors,
      loading
    } = this.state;
    return (
      <Container>
        <form onSubmit={this.onSubmit}>
          <Sections>
            <Section direction="column">
              <H3>
                <FormattedMessage {...messages.administrator} />
              </H3>
              <InputGroup error={errors.email}>
                <Input
                  name="email"
                  value={email}
                  onChange={this.onChange}
                  disabled={loading}
                  placeholder={intl.formatMessage(messages.fields.email)}
                  borderStyle={BorderStyle.ROUND}
                  inputStyle={InputStyle.COLORED}
                  autocomplete="off"
                />
              </InputGroup>

              <InputGroup error={errors.firstname}>
                <Input
                  name="firstname"
                  value={firstname}
                  onChange={this.onChange}
                  disabled={loading}
                  placeholder={intl.formatMessage(messages.fields.firstname)}
                  borderStyle={BorderStyle.ROUND}
                  inputStyle={InputStyle.COLORED}
                  autocomplete="off"
                />
              </InputGroup>

              <InputGroup error={errors.lastname}>
                <Input
                  name="lastname"
                  value={lastname}
                  onChange={this.onChange}
                  disabled={loading}
                  placeholder={intl.formatMessage(messages.fields.lastname)}
                  borderStyle={BorderStyle.ROUND}
                  inputStyle={InputStyle.COLORED}
                  autocomplete="off"
                />
              </InputGroup>

              <InputGroup error={errors.password}>
                <Input
                  name="password"
                  value={password}
                  onChange={this.onChange}
                  disabled={loading}
                  placeholder={intl.formatMessage(messages.fields.password)}
                  borderStyle={BorderStyle.ROUND}
                  inputStyle={InputStyle.COLORED}
                  type="password"
                  autocomplete="new-password"
                />
              </InputGroup>

              <InputGroup error={errors.confirmPassword}>
                <Input
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={this.onChange}
                  disabled={loading}
                  placeholder={intl.formatMessage(
                    messages.fields.confirmPassword
                  )}
                  type="password"
                  borderStyle={BorderStyle.ROUND}
                  inputStyle={InputStyle.COLORED}
                  autocomplete="new-password"
                />
              </InputGroup>

              <InputGroup error={errors.termsAccepted}>
                <Checkbox
                  checked={termsAccepted}
                  onChange={this.handleTermsChange}
                >
                  <a
                    href={`${process.env.REACT_APP_API_URL}/${lang}/help?section=terms`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage {...messages.fields.termsAndConditions} />
                  </a>
                </Checkbox>
              </InputGroup>
            </Section>

            <Section direction="column">
              <H3>
                <FormattedMessage {...messages.company} />
              </H3>
              <InputGroup error={errors.companyName}>
                <Input
                  name="companyName"
                  value={companyName}
                  onChange={this.onChange}
                  disabled={loading}
                  placeholder={intl.formatMessage(messages.fields.companyName)}
                  borderStyle={BorderStyle.ROUND}
                  inputStyle={InputStyle.COLORED}
                  autocomplete="off"
                />
              </InputGroup>

              <InputGroup error={errors.street}>
                <Input
                  name="street"
                  value={street}
                  onChange={this.onChange}
                  disabled={loading}
                  placeholder={intl.formatMessage(messages.fields.street)}
                  borderStyle={BorderStyle.ROUND}
                  inputStyle={InputStyle.COLORED}
                  autocomplete="off"
                />
              </InputGroup>

              <InputGroup error={errors.city}>
                <Input
                  name="city"
                  value={city}
                  onChange={this.onChange}
                  disabled={loading}
                  placeholder={intl.formatMessage(messages.fields.city)}
                  borderStyle={BorderStyle.ROUND}
                  inputStyle={InputStyle.COLORED}
                  autocomplete="off"
                />
              </InputGroup>

              <InputGroup error={errors.zipCode}>
                <Input
                  name="zipCode"
                  value={zipCode}
                  onChange={this.onChange}
                  disabled={loading}
                  placeholder={intl.formatMessage(messages.fields.zipCode)}
                  borderStyle={BorderStyle.ROUND}
                  inputStyle={InputStyle.COLORED}
                  autocomplete="off"
                />
              </InputGroup>

              <InputGroup error={errors.state}>
                <Input
                  name="state"
                  value={state}
                  onChange={this.onChange}
                  disabled={loading}
                  placeholder={intl.formatMessage(messages.fields.state)}
                  borderStyle={BorderStyle.ROUND}
                  inputStyle={InputStyle.COLORED}
                  autocomplete="off"
                />
              </InputGroup>

              <InputGroup error={errors.country}>
                <Input
                  name="country"
                  value={country}
                  onChange={this.onChange}
                  disabled={loading}
                  placeholder={intl.formatMessage(messages.fields.country)}
                  borderStyle={BorderStyle.ROUND}
                  inputStyle={InputStyle.COLORED}
                  autocomplete="off"
                />
              </InputGroup>

              <InputGroup error={errors.phoneNumber}>
                <Input
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={this.onChange}
                  disabled={loading}
                  placeholder={intl.formatMessage(messages.fields.phoneNumber)}
                  borderStyle={BorderStyle.ROUND}
                  inputStyle={InputStyle.COLORED}
                  autocomplete="off"
                />
              </InputGroup>
            </Section>
          </Sections>

          <Button type="submit" loading={loading} size={ButtonSize.FULL}>
            <FormattedMessage {...messages.register} />
          </Button>
        </form>

        <div>
          <Link to="/login">
            <FormattedMessage {...messages.alreadyHaveAnAccount} />
          </Link>
        </div>
      </Container>
    );
  }
}

RegisterCompanyForm.propTypes = {
  submit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  lang: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  lang: state.locales.lang
});

export default connect(mapStateToProps)(injectIntl(RegisterCompanyForm));
