import { defineMessages } from "react-intl";

const messages = {
  title: {
    id: "Popover.delete.title"
  },
  text: {
    id: "Popover.delete.text"
  },
  cancel: {
    id: "General.cancel"
  },
  delete: {
    id: "General.delete"
  }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
