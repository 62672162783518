import React from "react";
import { DateInput as BpDateInput } from "@blueprintjs/datetime";
import LocaleUtils from "react-day-picker/moment";
import { connect } from "react-redux";
import { toLocalizedDateString } from "../../../helpers/DateHelper";
import "moment/locale/fr";
import "moment/locale/en-ca";

const dateFormatter = {
  formatDate: date => toLocalizedDateString(date),
  parseDate: str => new Date(str)
};

const getMaxDate = () => {
  const now = new Date();
  const year = now.getFullYear();

  return new Date(year + 10, 12, 31);
};

const DateInput = ({ maxDate, locale, ...rest }) => (
  <BpDateInput
    {...rest}
    {...dateFormatter}
    maxDate={maxDate || getMaxDate()}
    canClearSelection={false}
    locale={locale}
    localeUtils={LocaleUtils}
    inputProps={{ readOnly: "readonly" }}
  />
);

const mapStateToProps = ({ locales }) => ({
  locale: locales.lang
});

export default connect(mapStateToProps)(DateInput);
