import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import messages from "../../../../Projects.messages";
import { toLocalizedDateString } from "../../../../../../helpers/DateHelper";

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.tablet_max_width}) {
    display: none;
  }
`;

const SectionContainer = styled.div`
  display: flex;
  /* flex-direction: column;
  justify-content: center;
  align-items: left; */
  font-size: 0.9em;

  @media only screen and (min-width: ${({ theme }) =>
      theme.desktop_min_width}) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const InformationSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
`;

const ItemInformationTitle = styled.span`
  &:not(:last-of-type) {
    margin-right: 5px;
  }
`;

const ItemInformationValue = styled.span`
  font-weight: bold;
  color: ${({ hasAlert, theme }) => (hasAlert ? theme.red_danger : "")};
`;

const ItemInformationSections = ({ item }) => (
  <StyledContainer>
    <SectionContainer>
      <InformationSection>
        <ItemInformationTitle>
          <FormattedMessage {...messages.fields.manager} />
        </ItemInformationTitle>
        <ItemInformationValue
          hasAlert={item.manager.isDeleted || !item.manager.isActive}
        >{`${item.manager.firstname} ${
          item.manager.lastname
        }`}</ItemInformationValue>
      </InformationSection>

      <InformationSection>
        <ItemInformationTitle>
          <FormattedMessage {...messages.fields.priority} />
        </ItemInformationTitle>
        <ItemInformationValue>{item.priority}</ItemInformationValue>
      </InformationSection>
    </SectionContainer>
    <SectionContainer>
      <InformationSection>
        <ItemInformationTitle>
          <FormattedMessage {...messages.fields.deadlineDate} />
        </ItemInformationTitle>
        <ItemInformationValue>
          {toLocalizedDateString(item.deadlineDate)}
        </ItemInformationValue>
      </InformationSection>

      <InformationSection>
        <ItemInformationTitle>
          <FormattedMessage {...messages.fields.percentage} />
        </ItemInformationTitle>
        <ItemInformationValue>{item.progressPercentage} %</ItemInformationValue>
      </InformationSection>
    </SectionContainer>
  </StyledContainer>
);

ItemInformationSections.propTypes = {};

export default ItemInformationSections;
