import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { Intent } from "@blueprintjs/core";
import PageLayout from "../PageLayout";
import { LoadingOverlay } from "../../../../components/overlays";
import * as projectActions from "../../../../actions/project";
import * as userActions from "../../../../actions/user";
import AppToaster from "../../../../components/toasters";
import {
  Header,
  ProjectsTable,
  ProjectDialog
} from "../../../../components/projectsManagement";
import DeleteAlert from "../../../../components/alerts/DeleteAlert";
import { roles } from "../../../../components/roles";

class ProjectsManagementPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialog: {
        project: undefined
      },
      deleteAlert: {
        isOpen: false,
        projectId: 0
      }
    };
  }

  componentDidMount() {
    const { fetchProjects, fetchUsers } = this.props;

    fetchProjects(false);
    fetchUsers(roles.ProjectManager);
  }

  addProjectClicked = () => {
    const { openProjectDialog } = this.props;
    this.setState(prevState => ({
      ...prevState,
      dialog: {
        project: undefined
      }
    }));

    openProjectDialog();
  };

  deleteProjectClicked = projectId => {
    this.setState(prevState => ({
      ...prevState,
      deleteAlert: {
        isOpen: true,
        projectId
      }
    }));
  };

  editProjectClicked = projectId => {
    const { projects, openProjectDialog } = this.props;

    this.setState(prevState => ({
      ...prevState,
      dialog: {
        project: projects.find(x => x.id === projectId)
      }
    }));

    openProjectDialog();
  };

  saveProject = project => {
    const { saveProject, updateProject } = this.props;

    const action = project.id > 0 ? updateProject : saveProject;

    action(project).catch(err => {
      this.showErrorToast(err);
    });
  };

  deleteProject = () => {
    const {
      props: { deleteProject },
      state: { deleteAlert }
    } = this;

    deleteProject(deleteAlert.projectId)
      .then(() => {})
      .catch(error => this.showErrorToast(error))
      .finally(() => {
        this.closeDeleteAlert();
      });
  };

  downloadProjectReport = (projectId, projectName) => {
    const { downloadProjectReport } = this.props;

    downloadProjectReport(projectId, projectName);
  };

  closeProjectDialog = () => {
    const { closeProjectDialog } = this.props;
    this.setState(prevState => ({
      ...prevState,
      dialog: {
        isOpen: false,
        project: undefined
      }
    }));

    closeProjectDialog();
  };

  closeDeleteAlert = () => {
    this.setState(prevState => ({
      ...prevState,
      deleteAlert: {
        isOpen: false,
        projectId: 0
      }
    }));
  };

  showErrorToast = message => {
    AppToaster.show({
      intent: Intent.DANGER,
      message
    });
  };

  render() {
    const {
      props: {
        intl,
        loading,
        projects,
        users,
        isSavingProject,
        licenceInfos,
        projectDialogOpened
      },
      state: { dialog, deleteAlert }
    } = this;
    return (
      <PageLayout>
        {loading && <LoadingOverlay />}
        <Header
          intl={intl}
          addProjectClicked={this.addProjectClicked}
          licenceInfos={licenceInfos}
        />

        <ProjectsTable
          projects={projects}
          deleteProjectClicked={this.deleteProjectClicked}
          editProjectClicked={this.editProjectClicked}
          downloadProjectReportClicked={this.downloadProjectReport}
        />

        {projectDialogOpened && (
          <ProjectDialog
            isOpen={projectDialogOpened}
            project={dialog.project}
            onClose={this.closeProjectDialog}
            users={users}
            submit={this.saveProject}
            loading={isSavingProject}
          />
        )}

        <DeleteAlert
          isOpen={deleteAlert.isOpen}
          onCancel={this.closeDeleteAlert}
          onConfirm={this.deleteProject}
        />
      </PageLayout>
    );
  }
}

const mapStateToProps = ({ user, project, licence }) => ({
  projects: project.projects,
  users: user.users,
  loading: project.loading || user.isFetchingData,
  isSavingProject: project.isSavingProject,
  licenceInfos: licence.informations,
  projectDialogOpened: project.projectDialogOpened
});

ProjectsManagementPage.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loading: PropTypes.bool.isRequired,
  isSavingProject: PropTypes.bool.isRequired,
  fetchProjects: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  saveProject: PropTypes.func.isRequired,
  deleteProject: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  projectDialogOpened: PropTypes.bool.isRequired,
  downloadProjectReport: PropTypes.func.isRequired
};

export default injectIntl(
  connect(
    mapStateToProps,
    {
      fetchProjects: projectActions.fetchProjects,
      updateProject: projectActions.updateProject,
      saveProject: projectActions.saveProject,
      deleteProject: projectActions.deleteProject,
      fetchUsers: userActions.fetchUsers,
      openProjectDialog: projectActions.openProjectDialog,
      closeProjectDialog: projectActions.closeProjectDialog,
      downloadProjectReport: projectActions.downloadProjectReport
    }
  )(ProjectsManagementPage)
);
