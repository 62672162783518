import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Button } from "../../buttons";
import messages from "../Licences.messages";
import { ItemType } from "../../projects";

const Container = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: flex-end;
  height: 250px;
  padding: 15px 30px;

  position: relative;
  border: 5px solid transparent;
  border-radius: 16px;
  background: white;
  background-clip: padding-box;

  &::after {
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    background: ${({ theme, isActiveLicence }) =>
      isActiveLicence
        ? `linear-gradient(to top, ${theme.green_regular}, ${
            theme.blue_regular
          })`
        : `linear-gradient(to top, ${theme.purple_regular}, ${
            theme.blue_regular
          })`};
    content: "";
    z-index: -1;
    border-radius: 16px;
  }
`;

const Info = styled.p`
  font-weight: bold;
`;

const InfoArchive = styled.p`
  font-weight: bold;
  color: ${({ theme, canArchive }) =>
    canArchive ? theme.black : theme.gray_light};
`;

const getButtonContent = (isNewAccount, isActiveLicence) => {
  if (isNewAccount) {
    return <FormattedMessage {...messages.general.select} />;
  }
  if (isActiveLicence) {
    return <FormattedMessage {...messages.general.currentPackage} />;
  }
  return <FormattedMessage {...messages.general.updatePackage} />;
};

const InfoSection = ({
  maxProjectCount,
  maxUserCount,
  canArchiveProject,
  isNewAccount,
  isActiveLicence,
  updateLicenceClicked
}) => (
  <Container isActiveLicence={isActiveLicence}>
    <Info>
      {maxProjectCount} <FormattedMessage {...messages.general.project} />
    </Info>
    <Info>
      {maxUserCount === 0 ? (
        <FormattedMessage {...messages.general.unlimitedUsers} />
      ) : (
        <React.Fragment>
          {maxUserCount} <FormattedMessage {...messages.general.maxUsers} />
        </React.Fragment>
      )}
    </Info>
    <InfoArchive canArchive={canArchiveProject}>
      <FormattedMessage {...messages.general.projectArchiving} />
    </InfoArchive>

    <Button
      itemType={isActiveLicence ? "" : ItemType.ACTION}
      onClick={updateLicenceClicked}
      disabled={isActiveLicence}
    >
      {getButtonContent(isNewAccount, isActiveLicence)}
    </Button>
  </Container>
);

InfoSection.propTypes = {
  maxProjectCount: PropTypes.number.isRequired,
  maxUserCount: PropTypes.number.isRequired,
  canArchiveProject: PropTypes.bool.isRequired,
  isActiveLicence: PropTypes.bool.isRequired
};

export default InfoSection;
