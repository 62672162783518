import React from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import Gantt from "./Gantt";
import { ItemStatus } from "../projectSummarySection/items";
import theme from "../../../themes/theme";
import { Flex } from "../..";
import { H3 } from "../../text/Headers";

class ItemGanttChart extends React.Component {
  getColorForStatus = (status = 0) => {
    if (status === ItemStatus.OVERDUE) {
      return theme.red_danger;
    }

    if (status === ItemStatus.SOON_EXPIRED) {
      return theme.orange_warning;
    }

    return theme.green_success;
  };

  prepareTasks = () => {
    const { items } = this.props;

    return {
      data: items.map(item => ({
        id: item.id,
        text: `${item.name} (${item.progressPercentage}%)`,
        start_date: new Date(item.beginningDate),
        end_date: new Date(item.deadlineDate),
        progress: item.progressPercentage / 100,
        color: this.getColorForStatus(item.status),
        item
      }))
    };
  };

  render() {
    const { title, onItemSelected, onItemLongPressed, itemType } = this.props;
    return (
      <Flex direction="column" justify="center" margin="30px 0px">
        <H3>{title}</H3>
        <Gantt
          itemType={itemType}
          tasks={this.prepareTasks()}
          rowClicked={id => onItemSelected(id, itemType)}
          onItemLongPressed={onItemLongPressed}
        />
      </Flex>
    );
  }
}

ItemGanttChart.propTypes = {
  title: PropTypes.string.isRequired,
  itemType: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  onItemSelected: PropTypes.func.isRequired,
  onItemLongPressed: PropTypes.func.isRequired
};

export default injectIntl(ItemGanttChart);
