import { defineMessages } from "react-intl";

const messages = {
  general: {
    title: { id: "CheckItems.title" },
    empty: { id: "CheckItems.empty" },
    add: { id: "General.add" },
  },
};

const definedMessages = defineMessages(messages);

export default definedMessages;
