import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Icon, Intent } from "@blueprintjs/core";
import { Button, ButtonSize } from "../../../buttons";
import { UserSelect } from "../../../users";
import messages from "../../Projects.messages";
import FormContainer from "../../../forms/formContainer/FormContainer";
import FormSectionContainer from "../../../forms/formSection/FormSectionContainer";
import FormSection from "../../../forms/formSection/FormSection";
import ButtonSection from "../../../forms/formSection/ButtonSection";
import {
  InputGroup,
  Input,
  Label,
  TextArea,
  DateInput,
  MultiInput
} from "../../../forms";
import { toLocalizedDateString } from "../../../../helpers/DateHelper";
import { validateForm } from "./validation";
import ItemType from "../../itemType/ItemType";
import Flex from "../../../flex/Flex";

class StrategyForm extends React.Component {
  constructor(props) {
    super(props);

    const beginningDate =
      new Date(props.strategy.beginningDate) < props.validationDates.minDate ||
      new Date(props.strategy.beginningDate) > props.validationDates.maxDate
        ? props.validationDates.minDate
        : new Date(props.strategy.beginningDate);

    const deadlineDate =
      new Date(props.strategy.deadlineDate) > props.validationDates.maxDate ||
      new Date(props.strategy.deadlineDate) < props.validationDates.minDate
        ? props.validationDates.maxDate
        : new Date(props.strategy.deadlineDate);

    this.state = {
      data: {
        id: props.strategy.id,
        name: props.strategy.name,
        description: props.strategy.description,
        measuringTool:
          props.strategy.measuringTool === null
            ? ""
            : props.strategy.measuringTool,
        manager: {
          id:
            props.strategy.manager.id === 0
              ? props.users[0].id
              : props.strategy.manager.id
        },
        priority: props.strategy.priority,
        creationDate: new Date(props.strategy.creationDate),
        beginningDate,
        deadlineDate,
        updateDate: new Date(props.strategy.updateDate),
        progressPercentage: props.strategy.progressPercentage,
        note: props.strategy.note === null ? "" : props.strategy.note,
        benefits: props.strategy.benefits,
        manualProgressPercentage: props.strategy.manualProgressPercentage
      },
      errors: {}
    };
  }

  managerSelected = user => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        manager: {
          id: user.id
        }
      }
    }));
  };

  deadlineDateChanged = selectedDate => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        deadlineDate: selectedDate
      }
    }));
  };

  beginningDateChanged = selectedDate => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        beginningDate: selectedDate
      }
    }));
  };

  submit = event => {
    event.preventDefault();
    const {
      state: { data },
      props: { updateStrategy, itemType }
    } = this;

    const errors = this.validate(data);

    if (Object.keys(errors).length === 0) {
      updateStrategy(data, itemType);
    }

    this.setState({ errors });
  };

  validate = data => {
    const { intl } = this.props;
    return validateForm(data, ItemType.STRATEGY, intl);
  };

  onChange = ({ target: { value, name } }) => {
    const inputName = name;
    const inputValue = value;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [inputName]: inputValue
      }
    }));
  };

  benefitValueUpdated = (value, index) => {
    const {
      data: { benefits: oldbenefits }
    } = this.state;
    let benefits = null;
    if (index === oldbenefits.length) {
      benefits = oldbenefits.concat({ name: value });
    } else if (value === "") {
      benefits = [
        ...oldbenefits.slice(0, index),
        ...oldbenefits.slice(index + 1)
      ];
    } else {
      benefits = [
        ...oldbenefits.slice(0, index),
        { name: value },
        ...oldbenefits.slice(index + 1)
      ];
    }

    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        benefits
      }
    }));
  };

  render() {
    const {
      state: {
        data: {
          name,
          description,
          measuringTool,
          manager,
          creationDate,
          deadlineDate,
          priority,
          beginningDate,
          progressPercentage,
          updateDate,
          note,
          benefits,
          manualProgressPercentage
        },
        errors
      },
      props: { users, intl, validationDates, loading, strategy }
    } = this;

    const hasActions = strategy.actions && strategy.actions.length > 0;

    return (
      <FormContainer onSubmit={this.submit}>
        <FormSectionContainer>
          <FormSection
            itemType={ItemType.STRATEGY}
            title={intl.formatMessage(messages.dialog.generalInformations)}
          >
            <InputGroup error={errors.name}>
              <Label>
                <FormattedMessage {...messages.fields.name} />
              </Label>
              <Input name="name" value={name} onChange={this.onChange} />
            </InputGroup>
            <InputGroup error={errors.description}>
              <Label>Description</Label>
              <TextArea
                name="description"
                value={description}
                onChange={this.onChange}
              />
            </InputGroup>

            <InputGroup error={errors.measuringTool}>
              <Label>
                <FormattedMessage {...messages.fields.measuringTools} />
              </Label>
              <TextArea
                name="measuringTool"
                value={measuringTool}
                onChange={this.onChange}
              />
            </InputGroup>

            <InputGroup>
              <Label>
                <FormattedMessage {...messages.fields.manager} />
              </Label>
              <UserSelect
                users={users}
                activeUser={users[users.findIndex(x => x.id === manager.id)]}
                onUserSelected={this.managerSelected}
              />
            </InputGroup>

            <InputGroup error={errors.beginningDate}>
              <Label>
                <FormattedMessage {...messages.fields.beginningDate} />
              </Label>
              <DateInput
                onChange={this.beginningDateChanged}
                value={beginningDate}
                minDate={validationDates.minDate}
                maxDate={validationDates.maxDate}
              />
            </InputGroup>

            <InputGroup error={errors.deadlineDate}>
              <Label>
                <FormattedMessage {...messages.fields.deadlineDate} />
              </Label>
              <DateInput
                onChange={this.deadlineDateChanged}
                value={deadlineDate}
                minDate={validationDates.minDate}
                maxDate={validationDates.maxDate}
              />
            </InputGroup>

            <InputGroup error={errors.priority}>
              <Label>
                <FormattedMessage {...messages.fields.priority} />
              </Label>
              <Input
                name="priority"
                onChange={this.onChange}
                value={priority}
                type="number"
                min="1"
                max="4"
              />
            </InputGroup>
          </FormSection>
          <FormSection
            itemType={ItemType.STRATEGY}
            title={intl.formatMessage(messages.dialog.additionalInformations)}
          >
            <InputGroup>
              <Label>
                <FormattedMessage {...messages.fields.creationDate} />
              </Label>
              <Input
                name="creationDate"
                onChange={this.onChange}
                value={toLocalizedDateString(creationDate)}
                disabled
              />
            </InputGroup>

            <InputGroup>
              <Label>
                <FormattedMessage {...messages.fields.updateDate} />
              </Label>
              <Input
                name="updateDate"
                onChange={this.onChange}
                value={toLocalizedDateString(updateDate)}
                disabled
              />
            </InputGroup>

            <InputGroup>
              <Label>
                <FormattedMessage {...messages.fields.percentage} />
              </Label>
              {hasActions ? (
                <Input
                  name="progressPercentage"
                  onChange={this.onChange}
                  value={progressPercentage}
                  disabled
                />
              ) : (
                <Flex align="center">
                  <Input
                    min="0"
                    max="100"
                    name="manualProgressPercentage"
                    value={manualProgressPercentage}
                    onChange={this.onChange}
                  />
                  <Icon icon="edit" intent={Intent.PRIMARY} />
                </Flex>
              )}
            </InputGroup>

            <InputGroup error={errors.note}>
              <Label>
                <FormattedMessage {...messages.fields.notes} />
              </Label>
              <TextArea name="note" onChange={this.onChange} value={note} />
            </InputGroup>

            <InputGroup error={errors.benefits}>
              <Label>
                <FormattedMessage {...messages.fields.benefits} />
              </Label>
              <MultiInput
                values={benefits.map(x => x.name)}
                valueUpdated={this.benefitValueUpdated}
              />
            </InputGroup>
          </FormSection>
        </FormSectionContainer>
        <ButtonSection>
          <Button
            size={ButtonSize.MEDIUM}
            type="submit"
            itemType={ItemType.STRATEGY}
            disabled={loading}
          >
            <FormattedMessage {...messages.dialog.save} />
          </Button>
        </ButtonSection>
      </FormContainer>
    );
  }
}

StrategyForm.defaultProps = {
  strategy: {
    id: 0,
    name: "",
    description: "",
    measuringTool: "",
    manager: {
      id: 0
    },
    creationDate: new Date().toString(),
    deadlineDate: new Date().toString(),
    priority: 1,
    beginningDate: new Date().toString(),
    progressPercentage: 0,
    updateDate: new Date().toString(),
    note: "",
    benefits: [],
    manualProgressPercentage: 0
  }
};

StrategyForm.propTypes = {
  strategy: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    measuringTool: PropTypes.string,
    manager: PropTypes.shape(),
    creationDate: PropTypes.string,
    deadlineDate: PropTypes.string,
    priority: PropTypes.number,
    beginningDate: PropTypes.string,
    progressPercentage: PropTypes.number,
    updateDate: PropTypes.string,
    note: PropTypes.string,
    benefits: PropTypes.arrayOf(PropTypes.object),
    manualProgressPercentage: PropTypes.number
  }),
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateStrategy: PropTypes.func.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(StrategyForm);
