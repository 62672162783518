import styled from "styled-components";

const ItemBody = styled.div`
  position: relative;
  display: flex;
  align-content: center;
  justify-content: space-between;
  height: 100%;
  padding-left: 30px;
  padding-right: 15px;

  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;

  background: linear-gradient(to right, #eeeeee 60%, #ffffff);

  margin-left: ${({ isOpen }) => (isOpen ? "30px" : "")};
  /* transition: margin-left 0.25s ease-out; */
`;

export default ItemBody;
