import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import ItemHeaderContainer from "./headerContainer/ItemHeaderContainer";
import ItemHeaderLeftBar from "./headerLeftBar/ItemHeaderLeftBar";
import ItemBody from "./headerBody/ItemBody";
import ItemTitle from "./headerTitle/ItemTitle";
import ItemStatusBar from "./headerStatus/ItemStatusBar";
import ItemInformationSections from "./headerInformationSection/ItemInformationSections";
import ItemActionSection from "./headerActions/ItemActionSection";
import ItemHeaderPopover from "./headerPopover/ItemHeaderPopover";
import { Flex } from "../../../..";
import ItemInfo from "./headerInfo/ItemInfo";

class ItemHeader extends React.Component {
  addButtonClicked = () => {
    const {
      userActions: { addButtonClicked },
      itemType,
      item,
      goalId,
      strategyId,
    } = this.props;

    addButtonClicked(itemType, {
      currentItemId: item.id,
      parents: {
        goalId,
        strategyId,
      },
    });
  };

  deleteButtonClicked = () => {
    const {
      userActions: { deleteButtonClicked },
      item,
      itemType,
      goalId,
      strategyId,
    } = this.props;

    deleteButtonClicked(item, itemType, {
      goalId,
      strategyId,
    });
  };

  editButtonClicked = () => {
    const {
      item,
      itemType,
      goalId,
      strategyId,
      userActions: { editButtonClicked },
    } = this.props;

    editButtonClicked(item, itemType, goalId, strategyId);
  };

  itemUsersButtonClicked = () => {
    const {
      userActions: { itemUsersButtonClicked },
      item,
      itemType,
      goalId,
      strategyId,
    } = this.props;

    itemUsersButtonClicked(item, itemType, {
      goalId,
      strategyId,
    });
  };

  commentButtonClick = () => {
    const {
      item,
      goalId,
      strategyId,
      userActions: { commentButtonClick },
    } = this.props;

    commentButtonClick(item.componentGuid, item.id, { goalId, strategyId });
  };

  attachmentButtonClick = () => {
    const {
      item,
      goalId,
      strategyId,
      userActions: { attachmentButtonClick },
    } = this.props;

    attachmentButtonClick(item.componentGuid, item.id, { goalId, strategyId });
  };

  checkItemButtonClick = (canEdit) => {
    const {
      item,
      goalId,
      strategyId,
      userActions: { checkItemButtonClick },
    } = this.props;

    checkItemButtonClick(canEdit, item.componentGuid, item.id, {
      goalId,
      strategyId,
    });
  };

  render() {
    const {
      item,
      isOpen,
      onOpenClick,
      itemType,
      itemStatus,
      permissions,
    } = this.props;

    return (
      <ItemHeaderContainer>
        <ItemHeaderLeftBar isOpen={isOpen} itemType={itemType} />
        <ItemBody isOpen={isOpen}>
          <Flex align="center" minWidth="0" margin="0px 5px 0px 0px">
            <ItemTitle
              onClick={onOpenClick}
              itemType={itemType}
              name={item.name}
            />

            <ItemHeaderPopover item={item}>
              <ItemInfo itemType={itemType} />
            </ItemHeaderPopover>
          </Flex>

          <Flex align="center" shrink="0">
            <ItemInformationSections item={item} />

            <ItemActionSection
              itemType={itemType}
              editButtonClicked={this.editButtonClicked}
              itemUsersButtonClicked={this.itemUsersButtonClicked}
              addButtonClicked={this.addButtonClicked}
              deleteButtonClicked={this.deleteButtonClicked}
              commentButtonClick={this.commentButtonClick}
              attachmentButtonClick={this.attachmentButtonClick}
              checkItemButtonClick={this.checkItemButtonClick}
              permissions={permissions}
              hasComments={item.hasComments}
              hasAttachments={item.hasAttachments}
              hasCheckItems={item.hasCheckItems}
              isAllItemChecked={item.isAllItemChecked}
              hasUsers={item.hasUsers}
            />

            <ItemStatusBar itemStatus={itemStatus} />
          </Flex>
        </ItemBody>
      </ItemHeaderContainer>
    );
  }
}

ItemHeader.defaultProps = {
  goalId: 0,
  strategyId: 0,
};

ItemHeader.propTypes = {
  item: PropTypes.shape().isRequired,
  isOpen: PropTypes.bool.isRequired,
  onOpenClick: PropTypes.func.isRequired,
  itemType: PropTypes.string.isRequired,
  itemStatus: PropTypes.number.isRequired,
  goalId: PropTypes.number,
  strategyId: PropTypes.number,
  userActions: PropTypes.shape({}).isRequired,
};

export default injectIntl(ItemHeader);
