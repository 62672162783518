import React from "react";
import { Dialog } from "@blueprintjs/core";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ItemType } from "..";
import { FormattedMessage } from "react-intl";
import { GoalForm, StrategyForm, ActionForm } from "./forms";
import messages from "../Projects.messages";
import { LoadingOverlay } from "../../overlays";

const StyledDialog = styled(Dialog)`
  position: relative;
  max-width: 1000px !important;
  width: 80% !important;
`;

class ComponentDialog extends React.Component {
  onClose = () => {
    const { closeDialog } = this.props;

    closeDialog();
  };

  getDialogTitle = itemType => (
    <FormattedMessage {...messages.itemType[itemType.toLowerCase()]} />
  );

  render() {
    const {
      isOpen,
      item,
      itemType,
      users,
      projectItemUpdated,
      loading,
      validationDates
    } = this.props;
    return (
      <StyledDialog
        onClose={this.onClose}
        isOpen={isOpen}
        title={itemType !== "" ? this.getDialogTitle(itemType) : ""}
        canOutsideClickClose={false}
      >
        {loading && <LoadingOverlay />}
        {(() => {
          switch (itemType) {
            case ItemType.OBJECTIVE:
              return (
                <GoalForm
                  users={users}
                  goal={item}
                  itemType={itemType}
                  updateGoal={projectItemUpdated}
                  validationDates={validationDates}
                  loading={loading}
                />
              );
            case ItemType.STRATEGY:
              return (
                <StrategyForm
                  users={users}
                  strategy={item}
                  itemType={itemType}
                  updateStrategy={projectItemUpdated}
                  validationDates={validationDates}
                  loading={loading}
                />
              );
            case ItemType.ACTION:
              return (
                <ActionForm
                  users={users}
                  action={item}
                  itemType={itemType}
                  updateAction={projectItemUpdated}
                  validationDates={validationDates}
                  loading={loading}
                />
              );
            default:
              return null;
          }
        })()}
      </StyledDialog>
    );
  }
}

ComponentDialog.defaultProps = {
  users: [],
  item: undefined
};

ComponentDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  itemType: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape()),
  projectItemUpdated: PropTypes.func.isRequired,
  item: PropTypes.shape()
};

export default ComponentDialog;
