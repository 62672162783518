import styled from "styled-components";

export const SectionContainer = styled.div`
  margin-bottom: 10px;
`;

export const SectionHeader = styled.p`
  cursor: pointer;
  font-weight: bold;
  font-size: 1.1em;
  color: ${({ theme }) => theme.gray_dark};
`;

export const Toggle = styled.span`
  font-size: 1.2em;
  &::before {
    content: "\>";
    display: inline-block;
    transform: ${({ opened }) => (opened ? "rotate(90deg)" : "rotate(0deg)")};
    transition: 200ms all linear;
    margin-right: 5px;
  }
`;
