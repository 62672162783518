import decode from "jwt-decode";
import { USER_LOGGED_IN, USER_LOGGED_OUT } from "./types";
import api from "../api";
import setAuthorizationHeader from "../utils/setAuthorizationHeader";

export const userLoggedIn = (user) => ({
  type: USER_LOGGED_IN,
  user,
});

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
});

export const getAccountFromToken = (token) => {
  const payload = decode(token);
  const account = {
    token,
    email: payload.email,
    role: parseInt(payload.role, 10),
    id: parseInt(payload.nameid, 10),
  };

  return account;
};

const logginUser = (token, dispatch) => {
  localStorage.token = token;
  setAuthorizationHeader(token);

  const account = getAccountFromToken(token);
  dispatch(userLoggedIn(account));
};

export const login = (credentials) => (dispatch) =>
  api.auth.login(credentials).then((res) => {
    const { data } = res;
    logginUser(data.token, dispatch);
  });

export const register = (userData) => (dispatch) =>
  api.auth.register(userData).then((res) => {
    const { data } = res;
    logginUser(data.token, dispatch);
  });

export const registerCompany = (userData) => (dispatch) =>
  api.auth.registerCompany(userData).then((res) => {
    const { data } = res;
    logginUser(data.token, dispatch);
  });

export const logout = () => (dispatch) => {
  localStorage.removeItem("token");
  setAuthorizationHeader();
  dispatch(userLoggedOut());
};

export const resetPasswordRequest = (data) => () =>
  api.auth.resetPasswordRequest(data);

export const unsubscribe = (data) => () => api.auth.unsubscribe(data);

export const resetPassword = (data) => () => api.auth.resetPassword(data);
