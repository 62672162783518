import React from "react";
import MaterialTable from "material-table";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PopoverPosition } from "@blueprintjs/core";
import { ItemStatus } from "../projects/projectSummarySection/items";
import messages from "./Tasks.messages";
import ItemHeaderPopover from "../projects/projectSummarySection/items/itemHeader/headerPopover/ItemHeaderPopover";
import ItemInfo from "../projects/projectSummarySection/items/itemHeader/headerInfo/ItemInfo";
import { toLocalizedDateString } from "../../helpers/DateHelper";

const TaskContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CurrentTaskContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  margin: 10px 0px;
`;

const ParentContainer = styled.div`
  font-size: 0.85em;
`;

const StatusIndicator = styled.div`
  min-width: 10px;
  min-height: 10px;
  width: 10px;
  height: 10px;
  border-radius: 55px;
  margin-right: 5px;

  background: ${({ theme, status }) => {
    if (status === ItemStatus.UP_TO_DATE) return theme.green_success;
    if (status === ItemStatus.SOON_EXPIRED) return theme.orange_warning;
    if (status === ItemStatus.OVERDUE) return theme.red_danger;
    throw new Error("Unsupported item status");
  }};
`;

const getSearchValue = (id, parentsIds) => {
  let searchvalue = `project=${parentsIds.projectId}`;

  searchvalue += `&goal=${parentsIds.goalId === 0 ? id : parentsIds.goalId}`;
  searchvalue += `&strategy=${
    parentsIds.goalId !== 0 && parentsIds.strategyId === 0
      ? id
      : parentsIds.strategyId
  }`;
  searchvalue += `&action=${parentsIds.strategyId !== 0 ? id : "0"}`;

  return searchvalue;
};

const TasksTable = ({ tasks, intl }) => (
  <React.Fragment>
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
    />
    <MaterialTable
      localization={{
        body: {
          emptyDataSourceMessage: intl.formatMessage(
            messages.general.noAssignedTask
          ),
        },
      }}
      columns={[
        {
          title: intl.formatMessage(messages.fields.task),
          field: "taskName",
          render: (rowData) => (
            <TaskContainer>
              <ParentContainer>{rowData.parentsDescription}</ParentContainer>
              <CurrentTaskContainer>
                <StatusIndicator status={rowData.status} />
                <Link
                  to={{
                    pathname: "/project-summary",
                    search: getSearchValue(rowData.id, rowData.parentsIds),
                  }}
                >
                  {rowData.taskName}
                </Link>

                <ItemHeaderPopover
                  item={rowData}
                  position={PopoverPosition.BOTTOM_LEFT}
                >
                  <ItemInfo margin="0 0 0 5px" iconSize={14} />
                </ItemHeaderPopover>
              </CurrentTaskContainer>
            </TaskContainer>
          ),
        },
        {
          title: intl.formatMessage(messages.fields.manager),
          render: (rowData) => (
            <React.Fragment>
              {rowData.manager.firstname} {rowData.manager.lastname}
            </React.Fragment>
          ),
        },
        {
          title: intl.formatMessage(messages.fields.deadlineDate),
          field: "deadlineDate",
          render: (rowData) => (
            <React.Fragment>
              {toLocalizedDateString(rowData.deadlineDate)}
            </React.Fragment>
          ),
        },
        {
          title: intl.formatMessage(messages.fields.priority),
          field: "priority",
        },
        {
          title: intl.formatMessage(messages.fields.progress),
          field: "progressPercentage",
        },
      ]}
      data={tasks}
      options={{ toolbar: false, paging: false }}
    />
  </React.Fragment>
);

TasksTable.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(TasksTable);
