import {
  FETCH_TASKS_BEGIN,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAILURE
} from "../actions/types";

const initialState = {
  tasks: [],
  loading: false,
  error: null
};

export default function task(state = initialState, action) {
  switch (action.type) {
    case FETCH_TASKS_BEGIN:
      return {
        tasks: [],
        loading: true,
        error: null
      };
    case FETCH_TASKS_SUCCESS:
      return {
        tasks: action.payload,
        loading: false,
        error: null
      };
    case FETCH_TASKS_FAILURE:
      return {
        tasks: [],
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
