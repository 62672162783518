import React from "react";
import { MenuItem, Button, PopoverPosition } from "@blueprintjs/core";
import PropTypes from "prop-types";
import { ItemType } from "..";
import { FormattedMessage } from "react-intl";
import ItemActionSection from "../projectSummarySection/items/itemHeader/headerActions/ItemActionSection";
import messages from "../Projects.messages";
import { roles, isAdmin, isManagement } from "../../roles";
import {
  HeaderContainer,
  StyledSelect,
  StyledFieldSet,
  StyledLegend,
} from "./Styles";

class ProjectSummaryHeader extends React.Component {
  handleAddGoalClick = () => {
    const { addGoalClicked } = this.props;

    addGoalClicked(ItemType.OBJECTIVE);
  };

  projectItemSelected = (project) => {
    const { projectSelected } = this.props;

    projectSelected(project.id);
  };

  getPermissions = () => {
    const { currentUser } = this.props;
    const hasFullAccess = isAdmin(currentUser) || isManagement(currentUser);
    return {
      canEdit: hasFullAccess || this.canEdit(),
      canAssociate: hasFullAccess || this.canAssociate(),
      canAdd: hasFullAccess || this.canAdd(),
      canRemove: hasFullAccess || this.canRemove(),
      canComment: true,
      canAttachment: true,
      canCheckItem: true,
    };
  };

  canAdd = () => this.isProjectManager() || this.isItemManager();

  canRemove = () => this.isProjectManager();

  canAssociate = () => this.isProjectManager() || this.isItemManager();

  canEdit = () => this.isProjectManager();

  canAddOrRemove = () => {
    const { currentUser, selectedProject } = this.props;

    if (isAdmin() || isManagement()) {
      return true;
    }

    return (
      selectedProject &&
      selectedProject.manager &&
      currentUser.id === selectedProject.manager.id
    );
  };

  isItemManager = () => {
    const { currentUser, selectedProject } = this.props;

    return currentUser.id === selectedProject.manager.id;
  };

  isProjectManager = () => {
    const { currentUser, selectedProject } = this.props;

    return (
      selectedProject &&
      selectedProject.manager &&
      currentUser.id === selectedProject.manager.id
    );
  };

  projectItemRenderer = (project, { handleClick, modifiers: { active } }) => (
    <MenuItem
      key={project.id}
      text={project.name}
      onClick={handleClick}
      active={active}
    />
  );

  itemUsersButtonClicked = () => {
    const {
      userActions: { itemUsersButtonClicked },
      selectedProject,
    } = this.props;

    itemUsersButtonClicked(selectedProject, ItemType.PROJECT, {});
  };

  commentButtonClick = () => {
    const {
      selectedProject,
      userActions: { commentButtonClick },
    } = this.props;

    commentButtonClick(selectedProject.componentGuid, selectedProject.id, {});
  };

  attachmentButtonClick = () => {
    const {
      selectedProject,
      userActions: { attachmentButtonClick },
    } = this.props;

    attachmentButtonClick(
      selectedProject.componentGuid,
      selectedProject.id,
      {}
    );
  };

  checkItemButtonClick = (canEdit) => {
    const {
      selectedProject,
      userActions: { checkItemButtonClick },
    } = this.props;

    checkItemButtonClick(
      canEdit,
      selectedProject.componentGuid,
      selectedProject.id,
      {}
    );
  };

  canUseHeadQuarter = () => {
    const { selectedProject, currentUser } = this.props;
    const hasFullAccess = isAdmin(currentUser) || isManagement(currentUser);

    return (
      hasFullAccess ||
      selectedProject.manager.id === currentUser.id ||
      selectedProject.assignedUsers.findIndex((x) => x.id === currentUser.id) >=
        0
    );
  };

  renderAddButton = () => {
    const { selectedProject, currentUser } = this.props;
    const isProjectManager =
      selectedProject.manager && selectedProject.manager.id === currentUser.id;
    const canAdd = isProjectManager || currentUser.role >= roles.Management;
    if (canAdd) {
      return (
        <Button onClick={this.handleAddGoalClick} className="default-button">
          <FormattedMessage {...messages.header.addGoal} />
        </Button>
      );
    }

    return null;
  };

  render() {
    const { projects, selectedProject } = this.props;

    return (
      <HeaderContainer>
        <div style={{ display: "flex" }}>
          <StyledSelect
            activeItem={selectedProject}
            items={projects}
            itemRenderer={this.projectItemRenderer}
            onItemSelect={this.projectItemSelected}
            filterable={false}
            popoverProps={{
              boundary: "window",
              position: PopoverPosition.BOTTOM_RIGHT,
            }}
            text={selectedProject.name}
          />
          {this.canUseHeadQuarter() && (
            <StyledFieldSet>
              <StyledLegend>QG</StyledLegend>
              <ItemActionSection
                itemType={ItemType.PROJECT}
                editButtonClicked={this.editButtonClicked}
                itemUsersButtonClicked={this.itemUsersButtonClicked}
                addButtonClicked={this.addButtonClicked}
                deleteButtonClicked={this.deleteButtonClicked}
                commentButtonClick={this.commentButtonClick}
                attachmentButtonClick={this.attachmentButtonClick}
                checkItemButtonClick={this.checkItemButtonClick}
                permissions={this.getPermissions()}
                hasComments={selectedProject.hasComments}
                hasAttachments={selectedProject.hasAttachments}
                hasUsers={selectedProject.hasUsers}
              />
            </StyledFieldSet>
          )}
        </div>
        {this.renderAddButton()}
      </HeaderContainer>
    );
  }
}

ProjectSummaryHeader.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  selectedProject: PropTypes.shape().isRequired,
  projectSelected: PropTypes.func.isRequired,
  addGoalClicked: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  userActions: PropTypes.shape({}).isRequired,
};

export default ProjectSummaryHeader;
