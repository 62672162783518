import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import PriceSection from "./PriceSection";
import InfoSection from "./InfoSection";

const Container = styled.div`
  position: relative;
  margin: 15px;
`;

const LicenceCard = ({
  licence,
  isActiveLicence,
  updateLicenceClicked,
  isNewAccount
}) => (
  <Container>
    <PriceSection
      name={licence.name}
      monthlyPrice={licence.monthlyPrice}
      isActiveLicence={isActiveLicence}
    />

    <InfoSection
      maxProjectCount={licence.maximumActiveProject}
      maxUserCount={licence.maximumUserCount}
      canArchiveProject={licence.canArchiveProject}
      isActiveLicence={isActiveLicence}
      updateLicenceClicked={updateLicenceClicked}
      isNewAccount={isNewAccount}
    />
  </Container>
);

LicenceCard.propTypes = {
  licence: PropTypes.shape({}).isRequired,
  isActiveLicence: PropTypes.bool.isRequired,
  updateLicenceClicked: PropTypes.func.isRequired,
  isNewAccount: PropTypes.bool.isRequired
};

export default LicenceCard;
