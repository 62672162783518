import {
  SET_LICENCE_INFORMATIONS,
  FETCH_LICENCE_TYPES_BEGIN,
  FETCH_LICENCE_TYPES_SUCCESS,
  FETCH_LICENCE_TYPES_FAILURE,
  STRIPE_CREATE_TOKEN_BEGIN,
  STRIPE_CREATE_TOKEN_SUCCESS,
  STRIPE_CREATE_TOKEN_FAILURE,
  UPDATE_LICENCE_BEGIN,
  UPDATE_LICENCE_SUCCESS,
  UPDATE_LICENCE_FAILURE,
  DISABLE_RECURING_BILLING_BEGIN,
  DISABLE_RECURING_BILLING_SUCCESS,
  DISABLE_RECURING_BILLING_FAILURE
} from "../actions/types";

const initialState = {
  informations: {
    isActive: true
  },
  licenceTypes: [],
  loading: false,
  updating: false,
  error: null
};

export default function licence(state = initialState, action) {
  switch (action.type) {
    case SET_LICENCE_INFORMATIONS:
      return {
        ...state,
        informations: action.payload
      };
    case FETCH_LICENCE_TYPES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_LICENCE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        licenceTypes: action.payload.sort((a, b) => (a.rank > b.rank ? 1 : -1))
      };
    case FETCH_LICENCE_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        licenceTypes: []
      };
    case STRIPE_CREATE_TOKEN_BEGIN:
      return {
        ...state,
        loading: true
      };
    case STRIPE_CREATE_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case STRIPE_CREATE_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_LICENCE_BEGIN:
      return {
        ...state,
        updating: true,
        error: null
      };
    case UPDATE_LICENCE_SUCCESS:
      return {
        ...state,
        updating: false
      };
    case UPDATE_LICENCE_FAILURE:
      return {
        ...state,
        updating: false,
        error: action.payload
      };
    case DISABLE_RECURING_BILLING_BEGIN:
      return {
        ...state,
        updating: true,
        error: null
      };
    case DISABLE_RECURING_BILLING_SUCCESS:
      return {
        ...state,
        updating: false
      };
    case DISABLE_RECURING_BILLING_FAILURE:
      return {
        ...state,
        updating: false,
        error: action.payload
      };
    default:
      return state;
  }
}