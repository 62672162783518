import {
  FETCH_COMMENTS_BEGIN,
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_FAILURE,
  SAVE_COMMENT_BEGIN,
  SAVE_COMMENT_SUCCESS,
  SAVE_COMMENT_FAILURE
} from "../actions/types";

const initialState = {
  comments: [],
  loading: false,
  error: null
};

export default function comment(state = initialState, action) {
  switch (action.type) {
    case FETCH_COMMENTS_BEGIN:
      return {
        comments: [],
        loading: true,
        error: null
      };
    case FETCH_COMMENTS_SUCCESS:
      return {
        comments: action.payload.items,
        loading: false,
        error: null
      };
    case FETCH_COMMENTS_FAILURE:
      return {
        comments: [],
        loading: false,
        error: action.payload
      };
    case SAVE_COMMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SAVE_COMMENT_SUCCESS: {
      return {
        comments: [action.payload.comment, ...state.comments],
        loading: false,
        error: null
      };
    }
    case SAVE_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
