import { defineMessages } from "react-intl";

const messages = {
  creditCard: {
    nameOnCard: { id: "Licences.creditCard.nameOnCard" },
    cardNumber: { id: "Licences.creditCard.cardNumber" },
    cardExpiry: { id: "Licences.creditCard.cardExpiry" },
    cardCVC: { id: "Licences.creditCard.cardCVC" }
  }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
