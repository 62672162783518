import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import LicenceCard from "./licenceCard/LicenceCard";
import { H2 } from "../text/Headers";
import messages from "./Licences.messages";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.mobile_small_max_width}) {
    flex-direction: column;
  }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  @media (max-width: ${({ theme }) => theme.mobile_small_max_width}) {
    flex-direction: column;
  }
`;

const renderLicenceCards = (
  licences,
  activeLicenceType,
  updateLicenceClicked,
  isNewAccount
) =>
  licences.map(licence => (
    <LicenceCard
      licence={licence}
      key={licence.name}
      isActiveLicence={licence.name === activeLicenceType}
      updateLicenceClicked={() => updateLicenceClicked(licence.name)}
      isNewAccount={isNewAccount}
    />
  ));

const CardSection = ({
  licences,
  activeLicenceType,
  updateLicenceClicked,
  isNewAccount
}) => (
  <MainContainer>
    <H2>
      <FormattedMessage {...messages.general.selectLicence} />
    </H2>
    <CardContainer>
      {renderLicenceCards(
        licences,
        activeLicenceType,
        updateLicenceClicked,
        isNewAccount
      )}
    </CardContainer>
  </MainContainer>
);

export default CardSection;
