import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Header from "../../../components/headers";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: linear-gradient(
    to bottom,
    ${props => props.theme.green_regular} 0%,
    ${props => props.theme.blue_regular} 51%,
    ${props => props.theme.purple_regular} 100%
  );
`;

const MainContent = styled.div`
  display: flex;
  justify-content: center;
`;

const PageLayout = ({ children }) => (
  <Container>
    <Header withColor={false} showMenu={false} />
    <MainContent>{children}</MainContent>
  </Container>
);

PageLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default PageLayout;
