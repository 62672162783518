import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { InputStyle, BorderStyle, DisplayStyle } from "../styles/Styles";

const StyledInput = styled.input`
  min-height: 2.5em;
  border: 1px solid;

  border-color: ${({ inputStyle, theme }) => {
    if (inputStyle === InputStyle.COLORED) return theme.blue_regular;
    return "#00000021";
  }};

  border-radius: ${({ borderStyle }) => {
    if (borderStyle === BorderStyle.ROUND) return "55px";
    if (borderStyle === BorderStyle.SQUARE) return "50px";
    return "10px";
  }};

  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0px 15px;
  width: 100%;
  color: ${({ theme }) => theme.gray_dark};
  min-width: ${({ minwidth }) => minwidth || "auto"};

  &::-webkit-input-placeholder,
  ::placeholder {
    color: ${({ inputStyle, theme }) => {
      if (inputStyle === InputStyle.COLORED) return theme.blue_regular;
      return theme.gray_dark;
    }};
    font-weight: bold;
    text-transform: lowercase;
  }

  &:focus {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  &:disabled {
    background: ${({ theme }) => theme.gray_very_light};
    box-shadow: none;
  }

  &[type="checkbox"] {
    min-height: 0px;
    box-shadow: none;
  }
`;

const Input = ({
  name,
  value,
  onChange,
  disabled,
  type,
  placeholder,
  borderStyle,
  inputStyle,
  ...rest
}) => (
  <StyledInput
    name={name}
    value={value}
    onChange={onChange}
    disabled={disabled}
    type={type}
    placeholder={placeholder}
    borderStyle={borderStyle}
    inputStyle={inputStyle}
    {...rest}
  />
);

Input.defaultProps = {
  disabled: false,
  type: "text",
  placeholder: "",
  borderStyle: BorderStyle.SQUARE,
  inputStyle: InputStyle.REGULAR,
  displayStyle: DisplayStyle.BLOCK
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  borderStyle: PropTypes.string,
  inputStyle: PropTypes.string,
  displayStyle: PropTypes.string
};

export { Input };
