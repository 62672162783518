import React from "react";
import PropTypes from "prop-types";
import { StyledAttachmentSection } from "./Styles";
import Attachment from "./Attachment";

export class AttachmentSection extends React.Component {
  attachmentSection = React.createRef();

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    this.attachmentSection.current.scrollIntoView({ behavior: "auto" });
  };

  render() {
    const { attachments, fetchFile, removeAttachment } = this.props;
    const reversedAttachments = attachments.slice().reverse();
    return (
      <StyledAttachmentSection>
        {reversedAttachments.map((attachment) => (
          <Attachment
            key={attachment.id}
            attachment={attachment}
            removeAttachment={removeAttachment}
            fetchFile={fetchFile}
          />
        ))}

        <div ref={this.attachmentSection} />
      </StyledAttachmentSection>
    );
  }
}

AttachmentSection.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeAttachment: PropTypes.func.isRequired,
  fetchFile: PropTypes.func.isRequired,
};

export default AttachmentSection;
