import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledFormSection = styled.div`
  flex-basis: 50%;
  flex-grow: 0;

  &:last-of-type {
    margin-left: 30px;

    @media (max-width: ${({ theme }) => theme.mobile_max_width}) {
      margin-left: 0px;
    }
  }

  &:not(:last-of-type) {
    margin-right: 30px;

    @media (max-width: ${({ theme }) => theme.mobile_max_width}) {
      margin-right: 0px;
    }
  }
`;

const FormSection = ({ children }) => (
  <StyledFormSection>{children}</StyledFormSection>
);

FormSection.propTypes = {
  children: PropTypes.node.isRequired
};

export default FormSection;
