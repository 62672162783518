import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledHamburger = styled.div`
  span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;

    background: ${props => props.theme.white};
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

    opacity: ${props => (props.isOpen ? "1" : "")};
    transform: ${props =>
      props.isOpen ? "rotate(-45deg) translate(-2px, -1px)" : ""};
  }

  span:first-child {
    transform-origin: 0% 0%;
  }

  span:nth-last-child(2) {
    transform-origin: 0% 100%;
    opacity: ${props => (props.isOpen ? "0" : "")};
    transform: ${props => (props.isOpen ? "rotate(0deg) scale(0.2, 0.2)" : "")};
  }

  span:nth-last-child(3) {
    transform: ${props =>
      props.isOpen ? "rotate(45deg) translate(0, -1px)" : ""};
  }

  &:hover {
    cursor: pointer;
  }
`;

const Hamburger = ({ isOpen, onClick }) => (
  <StyledHamburger
    isOpen={isOpen}
    onClick={onClick}
    className="ignore-react-onclickoutside"
  >
    <span />
    <span />
    <span />
  </StyledHamburger>
);

Hamburger.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Hamburger;
