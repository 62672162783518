import {
  FETCH_CHECKITEMS_BEGIN,
  FETCH_CHECKITEMS_SUCCESS,
  FETCH_CHECKITEMS_FAILURE,
  POST_CHECKITEM_EDIT_BEGIN,
  POST_CHECKITEM_EDIT_SUCCESS,
  POST_CHECKITEM_EDIT_FAILURE,
  SAVE_CHECKITEM_BEGIN,
  SAVE_CHECKITEM_SUCCESS,
  SAVE_CHECKITEM_FAILURE,
  REMOVE_CHECKITEM_BEGIN,
  REMOVE_CHECKITEM_SUCCESS,
  REMOVE_CHECKITEM_FAILURE,
} from "./types";
import api from "../api";

const fetchCheckItemsBegin = () => ({
  type: FETCH_CHECKITEMS_BEGIN,
});

const fetchCheckItemsSuccess = (checkItems) => ({
  type: FETCH_CHECKITEMS_SUCCESS,
  payload: checkItems,
});

const fetchCheckItemsFailure = (error) => ({
  type: FETCH_CHECKITEMS_FAILURE,
  payload: error,
});

const postCheckItemEditBegin = () => ({
  type: POST_CHECKITEM_EDIT_BEGIN,
});

const postCheckItemEditSuccess = (checkItem) => ({
  type: POST_CHECKITEM_EDIT_SUCCESS,
  payload: checkItem,
});

const postCheckItemEditFailure = (error) => ({
  type: POST_CHECKITEM_EDIT_FAILURE,
  payload: error,
});
const saveCheckItemBegin = () => ({
  type: SAVE_CHECKITEM_BEGIN,
});

const saveCheckItemSuccess = (checkItem) => ({
  type: SAVE_CHECKITEM_SUCCESS,
  payload: checkItem,
});

const saveCheckItemFailure = (error) => ({
  type: SAVE_CHECKITEM_FAILURE,
  payload: error,
});

const removeCheckItemBegin = () => ({
  type: REMOVE_CHECKITEM_BEGIN,
});

const removeCheckItemSuccess = (checkItem) => ({
  type: REMOVE_CHECKITEM_SUCCESS,
  payload: checkItem,
});

const removeCheckItemFailure = (error) => ({
  type: REMOVE_CHECKITEM_FAILURE,
  payload: error,
});

export const fetchCheckItems = (componentGuid, paging) => (dispatch) => {
  dispatch(fetchCheckItemsBegin());

  return api.checkItem
    .get(componentGuid, paging)
    .then((res) => dispatch(fetchCheckItemsSuccess(res.data)))
    .catch((err) => dispatch(fetchCheckItemsFailure(err)));
};

export const postCheckItemEdit = (
  componentGuid,
  checkItemId,
  check,
  text,
  itemId,
  itemParents,
  isAllItemChecked
) => (dispatch) => {
  dispatch(postCheckItemEditBegin());

  return api.checkItem
    .edit(componentGuid, checkItemId, check, text)
    .then((res) => {
      dispatch(
        postCheckItemEditSuccess({
          checkItem: res.data,
          itemId,
          itemParents,
          isAllItemChecked,
        })
      );
      return res.data;
    })
    .catch((err) => dispatch(postCheckItemEditFailure(err)));
};

export const saveCheckItem = (
  componentGuid,
  check,
  text,
  itemId,
  itemParents,
  isAllItemChecked
) => (dispatch) => {
  dispatch(saveCheckItemBegin());

  return api.checkItem
    .post(componentGuid, check, text)
    .then((res) =>
      dispatch(
        saveCheckItemSuccess({
          checkItem: res.data,
          itemId,
          itemParents,
          isAllItemChecked,
        })
      )
    )
    .catch((err) => dispatch(saveCheckItemFailure(err)));
};

export const removeCheckItem = (
  componentGuid,
  checkItemId,
  itemId,
  itemParents,
  hasItem,
  isAllItemChecked
) => (dispatch) => {
  dispatch(removeCheckItemBegin());

  return api.checkItem
    .remove(componentGuid, checkItemId)
    .then((res) => {
      dispatch(
        removeCheckItemSuccess({
          checkItemId,
          itemId,
          itemParents,
          hasItem,
          isAllItemChecked,
        })
      );
      return res.data;
    })
    .catch((err) => dispatch(removeCheckItemFailure(err)));
};
