import React from "react";
import {
  Dialog,
  Card,
  RadioGroup,
  Radio,
  Switch,
  Collapse,
  Button,
  Intent,
} from "@blueprintjs/core";
import { injectStripe } from "react-stripe-elements";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import { LoadingOverlay } from "../../overlays";
import { BillingMode } from "../BillingMode";
import { Flex } from "../..";
import messages from "../Licences.messages";
import CreditCardForm from "../../creditCard/CreditCardForm";
import { InputGroup, Label, Input } from "../../forms";

const StyledDialog = styled(Dialog)`
  position: relative;
  max-width: 1000px !important;
  width: 80% !important;
  padding: 10px;
`;

const StyledCard = styled(Card)`
  margin: 5px;

  display: ${({ shouldNotDisplay }) => (shouldNotDisplay ? "none" : "block")};
`;

const PromoCodeInput = styled(Input)`
  max-width: 300px;
`;

class LicenceDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        usePreviousCard: true,
        cardHolderName: "",
        promoCode: "",
      },
      stripe: {
        cardNumber: false,
        cardExpiry: false,
        cardCvc: false,
      },
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isOpen && !nextProps.loading && !nextProps.creditCard.last4) {
      return {
        ...prevState,
        data: {
          ...prevState.data,
          usePreviousCard: false,
        },
      };
    }

    return prevState;
  }

  handleBillingModeChange = ({ target }) => {
    const { value } = target;
    const { billingModeChanged } = this.props;

    billingModeChanged(parseInt(value, 10));
  };

  handlePreviousCreditCard = ({ target }) => {
    this.setState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        usePreviousCard: target.checked,
      },
    }));
  };

  stripeElementChange = (element) => {
    this.setState((prevState) => ({
      ...prevState,
      stripe: {
        ...prevState.stripe,
        [element.elementType]: element.complete,
      },
    }));
  };

  getProrationTotalText = () => {
    const { proration, intl } = this.props;
    const cost = Math.abs(proration.cost);
    const message =
      proration.cost > 0
        ? messages.dialog.prorationCost
        : messages.dialog.prorationCredit;

    const hasProration = !Number.isNaN(cost) && cost !== 0;
    return hasProration ? intl.formatMessage(message, { cost }) : null;
  };

  getNextPaymentCycleText = () => {
    const { selectedBillingMode, licenceType, intl } = this.props;

    const message =
      selectedBillingMode === BillingMode.MONTHLY
        ? messages.dialog.monthlyBilling
        : messages.dialog.yearlyBilling;

    const cost =
      selectedBillingMode === BillingMode.MONTHLY
        ? licenceType.monthlyPriceWithTaxes
        : licenceType.yearlyPriceWithTaxes;

    return intl.formatMessage(message, { cost });
  };

  onChange = ({ target: { value, name } }) => {
    const inputName = name;
    const inputValue = value;

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [inputName]: inputValue,
      },
    }));
  };

  onClose = () => {
    const { closeDialog } = this.props;

    closeDialog();
  };

  submit = async () => {
    const {
      state: { data },
    } = this;

    this.setState((prevState) => ({ ...prevState, errors: {} }));

    const errors = this.validate();

    if (Object.keys(errors).length !== 0) {
      this.setState((prevState) => ({ ...prevState, errors }));
      return;
    }

    if (data.usePreviousCard) {
      this.updateLicenceWithPreviousCard();
    } else {
      await this.updateLicenceWithNewCard();
    }
  };

  updateLicenceWithPreviousCard = () => {
    const { data } = this.state;
    const { updateLicence, licenceType, selectedBillingMode } = this.props;

    updateLicence(licenceType.name, {
      creditCardToken: "",
      billingMode: selectedBillingMode,
      promoCode: data.promoCode,
    });
  };

  updateLicenceWithNewCard = async () => {
    const { data } = this.state;
    const { updateLicence, licenceType, selectedBillingMode } = this.props;
    const isValidCard = this.validateCreditCardToken();
    const creditCardToken = await this.generateToken();

    if (isValidCard && creditCardToken !== null) {
      updateLicence(licenceType.name, {
        creditCardToken,
        billingMode: selectedBillingMode,
        promoCode: data.promoCode,
      });
    }
  };

  generateToken = async () => {
    const {
      props: { stripe, showErrorMessage },
      state: { data },
    } = this;

    const { token, error } = await stripe.createToken({
      name: data.cardHolderName,
    });

    if (error && error.message) {
      showErrorMessage(error.message);
      return null;
    }
    return token.id;
  };

  onChange = ({ target: { value, name } }) => {
    const inputName = name;
    const inputValue = value;

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [inputName]: inputValue,
      },
    }));
  };

  validate = () => {
    const { selectedBillingMode, intl } = this.props;
    const errors = {};

    if (selectedBillingMode === null)
      errors.selectedBillingMode = intl.formatMessage(
        messages.errors.billingMethodNotSelected
      );

    return errors;
  };

  validateCreditCardToken = () => {
    const {
      data: { cardHolderName },
      stripe,
    } = this.state;
    const { intl } = this.props;
    const errors = {};

    if (!stripe.cardNumber)
      errors.cardNumber = intl.formatMessage(messages.errors.invalidCardNumber);
    if (!stripe.cardExpiry)
      errors.cardExpiry = intl.formatMessage(messages.errors.invalidCardExpiry);
    if (!stripe.cardCvc)
      errors.cardCvc = intl.formatMessage(messages.errors.invalidCardCVC);
    if (!cardHolderName)
      errors.cardHolderName = intl.formatMessage(
        messages.errors.fieldIsRequired
      );

    this.setState((prevState) => ({ ...prevState, errors }));

    return (
      stripe.cardNumber &&
      stripe.cardExpiry &&
      stripe.cardCvc &&
      cardHolderName !== ""
    );
  };

  render() {
    const {
      isOpen,
      loading,
      selectedBillingMode,
      intl,
      creditCard,
      hasLicence,
      licenceType
    } = this.props;
    const { data, errors } = this.state;


    const translatedName = intl.formatMessage(messages.types[licenceType.name]);

    return (
      <StyledDialog
        onClose={this.onClose}
        isOpen={isOpen}
        title={
          hasLicence
            ? intl.formatMessage(messages.dialog.updateCurrentLicence)
            : intl.formatMessage(messages.dialog.newLicence, {name: translatedName})
        }
        canOutsideClickClose={false}
      >
        {loading && <LoadingOverlay />}

        <StyledCard shouldNotDisplay={!creditCard.last4}>
          <Switch
            checked={data.usePreviousCard}
            label={intl.formatMessage(messages.dialog.usePreviousCreditCard, {
              lastFour: creditCard.last4,
              nameOnCard: creditCard.nameOnCard,
            })}
            onChange={this.handlePreviousCreditCard}
          />
        </StyledCard>
        <Collapse isOpen={!data.usePreviousCard} keepChildrenMounted>
          <StyledCard>
            <CreditCardForm
              onChange={this.onChange}
              stripeElementChange={this.stripeElementChange}
              errors={errors}
              data={data}
            />
          </StyledCard>
        </Collapse>

        <StyledCard>
          <InputGroup>
            <Label>
              <FormattedMessage {...messages.dialog.promoCode} />
            </Label>
            <PromoCodeInput
              name="promoCode"
              value={data.promoCode}
              onChange={this.onChange}
            />
          </InputGroup>
          <InputGroup error={errors.selectedBillingMode}>
            <RadioGroup
              inline
              onChange={this.handleBillingModeChange}
              selectedValue={selectedBillingMode}
            >
              <Radio
                label={intl.formatMessage(messages.dialog.monthlyPayment)}
                value={BillingMode.MONTHLY}
              />
              <Radio
                label={intl.formatMessage(messages.dialog.yearlyPayment)}
                value={BillingMode.YEARLY}
              />
            </RadioGroup>
          </InputGroup>
        </StyledCard>

        <StyledCard>
          <Flex justify="space-between" align="center" mobile>
            <Flex direction="column">
              <strong>{this.getProrationTotalText()}</strong>
              <strong>{this.getNextPaymentCycleText()}</strong>
            </Flex>
            <Button
              onClick={this.submit}
              intent={Intent.PRIMARY}
              className="default-button"
            >
              <FormattedMessage {...messages.general.save} />
            </Button>
          </Flex>
        </StyledCard>
      </StyledDialog>
    );
  }
}

LicenceDialog.defaultProps = {
  licenceType: {
    monthlyPrice: 0,
    yearlyPrice: 0,
    name: "soho"
  },
};

LicenceDialog.propTypes = {
  billingModeChanged: PropTypes.func.isRequired,
  licenceType: PropTypes.shape({
    monthlyPrice: PropTypes.number,
    yearlyPrice: PropTypes.number,
    name: PropTypes.string,
  }),
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  selectedBillingMode: PropTypes.number.isRequired,
  proration: PropTypes.shape({}).isRequired,
  intl: intlShape.isRequired,
  updateLicence: PropTypes.func.isRequired,
  hasLicence: PropTypes.bool.isRequired,
};

export default injectStripe(injectIntl(LicenceDialog));
