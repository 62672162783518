import validator from "validator";
import messages from "../../Projects.messages";
import { ItemType } from "../..";

const hasValidLength = (value, max) =>
  validator.isLength(value, { min: 0, max });

export const validateForm = (data, itemType, intl) => {
  const errors = {};

  if (!data.name)
    errors.name = intl.formatMessage(messages.errors.fieldIsRequired);

  if (!hasValidLength(data.name, 100))
    errors.name = intl.formatMessage(messages.errors.invalidCharacterCount, {
      max: 100
    });

  if (!data.description)
    errors.description = intl.formatMessage(messages.errors.fieldIsRequired);

  if (!hasValidLength(data.description, 300))
    errors.description = intl.formatMessage(
      messages.errors.invalidCharacterCount,
      {
        max: 300
      }
    );

  if (data.beginningDate >= data.deadlineDate) {
    errors.beginningDate = intl.formatMessage(
      messages.errors.beginningDateBiggerThanDeadlineDate
    );
    errors.deadlineDate = intl.formatMessage(
      messages.errors.deadlineDateSmallerThanBeginningDate
    );
  }

  if (data.priority < 1 || data.priority > 4)
    errors.priority = intl.formatMessage(messages.errors.invalidPriority);

  if (itemType !== ItemType.ACTION && !hasValidLength(data.measuringTool, 300))
    errors.measuringTool = intl.formatMessage(
      messages.errors.invalidCharacterCount,
      {
        max: 300
      }
    );

  if (itemType !== ItemType.ACTION && !hasValidLength(data.note, 300))
    errors.note = intl.formatMessage(messages.errors.invalidCharacterCount, {
      max: 300
    });

  if (
    itemType !== ItemType.ACTION &&
    data.benefits.some(benefit => !hasValidLength(benefit.name, 100))
  )
    errors.benefits = intl.formatMessage(
      messages.errors.invalidCharacterCount,
      {
        max: 100
      }
    );

  return errors;
};
