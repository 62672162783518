import React from "react";
import Validator from "validator";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Intent } from "@blueprintjs/core";
import messages from "./LoginForm.messages";
import LoginFormContainer from "./LoginFormContainer";
import LoginFormHeader from "./header/LoginFormHeader";
import LoginFormInputs from "./inputs/LoginFormInputs";
import { Button, ButtonSize } from "../../../components/buttons";
import AppToaster from "../../../components/toasters";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        email: "",
        password: ""
      },
      loading: false,
      errors: {}
    };
  }

  onChange = ({ target: { value, name } }) => {
    const inputName = name;
    const inputValue = value;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [inputName]: inputValue
      }
    }));
  };

  onSubmit = e => {
    e.preventDefault();
    const {
      state: { data },
      props: { submit }
    } = this;
    const errors = this.validate(data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      submit(data).catch(err => {
        this.showErrorMessage(err);
        this.setState(prevState => ({
          errors,
          loading: false,
          data: { ...prevState.data, password: "" }
        }));
      });
    }
  };

  validate = data => {
    const errors = {};
    if (!Validator.isEmail(data.email)) errors.email = "Invalid email";
    if (!data.password) errors.password = "Can't be blank";

    return errors;
  };

  showErrorMessage = err => {
    AppToaster.show({
      message: err,
      intent: Intent.DANGER
    });
  };

  render() {
    const { data, loading, errors } = this.state;
    return (
      <LoginFormContainer>
        <LoginFormHeader />
        <form onSubmit={this.onSubmit}>
          <LoginFormInputs
            data={data}
            loading={loading}
            errors={errors}
            onChange={this.onChange}
          />

          <Button type="submit" loading={loading} size={ButtonSize.FULL}>
            <FormattedMessage {...messages.login} />
          </Button>
        </form>
        <div>
          <Link to="/forgot-password">
            <FormattedMessage {...messages.forgotPassword} />
          </Link>
        </div>
        <div>
          <Link to="/register-company">
            <FormattedMessage {...messages.noAccount} />
          </Link>
        </div>
      </LoginFormContainer>
    );
  }
}

LoginForm.propTypes = {
  submit: PropTypes.func.isRequired
};

export default LoginForm;
