import styled from "styled-components";

const FormSectionContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.mobile_max_width}) {
    flex-direction: column;
  }
`;

export default FormSectionContainer;
