import { defineMessages } from "react-intl";

const messages = {
  fields: {
    task: { id: "Form.fields.task" },
    manager: { id: "Form.fields.manager" },
    deadlineDate: { id: "Form.fields.deadlineDate" },
    priority: { id: "Form.fields.priority" },
    progress: { id: "Form.fields.percentage" }
  },
  general: {
    tasks: { id: "Task.general.tasks" },
    myTasks: { id: "Task.general.myTasks" },
    noAssignedTask: { id: "Task.general.noAssignedTask" },
    userTasks: { id: "Task.general.userTasks" }
  }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
