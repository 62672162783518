import { defineMessages } from "react-intl";

const messages = {
  general: {
    title: { id: "Comments.title" },
    send: { id: "General.send" }
  },
  fields: {
    name: { id: "Form.fields.name" },
    description: { id: "Form.fields.description" },
    benefits: { id: "Form.fields.benefits" },
    measuringTools: { id: "Form.fields.measuringTools" },
    manager: { id: "Form.fields.manager" },
    beginningDate: { id: "Form.fields.beginningDate" },
    deadlineDate: { id: "Form.fields.deadlineDate" },
    notes: { id: "Form.fields.notes" }
  }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
