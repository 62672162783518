import styled from "styled-components";

const Container = styled.div`
  background: ${props => props.theme.white};
  border-radius: 20px;
  padding: 20px;

  text-align: center;
  box-shadow: 8px 8px 58px 0 rgba(0, 0, 0, 0.5);
`;

const Sections = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => theme.mobile_max_width}) {
    flex-direction: column;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export { Container, Section, Sections };
