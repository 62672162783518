import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import WhiteLogo from "../../../images/logos/genba-monogram-blanc.svg";
import ColorLogo from "../../../images/logos/genba-monogram-couleur.svg";

const LogoContainer = styled(Link)`
  display: flex;

  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`;

const LogoText = styled.h1`
  color: white;
  margin-left: 10px;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
`;

const HeaderLogo = ({ withColor, showName }) => {
  const logo = withColor ? ColorLogo : WhiteLogo;

  return (
    <LogoContainer to="/">
      <Logo src={logo} alt="Top Logo" />
      {showName && <LogoText>Genba</LogoText>}
    </LogoContainer>
  );
};

HeaderLogo.defaultProps = {
  withColor: false,
  showName: true
};

HeaderLogo.propTypes = {
  withColor: PropTypes.bool,
  showName: PropTypes.bool
};

export default HeaderLogo;
