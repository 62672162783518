import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Validator from "validator";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Intent, Checkbox } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import { Button, ButtonSize } from "../../../components/buttons";
import messages from "./RegisterForm.messages";
import RegisterFormContainer from "./RegisterFormContainer";
import {
  InputGroup,
  Input,
  BorderStyle,
  InputStyle
} from "../../../components/forms";
import RegisterFormHeader from "./header/RegisterFormHeader";
import InputContainer from "./inputContainer/InputContainer";
import AppToaster from "../../../components/toasters";

class RegisterForm extends Component {
  constructor(props) {
    super(props);

    const { invitationToken } = this.props;

    this.state = {
      data: {
        email: "",
        firstname: "",
        lastname: "",
        password: "",
        confirmPassword: "",
        token: invitationToken || "",
        termsAccepted: false
      },
      loading: false,
      errors: {}
    };
  }

  onChange = ({ target: { value, name } }) => {
    const inputName = name;
    const inputValue = value;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [inputName]: inputValue
      }
    }));
  };

  handleTermsChange = event => {
    const termsAccepted = event.target.checked;
    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        termsAccepted
      }
    }));
  };

  onSubmit = e => {
    e.preventDefault();
    const {
      state: { data },
      props: { submit }
    } = this;
    const errors = this.validate(data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      submit(data).catch(err => {
        this.setState({ errors, loading: false });
        this.showErrorMessage(err);
      });
    }
  };

  validate = data => {
    const { intl } = this.props;
    const errors = {};

    if (!data.termsAccepted)
      errors.termsAccepted = intl.formatMessage(
        messages.errors.termsAndConditions
      );
    if (!Validator.isEmail(data.email)) {
      errors.email = intl.formatMessage(messages.errors.invalidEmail);
    }

    if (Validator.isEmpty(data.firstname)) {
      errors.firstname = intl.formatMessage(messages.errors.fieldIsRequired);
    }

    if (Validator.isEmpty(data.lastname)) {
      errors.lastname = intl.formatMessage(messages.errors.fieldIsRequired);
    }

    if (!Validator.isLength(data.password, { min: 6 })) {
      errors.password = intl.formatMessage(messages.errors.fieldIsTooShort, {
        min: 6
      });
    }

    if (Validator.isEmpty(data.confirmPassword)) {
      errors.confirmPassword = intl.formatMessage(
        messages.errors.fieldIsRequired
      );
    }

    if (!Validator.equals(data.password, data.confirmPassword)) {
      errors.confirmPassword = intl.formatMessage(
        messages.errors.confirmPassword
      );
    }

    if (Validator.isEmpty(data.token)) {
      errors.token = intl.formatMessage(messages.errors.fieldIsRequired);
    }

    return errors;
  };

  showErrorMessage = err => {
    AppToaster.show({
      message: err,
      intent: Intent.DANGER
    });
  };

  render() {
    const {
      state: {
        data: {
          email,
          firstname,
          lastname,
          password,
          confirmPassword,
          token,
          termsAccepted
        },
        loading,
        errors
      },
      props: { intl, lang }
    } = this;
    return (
      <RegisterFormContainer>
        <RegisterFormHeader />
        <form onSubmit={this.onSubmit}>
          <InputContainer>
            <InputGroup error={errors.email}>
              <Input
                name="email"
                value={email}
                onChange={this.onChange}
                disabled={loading}
                placeholder={intl.formatMessage(messages.fields.email)}
                borderStyle={BorderStyle.ROUND}
                inputStyle={InputStyle.COLORED}
              />
            </InputGroup>

            <InputGroup error={errors.firstname}>
              <Input
                name="firstname"
                value={firstname}
                onChange={this.onChange}
                disabled={loading}
                placeholder={intl.formatMessage(messages.fields.firstname)}
                borderStyle={BorderStyle.ROUND}
                inputStyle={InputStyle.COLORED}
              />
            </InputGroup>

            <InputGroup error={errors.lastname}>
              <Input
                name="lastname"
                value={lastname}
                onChange={this.onChange}
                disabled={loading}
                placeholder={intl.formatMessage(messages.fields.lastname)}
                borderStyle={BorderStyle.ROUND}
                inputStyle={InputStyle.COLORED}
              />
            </InputGroup>

            <InputGroup error={errors.password}>
              <Input
                name="password"
                value={password}
                onChange={this.onChange}
                disabled={loading}
                placeholder={intl.formatMessage(messages.fields.password)}
                borderStyle={BorderStyle.ROUND}
                inputStyle={InputStyle.COLORED}
                type="password"
              />
            </InputGroup>

            <InputGroup error={errors.confirmPassword}>
              <Input
                name="confirmPassword"
                value={confirmPassword}
                onChange={this.onChange}
                disabled={loading}
                placeholder={intl.formatMessage(
                  messages.fields.confirmPassword
                )}
                type="password"
                borderStyle={BorderStyle.ROUND}
                inputStyle={InputStyle.COLORED}
              />
            </InputGroup>

            <InputGroup error={errors.token}>
              <Input
                name="token"
                value={token}
                onChange={this.onChange}
                placeholder={intl.formatMessage(messages.fields.companyID)}
                borderStyle={BorderStyle.ROUND}
                inputStyle={InputStyle.COLORED}
                disabled
              />
            </InputGroup>

            <InputGroup error={errors.termsAccepted}>
              <Checkbox
                checked={termsAccepted}
                onChange={this.handleTermsChange}
              >
                <a
                  href={`${process.env.REACT_APP_API_URL}/${lang}/help?section=terms`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage {...messages.fields.termsAndConditions} />
                </a>
              </Checkbox>
            </InputGroup>
          </InputContainer>
          <Button type="submit" loading={loading} size={ButtonSize.FULL}>
            <FormattedMessage {...messages.register} />
          </Button>

          <Link to="/login">
            <FormattedMessage {...messages.alreadyHaveAnAccount} />
          </Link>
        </form>
      </RegisterFormContainer>
    );
  }
}

RegisterForm.propTypes = {
  submit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  lang: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  lang: state.locales.lang
});

export default connect(mapStateToProps)(injectIntl(RegisterForm));
