import React from "react";
import styled from "styled-components";
import { Spinner } from "@blueprintjs/core";

const OverlayContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;

  opacity: 0.6;
  z-index: 100;
`;

const LoadingOverlay = () => (
  <OverlayContainer>
    <Spinner />
  </OverlayContainer>
);

export default LoadingOverlay;
