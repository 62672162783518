export const commentComponentFromProjects = (projects, commentResponse) => {
  let updatedProject;
  const projectIndex = projects.findIndex(
    (x) => x.id === commentResponse.itemParents.projectId
  );

  if (projectIndex >= 0 && commentResponse.itemParents.goalId == null) {
    updatedProject = commentComponent(projects[projectIndex]);
  } else {
    updatedProject = {
      ...projects[projectIndex],
      goals: commentGoals(projects[projectIndex].goals, commentResponse),
    };
  }

  return [
    ...projects.slice(0, projectIndex),
    updatedProject,
    ...projects.slice(projectIndex + 1),
  ];
};

const commentGoals = (goals, commentResponse) => {
  let updatedGoal = null;
  const goalId =
    commentResponse.itemParents.goalId === 0
      ? commentResponse.itemId
      : commentResponse.itemParents.goalId;
  const index = goals.findIndex((x) => x.id === goalId);

  if (commentResponse.itemParents.goalId === 0) {
    updatedGoal = commentComponent(goals[index]);
  } else {
    updatedGoal = {
      ...goals[index],
      strategies: commentStrategies(goals[index].strategies, commentResponse),
    };
  }
  return [...goals.slice(0, index), updatedGoal, ...goals.slice(index + 1)];
};

const commentStrategies = (strategies, commentResponse) => {
  let updatedStrategy = null;
  const strategyId =
    commentResponse.itemParents.strategyId === 0
      ? commentResponse.itemId
      : commentResponse.itemParents.strategyId;
  const index = strategies.findIndex((x) => x.id === strategyId);

  if (commentResponse.itemParents.strategyId === 0) {
    updatedStrategy = commentComponent(strategies[index]);
  } else {
    updatedStrategy = {
      ...strategies[index],
      actions: commentActions(strategies[index].actions, commentResponse),
    };
  }

  return [
    ...strategies.slice(0, index),
    updatedStrategy,
    ...strategies.slice(index + 1),
  ];
};

const commentActions = (actions, commentResponse) => {
  const actionId = commentResponse.itemId;
  const index = actions.findIndex((x) => x.id === actionId);

  return [
    ...actions.slice(0, index),
    commentComponent(actions[index]),
    ...actions.slice(index + 1),
  ];
};

const commentComponent = (component) => ({ ...component, hasComments: true });
