import React from "react";
import PropTypes from "prop-types";
import { Collapse } from "@blueprintjs/core";
import { SortableElement } from "react-sortable-hoc";
import styled from "styled-components";
import ItemHeader from "../itemHeader/ItemHeader";
import { ItemType } from "../../..";
import { isAdmin, isManagement } from "../../../../roles";

const StyledItemContainer = styled.div`
  margin: 15px 0px;
  margin-left: ${({ itemType }) => {
    if (itemType === ItemType.OBJECTIVE) return "0px";
    if (itemType === ItemType.STRATEGY) return "40px";
    if (itemType === ItemType.ACTION) return "80px";
    throw new Error("Unsupported item type");
  }};

  @media (max-width: ${({ theme }) => theme.mobile_max_width}) {
    margin-left: 0px;
    margin-bottom: ${({ isOpen }) => (isOpen ? "30px" : "0px")};
  }
`;

class ItemContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    const { item, itemType, queryValues } = this.props;
    let currentId = 0;

    if (itemType === ItemType.OBJECTIVE) {
      currentId = queryValues.goal;
    } else if (itemType === ItemType.STRATEGY) {
      currentId = queryValues.strategy;
    } else {
      currentId = queryValues.action;
    }

    if (item.id.toString() === currentId) {
      this.setState({
        isOpen: item.id.toString() === currentId,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { item, itemType } = this.props;

    if (
      (itemType === ItemType.OBJECTIVE &&
        item.strategies.length > prevProps.item.strategies.length) ||
      (itemType === ItemType.STRATEGY &&
        item.actions.length > prevProps.item.actions.length)
    ) {
      this.setState({ isOpen: true });
    }
  }

  getPermissions = () => {
    const { currentUser } = this.props;
    const hasFullAccess = isAdmin(currentUser) || isManagement(currentUser);
    return {
      canEdit: hasFullAccess || this.canEdit(),
      canAssociate: hasFullAccess || this.canAssociate(),
      canAdd: hasFullAccess || this.canAdd(),
      canRemove: hasFullAccess || this.canRemove(),
      canComment: true,
      canAttachment: true,
      canCheckItem: true,
    };
  };

  canAdd = () =>
    this.isProjectManager() || this.isItemManager() || this.isParentManager();

  canRemove = () => this.isProjectManager();

  canAssociate = () =>
    this.isProjectManager() || this.isItemManager() || this.isParentManager();

  canEdit = () => this.isParentManager() || this.isProjectManager();

  canAddOrRemove = () => {
    const { currentUser, project } = this.props;

    if (isAdmin() || isManagement()) {
      return true;
    }

    return project && project.manager && currentUser.id === project.manager.id;
  };

  isItemManager = () => {
    const { currentUser, item } = this.props;

    return currentUser.id === item.manager.id;
  };

  isProjectManager = () => {
    const { currentUser, project } = this.props;

    return project && project.manager && currentUser.id === project.manager.id;
  };

  isParentManager = () => {
    const { isParentManager } = this.props;

    return isParentManager;
  };

  onOpenClick = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    const {
      state: { isOpen },
      props: {
        children,
        item,
        itemType,
        userActions,
        goalId,
        strategyId,
        hasChildren,
      },
    } = this;
    return (
      <StyledItemContainer itemType={itemType} isOpen={isOpen}>
        <ItemHeader
          item={item}
          isOpen={!hasChildren || isOpen}
          onOpenClick={this.onOpenClick}
          itemType={itemType}
          itemStatus={item.status}
          userActions={userActions}
          goalId={goalId}
          strategyId={strategyId}
          canEdit={this.canEdit()}
          canAddOrRemove={this.canAddOrRemove()}
          permissions={this.getPermissions()}
        />
        <Collapse isOpen={isOpen} keepChildrenMounted transitionDuration={0}>
          {children}
        </Collapse>
      </StyledItemContainer>
    );
  }
}

const SortableItemContainer = SortableElement((props) => (
  <ItemContainer {...props} />
));

ItemContainer.defaultProps = {
  children: null,
  goalId: 0,
  strategyId: 0,
};

ItemContainer.propTypes = {
  children: PropTypes.node,
  item: PropTypes.shape().isRequired,
  itemType: PropTypes.string.isRequired,
  goalId: PropTypes.number,
  strategyId: PropTypes.number,
  hasChildren: PropTypes.bool.isRequired,
  queryValues: PropTypes.shape().isRequired,
  userActions: PropTypes.shape({}).isRequired,
};

export default SortableItemContainer;
