import { defineMessages } from "react-intl";

const messages = {
  general: {
    save: {
      id: "General.save",
    },
    saveSuccess: {
      id: "Messages.informationSaved.success",
    },
  },
  fields: {
    firstname: {
      id: "Form.fields.firstname",
    },
    lastname: {
      id: "Form.fields.lastname",
    },
    email: {
      id: "Form.fields.email",
    },
    phoneNumber: {
      id: "Form.fields.phoneNumber",
    },
    position: {
      id: "Form.fields.position",
    },
    oldPassword: {
      id: "Form.fields.oldPassword",
    },
    newPassword: {
      id: "Form.fields.newPassword",
    },
    newPasswordConfirmation: {
      id: "Form.fields.newPasswordConfirmation",
    },
    unsubscribe: { id: "Form.fields.unsubscribe" },
  },
  errors: {
    invalidEmail: {
      id: "Form.errors.invalidEmail",
    },
    invalidPhoneNumber: {
      id: "Form.errors.invalidPhoneNumber",
    },
    fieldIsRequired: {
      id: "Form.errors.fieldIsRequired",
    },
    confirmPassword: {
      id: "Form.errors.confirmPassword",
    },
    invalidImageType: {
      id: "Form.errors.invalidImageType",
    },
    fieldIsTooShort: {
      id: "Form.errors.fieldIsTooShort",
    },
  },
  profile: {
    myProfile: {
      id: "Profile.myProfile",
    },
    generalInformation: {
      id: "Profile.generalInformations",
    },
    additionalInformation: {
      id: "Profile.additionalInformation",
    },
    additionalActions: {
      id: "Profile.additionalActions",
    },
    updatePassword: {
      id: "Profile.updatePassword",
    },
    updateAvatar: {
      id: "Profile.updateAvatar",
    },
    selectYourAvatar: {
      id: "Profile.selectYourAvatar",
    },
    avatarSizeRecommendation: {
      id: "Profile.avatarSizeRecommendation",
    },
  },
};

const definedMessages = defineMessages(messages);

export default definedMessages;
