import styled from "styled-components";

const ItemHeaderContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 45px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

export default ItemHeaderContainer;
