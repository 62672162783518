import styled from "styled-components";
import { Dialog } from "@blueprintjs/core";

export const StyledDialog = styled(Dialog)`
  position: relative;
  max-width: 1000px !important;
  width: 80% !important;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 80vh !important;
  padding: 20px 20px;
`;

export const StyledCommentSection = styled.div`
  background: ${({ theme }) => theme.gray_very_light};
  border-radius: 10px;
  box-shadow: 8px 8px 58px 0 rgba(0, 0, 0, 0.2);
  min-height: 200px;
  overflow-y: scroll;
  margin-bottom: 20px;
`;

export const InputSection = styled.form`
  display: flex;
`;

export const TextInput = styled.textarea`
  flex-grow: 1;
  margin-right: 5px;
  resize: none;
`;

export const StyledComment = styled.div`
  margin: 0px 20px;
  padding: 20px 0px;
  min-height: 50px;
  display: flex;
  align-items: center;

  &:not(:first-child) {
    border-top: ${({ theme }) => `1px solid ${theme.gray_light}`};
  }
`;

export const CommentDisplaySection = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const CommentInfos = styled.div`
  display: flex;
`;

export const CommentInfo = styled.p`
  font-weight: bold;
  font-size: 0.85rem;
  padding: 0px 5px 0px 0px;
`;

export const CommentText = styled.p``;
