import {
  FETCH_LOGS_BEGIN,
  FETCH_LOGS_SUCCESS,
  FETCH_LOGS_FAILURE,
} from "./types";
import api from "../api";

export const fetchLogsBegin = () => ({
  type: FETCH_LOGS_BEGIN,
});

export const fetchLogsSuccess = (logs) => ({
  type: FETCH_LOGS_SUCCESS,
  payload: logs,
});

export const fetchLogsFailure = (error) => ({
  type: FETCH_LOGS_FAILURE,
  payload: error,
});

export const fetchLogs = (paging, sort, sortOrder, filter) => (dispatch) => {
  dispatch(fetchLogsBegin());

  return api.logs
    .get(paging.pageIndex, paging.pageSize, sort, sortOrder, filter)
    .then((res) => {
      dispatch(fetchLogsSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      dispatch(fetchLogsFailure(error));
    });
};
