import React from "react";
import { Dialog } from "@blueprintjs/core";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import validator from "validator";
import FormContainer from "../forms/formContainer/FormContainer";
import { Button, ButtonSize } from "../buttons";
import {
  FormSectionContainer,
  FormSection,
  ButtonSection
} from "../forms/formSection";
import { InputGroup, Input, Label, DateInput, TextArea } from "../forms";
import { DisplayStyle } from "../forms/styles/Styles";
import { UserSelect } from "../users";
import messages from "./Projects.messages";
import LoadingOverlay from "../overlays/LoadingOverlay";

const StyledDialog = styled(Dialog)`
  position: relative;
  max-width: 1000px !important;
  width: 80% !important;
`;

class ProjectDialog extends React.Component {
  constructor(props) {
    super(props);

    const { users, project } = this.props;

    this.state = {
      errors: {},
      data: {
        id: project.id,
        name: project.name,
        description: project.description,
        isArchived: project.isArchived,
        beginningDate: new Date(project.beginningDate),
        deadlineDate: new Date(project.deadlineDate),
        manager: {
          id: project.manager.id === 0 ? users[0].id : project.manager.id
        }
      }
    };
  }

  dateChanged = (selectedDate, fieldName) => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [fieldName]: selectedDate
      }
    }));
  };

  managerSelected = user => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        manager: {
          id: user.id
        }
      }
    }));
  };

  onChange = ({ target: { value, name } }) => {
    const inputName = name;
    const inputValue = value;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [inputName]: inputValue
      }
    }));
  };

  onSubmit = event => {
    event.preventDefault();
    const {
      props: { submit },
      state: { data }
    } = this;

    const errors = this.validate(data);

    if (Object.keys(errors).length === 0) {
      submit(data);
    }

    this.setState({ errors });
  };

  validate = data => {
    const { intl } = this.props;
    const errors = {};

    if (!data.name) {
      errors.name = intl.formatMessage(messages.errors.fieldIsRequired);
    }
    if (!this.hasValidLength(data.name, 100)) {
      errors.name = this.getLengthError(100);
    }
    if (data.beginningDate >= data.deadlineDate) {
      errors.beginningDate = intl.formatMessage(
        messages.errors.beginningDateBiggerThanDeadlineDate
      );
      errors.deadlineDate = intl.formatMessage(
        messages.errors.deadlineDateSmallerThanBeginningDate
      );
    }

    return errors;
  };

  hasValidLength = (value, max) => validator.isLength(value, { min: 0, max });

  getLengthError = max => {
    const { intl } = this.props;

    return intl.formatMessage(messages.errors.invalidCharacterCount, { max });
  };

  render() {
    const {
      props: { isOpen, onClose, users, loading, intl },
      state: {
        errors,
        data: {
          id,
          name,
          description,
          manager,
          beginningDate,
          deadlineDate,
          isArchived
        }
      }
    } = this;

    return (
      <StyledDialog
        isOpen={isOpen}
        onClose={onClose}
        title={intl.formatMessage(messages.general.title)}
        canOutsideClickClose={false}
      >
        {loading && <LoadingOverlay />}
        <FormContainer onSubmit={this.onSubmit}>
          <FormSectionContainer>
            <FormSection
              title={intl.formatMessage(messages.general.generalInformations)}
            >
              <InputGroup error={errors.name}>
                <Label>
                  <FormattedMessage {...messages.fields.name} />
                </Label>
                <Input name="name" value={name} onChange={this.onChange} />
              </InputGroup>

              <InputGroup error={errors.description}>
                <Label>
                  <FormattedMessage {...messages.fields.description} />
                </Label>
                <TextArea
                  name="description"
                  value={description}
                  onChange={this.onChange}
                />
              </InputGroup>

              <InputGroup>
                <Label>
                  <FormattedMessage {...messages.fields.manager} />
                </Label>
                <UserSelect
                  users={users}
                  activeUser={users[users.findIndex(x => x.id === manager.id)]}
                  onUserSelected={this.managerSelected}
                />
              </InputGroup>

              <InputGroup error={errors.beginningDate}>
                <Label>
                  <FormattedMessage {...messages.fields.beginningDate} />
                </Label>
                <DateInput
                  onChange={selectedDate =>
                    this.dateChanged(selectedDate, "beginningDate")
                  }
                  value={beginningDate}
                />
              </InputGroup>

              <InputGroup error={errors.deadlineDate}>
                <Label>
                  <FormattedMessage {...messages.fields.deadlineDate} />
                </Label>
                <DateInput
                  onChange={selectedDate =>
                    this.dateChanged(selectedDate, "deadlineDate")
                  }
                  value={deadlineDate}
                />
              </InputGroup>

              <InputGroup
                displayStyle={
                  id !== 0 ? DisplayStyle.INLINE : DisplayStyle.NONE
                }
              >
                <Label>
                  <FormattedMessage {...messages.fields.archived} />
                </Label>
                <Input
                  name="isArchived"
                  checked={isArchived}
                  value={`${isArchived}`}
                  onChange={e => {
                    this.onChange({
                      target: {
                        name: e.target.name,
                        value: e.target.checked
                      }
                    });
                  }}
                  type="checkbox"
                />
              </InputGroup>
            </FormSection>
          </FormSectionContainer>
          <ButtonSection>
            <Button size={ButtonSize.MEDIUM} type="submit" disabled={loading}>
              <FormattedMessage {...messages.general.save} />
            </Button>
          </ButtonSection>
        </FormContainer>
      </StyledDialog>
    );
  }
}

ProjectDialog.defaultProps = {
  project: {
    id: 0,
    name: "",
    description: "",
    isArchived: false,
    beginningDate: new Date().toString(),
    deadlineDate: new Date().toString(),
    manager: {
      id: 0
    }
  }
};

ProjectDialog.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    isArchived: PropTypes.bool,
    beginningDate: PropTypes.string,
    deadlineDate: PropTypes.string,
    manager: PropTypes.shape({})
  }),
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(ProjectDialog);
