import React from "react";
import { Collapse } from "@blueprintjs/core";
import { FormattedMessage } from "react-intl";

import { SectionContainer, SectionHeader, Toggle } from "./Styles";

const CollapseContainer = ({ isOpen, toggleCollapse, message, children }) => (
  <SectionContainer>
    <SectionHeader onClick={() => toggleCollapse("disableBillingOpened")}>
      <Toggle opened={isOpen} />
      <FormattedMessage {...message} />
    </SectionHeader>
    <Collapse isOpen={isOpen}>{children}</Collapse>
  </SectionContainer>
);

export default CollapseContainer;
