import React from "react";
import {
  Popover,
  H5,
  Button,
  Intent,
  Classes,
  Position
} from "@blueprintjs/core";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import messages from "./DeletePopover.messages";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
`;

const StyledPopover = styled(Popover)`
  & .bp3-heading {
    color: ${({ theme }) => theme.blue_regular} !important;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 5px;
  border-radius: 35px !important;
  box-shadow: none !important;
  padding: 10px 20px !important;
  background-image: none !important;
  font-weight: 700;

  &:not(.bp3-intent-danger) {
    background-color: #e8e8e8 !important;
  }
`;

const DeletePopover = ({ children, onDelete }) => (
  <StyledPopover
    popoverClassName="bp3-popover-content-sizing"
    position={Position.TOP_RIGHT}
    usePortal={false}
  >
    {children}
    <div>
      <H5>
        <FormattedMessage {...messages.title} />
      </H5>

      <p>
        <FormattedMessage {...messages.text} />
      </p>

      <ButtonContainer>
        <StyledButton className={Classes.POPOVER_DISMISS}>
          <FormattedMessage {...messages.cancel} />
        </StyledButton>

        <StyledButton
          intent={Intent.DANGER}
          className={Classes.POPOVER_DISMISS}
          onClick={onDelete}
        >
          <FormattedMessage {...messages.delete} />
        </StyledButton>
      </ButtonContainer>
    </div>
  </StyledPopover>
);

DeletePopover.propTypes = {
  children: PropTypes.node.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default DeletePopover;
