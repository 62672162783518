export const checkItemComponentFromProjects = (
  projects,
  checkItemResponse,
  hasItem
) => {
  const projectIndex = projects.findIndex(
    (x) => x.id === checkItemResponse.itemParents.projectId
  );

  const updatedProject = {
    ...projects[projectIndex],
    goals: checkItemGoals(
      projects[projectIndex].goals,
      checkItemResponse,
      hasItem
    ),
  };

  return [
    ...projects.slice(0, projectIndex),
    updatedProject,
    ...projects.slice(projectIndex + 1),
  ];
};

const checkItemGoals = (goals, checkItemResponse, hasItem) => {
  let updatedGoal = null;
  const goalId =
    checkItemResponse.itemParents.goalId === 0
      ? checkItemResponse.itemId
      : checkItemResponse.itemParents.goalId;
  const index = goals.findIndex((x) => x.id === goalId);

  if (checkItemResponse.itemParents.goalId === 0) {
    updatedGoal = checkItemComponent(
      goals[index],
      checkItemResponse.isAllItemChecked,
      hasItem
    );
  } else {
    updatedGoal = {
      ...goals[index],
      strategies: checkItemStrategies(
        goals[index].strategies,
        checkItemResponse,
        hasItem
      ),
    };
  }

  return [...goals.slice(0, index), updatedGoal, ...goals.slice(index + 1)];
};

const checkItemStrategies = (strategies, checkItemResponse, hasItem) => {
  let updatedStrategy = null;
  const strategyId =
    checkItemResponse.itemParents.strategyId === 0
      ? checkItemResponse.itemId
      : checkItemResponse.itemParents.strategyId;
  const index = strategies.findIndex((x) => x.id === strategyId);

  if (checkItemResponse.itemParents.strategyId === 0) {
    updatedStrategy = checkItemComponent(
      strategies[index],
      checkItemResponse.isAllItemChecked,
      hasItem
    );
  } else {
    updatedStrategy = {
      ...strategies[index],
      actions: checkItemActions(
        strategies[index].actions,
        checkItemResponse,
        hasItem
      ),
    };
  }

  return [
    ...strategies.slice(0, index),
    updatedStrategy,
    ...strategies.slice(index + 1),
  ];
};

const checkItemActions = (actions, checkItemResponse, hasItem) => {
  const actionId = checkItemResponse.itemId;
  const index = actions.findIndex((x) => x.id === actionId);

  return [
    ...actions.slice(0, index),
    checkItemComponent(
      actions[index],
      checkItemResponse.isAllItemChecked,
      hasItem
    ),
    ...actions.slice(index + 1),
  ];
};
const checkItemComponent = (component, allItemChecked, hasItem) => ({
  ...component,
  hasCheckItems: hasItem,
  isAllItemChecked: allItemChecked,
});
