import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import SubNavItem from "./SubNavItem";
import HasRole from "../../../roles/HasRole";

const StyledNavItem = styled.li`
  font-size: 1.5em;
  font-weight: bold;

  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const SubNavItemsContainer = styled.ul`
  list-style: none;
  margin-top: 10px;
  padding: 0;
`;

const renderSubNavItems = (subNavItems, intl) =>
  subNavItems.map(subNavItem => (
    <SubNavItem subNavItem={subNavItem} intl={intl} key={subNavItem.name.id} />
  ));

const NavItem = ({ navItem, intl }) => (
  <HasRole minRole={navItem.minRole}>
    <StyledNavItem>
      {intl.formatMessage(navItem.name)}
      <SubNavItemsContainer>
        {renderSubNavItems(navItem.subNavItems, intl)}
      </SubNavItemsContainer>
    </StyledNavItem>
  </HasRole>
);

NavItem.propTypes = {
  navItem: PropTypes.shape().isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(NavItem);
