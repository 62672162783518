import { defineMessages } from "react-intl";

const messages = {
  register: {
    id: "RegisterForm.register",
    defaultMessage: "Register",
    description: "Register button text"
  },
  alreadyHaveAnAccount: {
    id: "RegisterForm.alreadyHaveAnAccount",
    description:
      "Text for the already have an account under the register button"
  },
  fields: {
    email: {
      id: "Form.fields.email",
      description: "Email field name"
    },
    firstname: {
      id: "Form.fields.firstname",
      description: "Firstname field name"
    },
    lastname: {
      id: "Form.fields.lastname",
      description: "Lastname field name"
    },
    password: {
      id: "Form.fields.password",
      description: "Password field name"
    },
    confirmPassword: {
      id: "Form.fields.confirmPassword",
      description: "Confirm password field name"
    },
    companyID: {
      id: "Form.fields.companyID",
      description: "Company ID field name"
    },
    termsAndConditions: { id: "Form.fields.termsAndConditions" }
  },
  errors: {
    invalidEmail: {
      id: "Form.errors.invalidEmail",
      description: "Invalid email error"
    },
    confirmPassword: {
      id: "Form.errors.confirmPassword",
      description: "Confirm password is not matching password"
    },
    fieldIsRequired: {
      id: "Form.errors.fieldIsRequired",
      description: "Field is required"
    },
    termsAndConditions: { id: "Form.errors.termsAndConditions" },
    fieldIsTooShort: { id: "Form.errors.fieldIsTooShort" }
  }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
