import { defineMessages } from "react-intl";

const messages = {
  alerts: {
    inactiveLicence: { id: "Alerts.licence.inactiveLicence" },
    inactiveLicenceAdmin: { id: "Alerts.licence.inactiveLicenceAdmin" },
    goToLicencePage: { id: "Alerts.licence.goToLicencePage" }
  }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
