import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import queryString from 'query-string';
import * as actions from "../../../../actions/auth";
import RegisterCompanyForm from "../../../forms/registerCompany";
import PageLayout from "../PageLayout";

class RegisterCompanyPage extends React.Component {
  submit = data => {
    const {
      props: { registerCompany, history, location }
    } = this;

    const params = queryString.parse(location.search);

    let redirect = null;

    if (params.page) {
      redirect = () => {history.push({
        pathname: params.page,
        search: `?licence=${params.licence}`
      })};
    } else {
      redirect = () => history.push("/project-summary");
    }

    return registerCompany(data).then(() => redirect());
  };

  render() {
    return (
      <PageLayout>
        <RegisterCompanyForm submit={this.submit} />
      </PageLayout>
    );
  }
}

RegisterCompanyPage.propTypes = {
  registerCompany: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({}).isRequired,
};

export default connect(
  null,
  { registerCompany: actions.registerCompany }
)(RegisterCompanyPage);
