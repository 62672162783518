import React from "react";
import styled from "styled-components";
import visa from "payment-icons/min/flat/visa.svg";
import mastercard from "payment-icons/min/flat/mastercard.svg";
import amex from "payment-icons/min/flat/amex.svg";
import { CreditCard } from "./CreditCard";

const Container = styled.div`
  margin-left: 5px;
  margin-bottom: 10px;
`;

const CreditCardIconSet = () => (
  <Container>
    <CreditCard src={visa} alt="Credit Card" />
    <CreditCard src={mastercard} alt="Credit Card" />
    <CreditCard src={amex} alt="Credit Card" />
  </Container>
);

export default CreditCardIconSet;
