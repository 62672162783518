import React from "react";
import PropTypes from "prop-types";
import { Intent, Button } from "@blueprintjs/core";
import {
  StyledCheckBox,
  StyledLabel,
  StyledTextbox,
  CheckItemDivFlex,
} from "./Styles";
import { FocusStyleManager } from "@blueprintjs/core";

FocusStyleManager.onlyShowFocusOnTabs();
export class CheckItemEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: "",
      check: false,
      editModeOn: false,
    };
  }

  componentDidMount() {
    const { text, check } = this.props;
    this.setState((prev) => {
      return { text, check, editModeOn: prev.editModeOn };
    });
  }

  submit(newText, id, check, editModeOn, checkChange) {
    this.setState(() => ({
      check,
      text: newText,
      editModeOn,
    }));

    checkChange(id, check, newText);
  }

  saveChange(newText, check, editModeOn) {
    this.setState(() => ({
      check,
      text: newText,
      editModeOn,
    }));
  }

  renderAdminCheck() {
    const { id, checkChange, checkRemove } = this.props;
    const { text, check, editModeOn } = this.state;

    if (editModeOn) {
      return (
        <React.Fragment>
          <StyledTextbox
            name="name"
            value={text}
            onChange={(e) => {
              this.saveChange(e.target.value, check, editModeOn);
            }}
          />

          <Button
            intent={Intent.SUCCESS}
            type="submit"
            icon="tick"
            small
            minimal
            onClick={() => {
              this.submit(text, id, check, false, checkChange);
            }}
            disabled={!text || !text.trim()}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <StyledLabel>{text}</StyledLabel>

        <Button
          intent={Intent.PRIMARY}
          type="submit"
          icon="edit"
          minimal
          small
          onClick={() => {
            this.setState(() => ({
              check,
              text,
              editModeOn: true,
            }));
          }}
        />

        <Button
          intent={Intent.DANGER}
          type="submit"
          icon="trash"
          small
          minimal
          onClick={() => {
            checkRemove(id);
          }}
        />
      </React.Fragment>
    );
  }

  renderReadOnlyCheck() {
    const { text } = this.state;
    return <StyledLabel>{text}</StyledLabel>;
  }

  render() {
    const { id, checkChange, canEdit } = this.props;
    const { text, check } = this.state;

    return (
      <CheckItemDivFlex>
        <StyledCheckBox
          type="checkbox"
          name={text}
          checked={check}
          onChange={(e) => {
            this.submit(text, id, e.target.checked, false, checkChange);
          }}
          value={`${check}`}
        />

        {canEdit ? this.renderAdminCheck() : this.renderReadOnlyCheck()}
      </CheckItemDivFlex>
    );
  }
}

CheckItemEdit.propTypes = {
  check: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  checkChange: PropTypes.func.isRequired,
  checkRemove: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

export default CheckItemEdit;
