import styled from "styled-components";

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin-top: 0px;
`;

export default FormContainer;
