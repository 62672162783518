import React from "react";
import { Alert, Intent } from "@blueprintjs/core";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import messages from "./DeleteAlert.messages";

const DeleteAlert = ({ onCancel, onConfirm, isOpen, intl }) => (
  <Alert
    cancelButtonText={intl.formatMessage(messages.cancel)}
    confirmButtonText={intl.formatMessage(messages.delete)}
    icon="trash"
    isOpen={isOpen}
    intent={Intent.DANGER}
    onCancel={onCancel}
    onConfirm={onConfirm}
  >
    <p>
      <FormattedMessage {...messages.confirmationMessage} />
    </p>
  </Alert>
);

DeleteAlert.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(DeleteAlert);
