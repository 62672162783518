import { defineMessages } from "react-intl";

const messages = {
  title: { id: "ResetPassword.title" },
  errors: {
    fieldIsRequired: {
      id: "Form.errors.fieldIsRequired",
      description: "Field is required"
    },
    confirmPassword: {
      id: "Form.errors.confirmPassword",
      description: "Confirm password is not matching password"
    }
  },
  fields: {
    newPassword: {
      id: "Form.fields.newPassword",
      description: "New password field name"
    },
    newPasswordConfirmation: {
      id: "Form.fields.newPasswordConfirmation",
      description: "New password confirmation field name"
    }
  },
  returnToLogin: { id: "ResetPassword.returnToLogin" },
  changeMyPassword: { id: "ResetPassword.changeMyPassword" }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
