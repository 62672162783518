import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

const PublicRoute = ({
  isAuthenticated,
  component: Component,
  dontRedirectIfAuthenticated,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      dontRedirectIfAuthenticated || !isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ ...props.location, pathname: "/project-summary" }} />
      )
    }
  />
);

PublicRoute.propTypes = {
  component: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  dontRedirectIfAuthenticated: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.account.token,
  };
}

export default connect(mapStateToProps)(PublicRoute);
