import React from "react";
import styled from "styled-components";
import visa from "payment-icons/min/flat/visa.svg";
import mastercard from "payment-icons/min/flat/mastercard.svg";
import amex from "payment-icons/min/flat/amex.svg";
import { FormattedMessage } from "react-intl";
import { CreditCard } from "./CreditCard";
import messages from "./CreditCardForm.messages";

const Container = styled.div`
  max-width: 450px;
  border-radius: 55px;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => `${theme.gray_light}33`};
`;

const CardContainer = styled.div`
  margin-right: 10px;
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SmallText = styled.div`
  font-size: 0.8rem;
`;

const renderCreditCard = brand => {
  switch (brand) {
    case "Visa":
      return <CreditCard src={visa} alt="Credit Card" />;
    case "MasterCard":
      return <CreditCard src={mastercard} alt="Credit Card" />;
    case "American Express":
    default:
      return <CreditCard src={amex} alt="Credit Card" />;
  }
};

const SavedCreditCard = ({ lastFour, brand, expiry, nameOnCard }) => (
  <Container>
    <LeftContainer>
      <CardContainer>{renderCreditCard(brand)}</CardContainer>
      <InfoContainer>
        <div>{`**** **** **** ${lastFour}`}</div>

        <div>
          <SmallText>
            <FormattedMessage {...messages.creditCard.cardExpiry} /> : {expiry}
          </SmallText>
          <SmallText>
            <FormattedMessage {...messages.creditCard.nameOnCard} /> :{" "}
            {nameOnCard}
          </SmallText>
        </div>
      </InfoContainer>
    </LeftContainer>
  </Container>
);

export default SavedCreditCard;
