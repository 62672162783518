import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import PageLayout from "../PageLayout";
import { LoadingOverlay } from "../../../../components/overlays";
import * as logActions from "../../../../actions/log";
import { Header, LogsTable } from "../../../../components/logs";

class LogPage extends React.Component {
  render() {
    const {
      props: { loading, logs },
    } = this;
    return (
      <PageLayout>
        {loading && <LoadingOverlay />}
        <Header />

        <LogsTable />
      </PageLayout>
    );
  }
}

const mapStateToProps = ({ log }) => {
  return {
    loading: log.loading,
  };
};

LogPage.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default injectIntl(
  connect(
    mapStateToProps,
    {
      fetchLogs: logActions.fetchLogs,
    }
  )(LogPage)
);
