import React from "react";
import styled from "styled-components";
import { H1 } from "../../../../components/text/Headers";

const RegisterFormHeaderContainer = styled.div`
  margin-bottom: 40px;
`;

const RegisterFormHeader = () => (
  <RegisterFormHeaderContainer>
    <H1>Genba</H1>
  </RegisterFormHeaderContainer>
);

export default RegisterFormHeader;
