import React from "react";
import PropTypes from "prop-types";
import { StyledCommentSection } from "./Styles";
import Comment from "./Comment";

export class CommentSection extends React.Component {
  commentSection = React.createRef();

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    this.commentSection.current.scrollIntoView({ behavior: "auto" });
  };

  render() {
    const { comments } = this.props;
    const reversedComments = comments.slice().reverse();
    return (
      <StyledCommentSection>
        {reversedComments.map(comment => (
          <Comment key={comment.id} comment={comment} />
        ))}

        <div ref={this.commentSection} />
      </StyledCommentSection>
    );
  }
}

CommentSection.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default CommentSection;
