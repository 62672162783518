import { defineMessages } from "react-intl";

const messages = {
  general: {
    title: { id: "Nav.items.paymentOptions" },
    cancel: { id: "General.cancel" },
    confirm: { id: "General.confirm" },
    save: { id: "General.save" }
  },
  options: {
    disableBillingConfirmation: {
      id: "Licences.options.disableBillingConfirmation"
    },
    noActiveLicence: {
      id: "Licences.options.noActiveLicence"
    },
    disableBilling: {
      id: "Licences.options.disableBilling"
    },
    subscriptionEndsOn: {
      id: "Licences.options.subscriptionEndsOn"
    },
    disableBillingSuccess: {
      id: "Licences.options.disableBillingSuccess"
    },
    automaticRenewOfMyLicence: {
      id: "Licences.options.automaticRenewOfMyLicence"
    },
    updateMyCreditCard: {
      id: "Licences.options.updateMyCreditCard"
    },
    updateCreditCardSuccess: {
      id: "Licences.options.updateCreditCardSuccess"
    },
    currentCreditCard: {
      id: "Licences.options.currentCreditCard"
    }
  },
  errors: {
    fieldIsRequired: { id: "Form.errors.fieldIsRequired" },
    invalidCardNumber: { id: "Form.errors.invalidCardNumber" },
    invalidCardExpiry: { id: "Form.errors.invalidCardExpiry" },
    invalidCardCVC: { id: "Form.errors.invalidCardCVC" }
  }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
