import {
  FETCH_CHECKITEMS_BEGIN,
  FETCH_CHECKITEMS_SUCCESS,
  FETCH_CHECKITEMS_FAILURE,
  POST_CHECKITEM_EDIT_BEGIN,
  POST_CHECKITEM_EDIT_SUCCESS,
  POST_CHECKITEM_EDIT_FAILURE,
  SAVE_CHECKITEM_BEGIN,
  SAVE_CHECKITEM_SUCCESS,
  SAVE_CHECKITEM_FAILURE,
  REMOVE_CHECKITEM_BEGIN,
  REMOVE_CHECKITEM_SUCCESS,
  REMOVE_CHECKITEM_FAILURE,
} from "../actions/types";

const initialState = {
  checkItems: [],
  loading: false,
  error: null,
};

export default function checkItem(state = initialState, action) {
  switch (action.type) {
    case FETCH_CHECKITEMS_BEGIN:
      return {
        checkItems: [],
        loading: true,
        error: null,
      };
    case FETCH_CHECKITEMS_SUCCESS:
      return {
        checkItems: action.payload.items,
        loading: false,
        error: null,
      };
    case FETCH_CHECKITEMS_FAILURE:
      return {
        checkItems: [],
        loading: false,
        error: action.payload,
      };
    case POST_CHECKITEM_EDIT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case POST_CHECKITEM_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case POST_CHECKITEM_EDIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SAVE_CHECKITEM_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SAVE_CHECKITEM_SUCCESS: {
      return {
        checkItems: [action.payload.checkItem, ...state.checkItems],
        loading: false,
        error: null,
      };
    }
    case SAVE_CHECKITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REMOVE_CHECKITEM_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_CHECKITEM_SUCCESS: {
      const checkItemIndex = state.checkItems.findIndex(
        (x) => x.id === action.payload.checkItemId
      );
      return {
        ...state,
        checkItems: [
          ...state.checkItems.slice(0, checkItemIndex),
          ...state.checkItems.slice(checkItemIndex + 1),
        ],
        loading: false,
        error: null,
      };
    }
    case REMOVE_CHECKITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
