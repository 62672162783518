import React from "react";
import { FormattedMessage } from "react-intl";
import { H2 } from "../text/Headers";
import { Flex } from "..";
import messages from "./Tasks.messages";
import { HasRole, roles } from "../roles";
import { UserSelect } from "../users";

class Header extends React.Component {
  onUserItemSelected = user => {
    const { updateSelectedUser } = this.props;

    updateSelectedUser(user);
  };

  render() {
    const { isCurrentUserTasks, users, selectedUser } = this.props;
    let currentUserName = "";

    if (selectedUser) {
      currentUserName = `${selectedUser.firstname} ${selectedUser.lastname}`;
    }

    return (
      <Flex justify="space-between" align="center">
        <H2>
          {isCurrentUserTasks ? (
            <FormattedMessage {...messages.general.myTasks} />
          ) : (
            <FormattedMessage
              {...messages.general.userTasks}
              values={{ name: currentUserName }}
            />
          )}
        </H2>

        {selectedUser && (
          <HasRole minRole={roles.ProjectManager}>
            <UserSelect
              users={users}
              activeUser={users[users.findIndex(x => x.id === selectedUser.id)]}
              onUserSelected={this.onUserItemSelected}
            />
          </HasRole>
        )}
      </Flex>
    );
  }
}
export default Header;
