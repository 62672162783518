import {
  FETCH_COMMENTS_BEGIN,
  FETCH_COMMENTS_SUCCESS,
  FETCH_COMMENTS_FAILURE,
  SAVE_COMMENT_BEGIN,
  SAVE_COMMENT_SUCCESS,
  SAVE_COMMENT_FAILURE
} from "./types";
import api from "../api";

const fetchCommentsBegin = () => ({
  type: FETCH_COMMENTS_BEGIN
});

const fetchCommentsSuccess = comments => ({
  type: FETCH_COMMENTS_SUCCESS,
  payload: comments
});

const fetchCommentsFailure = error => ({
  type: FETCH_COMMENTS_FAILURE,
  payload: error
});

const saveCommentBegin = () => ({
  type: SAVE_COMMENT_BEGIN
});

const saveCommentSuccess = comment => ({
  type: SAVE_COMMENT_SUCCESS,
  payload: comment
});

const saveCommentFailure = error => ({
  type: SAVE_COMMENT_FAILURE,
  payload: error
});

export const fetchComments = (componentGuid, paging) => dispatch => {
  dispatch(fetchCommentsBegin());

  return api.comments
    .get(componentGuid, paging)
    .then(res => dispatch(fetchCommentsSuccess(res.data)))
    .catch(err => dispatch(fetchCommentsFailure(err)));
};

export const saveComment = (
  componentGuid,
  commentText,
  itemId,
  itemParents
) => dispatch => {
  dispatch(saveCommentBegin());

  return api.comments
    .post(componentGuid, commentText)
    .then(res =>
      dispatch(saveCommentSuccess({ comment: res.data, itemId, itemParents }))
    )
    .catch(err => dispatch(saveCommentFailure(err)));
};
