import {
  FETCH_PROFILE_BEGIN,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  PUT_PROFILE_BEGIN,
  PUT_PROFILE_SUCCESS,
  PUT_PROFILE_FAILURE,
  UPDATE_PASSWORD_BEGIN,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_AVATAR_BEGIN,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_AVATAR_FAILURE,
} from "./types";
import api from "../api";

// *** Fetching Profile *** //
const fetchProfileBegin = () => ({
  type: FETCH_PROFILE_BEGIN,
});

const fetchProfileSuccess = (profile) => ({
  type: FETCH_PROFILE_SUCCESS,
  payload: profile,
});

const fetchProfileFailure = (error) => ({
  type: FETCH_PROFILE_FAILURE,
  payload: error,
});

const updatePasswordBegin = () => ({
  type: UPDATE_PASSWORD_BEGIN,
});

const updatePasswordSuccess = () => ({
  type: UPDATE_PASSWORD_SUCCESS,
});

const updatePasswordFailure = (error) => ({
  type: UPDATE_PASSWORD_FAILURE,
  payload: error,
});

const updateAvatarBegin = () => ({
  type: UPDATE_AVATAR_BEGIN,
});

const updateAvatarSuccess = (user) => ({
  type: UPDATE_AVATAR_SUCCESS,
  payload: user,
});

const updateAvatarFailure = (error) => ({
  type: UPDATE_AVATAR_FAILURE,
  payload: error,
});

export const fetchProfile = () => (dispatch) => {
  dispatch(fetchProfileBegin());

  return api.profile
    .get()
    .then((res) => {
      dispatch(fetchProfileSuccess(res.data));
      return res.data;
    })
    .catch((error) => {
      dispatch(fetchProfileFailure(error));
    });
};

// *** Saving Profile *** //
const saveProfileBegin = (data) => ({
  type: PUT_PROFILE_BEGIN,
  payload: data,
});

const saveProfileSuccess = (data) => ({
  type: PUT_PROFILE_SUCCESS,
  payload: data,
});

const saveProfileFailure = (error) => ({
  type: PUT_PROFILE_FAILURE,
  payload: error,
});

export const saveProfile = (data) => (dispatch) => {
  dispatch(saveProfileBegin(data));

  return api.profile
    .put(data)
    .then((res) => {
      dispatch(saveProfileSuccess(data));
      return res;
    })
    .catch((error) => {
      dispatch(saveProfileFailure(error));
      return error;
    });
};

export const updatePassword = (data) => (dispatch) => {
  dispatch(updatePasswordBegin());

  return api.profile
    .updatePassword(data)
    .then(() => dispatch(updatePasswordSuccess()))
    .catch((error) => {
      dispatch(updatePasswordFailure(error));
      throw error;
    });
};

export const updateAvatar = (file, selectedAvatar) => (dispatch) => {
  dispatch(updateAvatarBegin());

  return api.profile
    .updateAvatar(file, selectedAvatar)
    .then((res) => {
      dispatch(updateAvatarSuccess(res.data));
    })
    .catch((error) => {
      dispatch(updateAvatarFailure(error));
      throw error;
    });
};
