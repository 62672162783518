import React from "react";
import styled from "styled-components";
import { Popover, Menu, MenuItem, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { ItemType } from "../../../..";
import DeletePopover from "../../../../../popovers/DeletePopover";
import ActionButton from "./ActionButton";
import messages from "../../../../Projects.messages";
import StyledActionIcon from "./StyleActionButton";

const StyledContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin-left: 8px;
  flex-shrink: 0;
`;

const DesktopActionSection = styled.div`
  display: flex;

  @media (max-width: ${({ theme }) => theme.mobile_small_max_width}) {
    display: none;
  }
`;

const MobileActionSection = styled.div`
  @media (min-width: ${({ theme }) => theme.mobile_small_max_width}) {
    display: none;
  }
`;

class ItemActionSection extends React.Component {
  getPopoverTextForCanAdd = () => {
    const { itemType, intl } = this.props;

    switch (itemType) {
      case ItemType.OBJECTIVE:
        return intl.formatMessage(messages.general.addStrategy);
      case ItemType.STRATEGY:
        return intl.formatMessage(messages.general.addAction);
      case ItemType.ACTION:
      default:
        return "";
    }
  };

  setIntentCheckList = (hasCheckItems, isAllItemChecked) => {
    if (hasCheckItems) {
      if (isAllItemChecked) {
        return Intent.SUCCESS;
      }
      return Intent.PRIMARY;
    }
    return Intent.NONE;
  };

  getPopoverTextForCanDelete = () => {
    const { itemType, intl } = this.props;

    switch (itemType) {
      case ItemType.OBJECTIVE:
        return intl.formatMessage(messages.general.deleteGoal);
      case ItemType.STRATEGY:
        return intl.formatMessage(messages.general.deleteStrategy);
      case ItemType.ACTION:
      default:
        return intl.formatMessage(messages.general.deleteAction);
    }
  };

  checkItemButtonClick = () => {
    const { permissions, checkItemButtonClick } = this.props;

    checkItemButtonClick(permissions.canEdit);
  };

  renderMobileActionSection = () => {
    const {
      itemType,
      editButtonClicked,
      itemUsersButtonClicked,
      addButtonClicked,
      deleteButtonClicked,
      permissions,
      commentButtonClick,
      hasComments,
      attachmentButtonClick,
      hasAttachments,
      hasCheckItems,
      isAllItemChecked,
      hasUsers,
      intl,
    } = this.props;

    return (
      <MobileActionSection>
        <Popover
          content={
            <Menu>
              {permissions.canEdit && itemType !== ItemType.PROJECT && (
                <MenuItem
                  text={intl.formatMessage(messages.general.editComponent)}
                  icon="edit"
                  onClick={editButtonClicked}
                />
              )}

              {permissions.canAssociate && (
                <MenuItem
                  text={intl.formatMessage(messages.general.assignUsers)}
                  icon="user"
                  onClick={itemUsersButtonClicked}
                  intent={hasUsers ? Intent.PRIMARY : Intent.NONE}
                />
              )}

              {permissions.canComment && (
                <MenuItem
                  text={intl.formatMessage(messages.general.commentComponent)}
                  icon="comment"
                  onClick={commentButtonClick}
                  intent={hasComments ? Intent.PRIMARY : Intent.NONE}
                />
              )}

              {permissions.canAttachment && (
                <MenuItem
                  text={intl.formatMessage(
                    messages.general.attachmentComponent
                  )}
                  icon="paperclip"
                  onClick={attachmentButtonClick}
                  intent={hasAttachments ? Intent.PRIMARY : Intent.NONE}
                />
              )}

              {itemType !== ItemType.PROJECT && permissions.canCheckItem && (
                <MenuItem
                  text={intl.formatMessage(messages.general.checkItemComponent)}
                  icon="form"
                  onClick={this.checkItemButtonClick}
                  intent={this.setIntentCheckList(
                    hasCheckItems,
                    isAllItemChecked
                  )}
                />
              )}

              {permissions.canAdd &&
                itemType !== ItemType.PROJECT &&
                itemType !== ItemType.ACTION && (
                  <MenuItem
                    text={this.getPopoverTextForCanAdd()}
                    icon="add"
                    onClick={addButtonClicked}
                  />
                )}

              {permissions.canRemove && itemType !== ItemType.PROJECT && (
                <MenuItem
                  text={this.getPopoverTextForCanDelete()}
                  icon="remove"
                  onClick={deleteButtonClicked}
                />
              )}
            </Menu>
          }
        >
          <StyledActionIcon icon="cog" iconSize={25} />
        </Popover>
      </MobileActionSection>
    );
  };

  renderDesktopActionSection = () => {
    const {
      itemType,
      editButtonClicked,
      itemUsersButtonClicked,
      addButtonClicked,
      deleteButtonClicked,
      permissions,
      commentButtonClick,
      hasComments,
      attachmentButtonClick,
      hasAttachments,
      checkItemButtonClick,
      hasCheckItems,
      isAllItemChecked,
      hasUsers,
      intl,
    } = this.props;
    return (
      <DesktopActionSection>
        {permissions.canEdit && itemType !== ItemType.PROJECT && (
          <ActionButton
            onClick={editButtonClicked}
            icon="edit"
            title={intl.formatMessage(messages.general.editComponent)}
          />
        )}

        {permissions.canAssociate && (
          <ActionButton
            onClick={itemUsersButtonClicked}
            icon="user"
            title={intl.formatMessage(messages.general.assignUsers)}
            intent={hasUsers ? Intent.PRIMARY : Intent.NONE}
          />
        )}

        <ActionButton
          onClick={commentButtonClick}
          icon="comment"
          title={intl.formatMessage(messages.general.commentComponent)}
          intent={hasComments ? Intent.PRIMARY : Intent.NONE}
        />

        <ActionButton
          onClick={attachmentButtonClick}
          icon="paperclip"
          title={intl.formatMessage(messages.general.attachmentComponent)}
          intent={hasAttachments ? Intent.PRIMARY : Intent.NONE}
        />

        {itemType !== ItemType.PROJECT && permissions.canCheckItem && (
          <ActionButton
            onClick={checkItemButtonClick}
            icon="form"
            title={intl.formatMessage(messages.general.checkItemComponent)}
            intent={this.setIntentCheckList(hasCheckItems, isAllItemChecked)}
          />
        )}

        {permissions.canAdd &&
          itemType !== ItemType.ACTION &&
          itemType !== ItemType.PROJECT && (
            <ActionButton
              onClick={addButtonClicked}
              icon="add"
              title={this.getPopoverTextForCanAdd()}
            />
          )}

        {permissions.canRemove && itemType !== ItemType.PROJECT && (
          <DeletePopover onDelete={deleteButtonClicked}>
            <ActionButton
              icon="remove"
              title={this.getPopoverTextForCanDelete()}
            />
          </DeletePopover>
        )}
      </DesktopActionSection>
    );
  };

  render() {
    return (
      <StyledContainer>
        {this.renderDesktopActionSection()}
        {this.renderMobileActionSection()}
      </StyledContainer>
    );
  }
}

ItemActionSection.propTypes = {
  itemType: PropTypes.string.isRequired,
  editButtonClicked: PropTypes.func,
  itemUsersButtonClicked: PropTypes.func.isRequired,
  addButtonClicked: PropTypes.func,
  deleteButtonClicked: PropTypes.func,
  commentButtonClick: PropTypes.func.isRequired,
  hasComments: PropTypes.bool.isRequired,
  attachmentButtonClick: PropTypes.func.isRequired,
  hasAttachments: PropTypes.bool.isRequired,
  checkItemButtonClick: PropTypes.func.isRequired,
  hasCheckItems: PropTypes.bool,
  isAllItemChecked: PropTypes.bool,
  intl: intlShape.isRequired,
  hasUsers: PropTypes.bool.isRequired,
  permissions: PropTypes.shape({}).isRequired,
};

export default injectIntl(ItemActionSection);
