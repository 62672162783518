import React from "react";
import PropTypes from "prop-types";
import RoleRoute from "../../routes/RoleRoute";
import UsersManagementPage from "../../../containers/pages/privatePage/usersManagementPage";
import ProjectsManagementPage from "../../../containers/pages/privatePage/projectsManagementPage";
import LicencePage from "../../../containers/pages/privatePage/licencePage";
import PaymentOptionsPage from "../../../containers/pages/privatePage/paymentOptionsPage";
import { roles } from "../../roles/roles";
import LogPage from "../../../containers/pages/privatePage/logPage";

const AppRoleRoutes = ({ location }) => (
  <React.Fragment>
    <RoleRoute
      location={location}
      path="/users-management"
      exact
      component={UsersManagementPage}
      minRole={roles.Administrator}
    />

    <RoleRoute
      location={location}
      path="/licence"
      exact
      component={LicencePage}
      minRole={roles.Administrator}
    />

    <RoleRoute
      location={location}
      path="/payment-options"
      exact
      component={PaymentOptionsPage}
      minRole={roles.Administrator}
    />

    <RoleRoute
      location={location}
      path="/projects-management"
      exact
      component={ProjectsManagementPage}
      minRole={roles.ProjectManager}
    />

    <RoleRoute
      location={location}
      path="/logs"
      exact
      component={LogPage}
      minRole={roles.ProjectManager}
    />
  </React.Fragment>
);

AppRoleRoutes.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default AppRoleRoutes;
