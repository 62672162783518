import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import queryString from "query-string";
import * as actions from "../../../../actions/auth";
import RegisterForm from "../../../forms/register";
import PageLayout from "../PageLayout";

class RegisterPage extends React.Component {
  getInvitationToken = () => {
    const {
      location: { search }
    } = this.props;
    const values = queryString.parse(search);
    const { token } = values;

    return token;
  };

  submit = data => {
    const {
      props: { register, history }
    } = this;

    return register(data).then(() => history.push("/project-summary"));
  };

  render() {
    return (
      <PageLayout>
        <RegisterForm
          submit={this.submit}
          invitationToken={this.getInvitationToken()}
        />
      </PageLayout>
    );
  }
}

RegisterPage.propTypes = {
  register: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired
};

export default connect(
  null,
  { register: actions.register }
)(RegisterPage);
