import React from "react";
import PropTypes from "prop-types";
import {
  StyledComment,
  CommentDisplaySection,
  CommentInfos,
  CommentInfo,
  CommentText
} from "./Styles";
import { toLocalizedDateTimeString } from "../../helpers/DateHelper";
import Avatar from "../avatar/Avatar";

const getAvatarLink = userLink => {
  if (userLink !== null) {
    return userLink;
  }

  return "/images/avatars/default_avatar.svg";
};

const Comment = ({ comment }) => (
  <StyledComment>
    <Avatar avatarLink={getAvatarLink(comment.user.avatarLink)} />

    <CommentDisplaySection>
      <CommentInfos>
        <CommentInfo>{comment.user.fullname}</CommentInfo>
        <CommentInfo>
          {toLocalizedDateTimeString(comment.creationDate)}
        </CommentInfo>
      </CommentInfos>
      <CommentText>{comment.text}</CommentText>
    </CommentDisplaySection>
  </StyledComment>
);

Comment.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    componentGuid: PropTypes.string.isRequired,
    creationDate: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired
  }).isRequired
};

export default Comment;
