import React from "react";
import styled from "styled-components";
import { H1 } from "../../../../components/text/Headers";

const LoginFormHeaderContainer = styled.div`
  margin-bottom: 40px;
`;

const LoginFormHeader = () => (
  <LoginFormHeaderContainer>
    <H1>Genba</H1>
  </LoginFormHeaderContainer>
);

export default LoginFormHeader;
