import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Header from "../../../components/headers";
import Footer from "../../../components/footer";
import messages from "../Pages.messages";
import { HasRole, roles } from "../../../components/roles";

import logo from "../../../images/logos/genba-monogram-couleur.svg";

const MainContentContainer = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ImageBackground = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  width: 500px;
  background-repeat: no-repeat;
  background-image: url(${logo});
  opacity: 0.15;
  transform: translate(20%, 60%);
  overflow: hidden;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  @media (max-height: ${({ theme }) => theme.mobile_small_height}) {
    min-height: 110vh;
  }
`;

const MainContent = styled.div`
  position: relative;
  overflow-x: hidden;
  width: 90%;
  margin: auto;
  padding: 20px 40px;
  border-radius: 15px;
  min-height: 400px;

  @media (max-width: ${({ theme }) => theme.mobile_max_width}) {
    width: 100%;
    padding: 10px 10px;
    background: inherit;
  }
`;

const InvalidLicenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  text-align: center;
  background: ${({ theme }) => theme.alert_message_red_background};
  color: ${({ theme }) => theme.alert_message_red};
  border-radius: 10px;
  border: ${({ theme }) => `1px solid ${theme.alert_message_red_border}`};
  padding: 3px 10px;
  margin-bottom: 10px;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.alert_message_red};
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.alert_message_red};
  }
`;

class PageLayout extends React.Component {
  shouldRenderInactiveLicence = () => {
    const { licenceIsActive } = this.props;
    const isLicenceRoute = window.location.pathname.includes("licence");

    return !licenceIsActive && !isLicenceRoute;
  };

  renderInactiveLicenceMessage = () => (
    <InvalidLicenceContainer>
      <HasRole minRole={roles.Administrator} exact>
        <FormattedMessage {...messages.alerts.inactiveLicenceAdmin} />
        <StyledLink to="/licence">
          <FormattedMessage {...messages.alerts.goToLicencePage} />
        </StyledLink>
      </HasRole>

      <HasRole minRole={roles.RegularUser} maxRole={roles.ProjectManager}>
        <FormattedMessage {...messages.alerts.inactiveLicence} />
      </HasRole>
    </InvalidLicenceContainer>
  );

  render() {
    const { children } = this.props;
    return (
      <Container>
        <Header withColor={false} />
        <MainContentContainer>
          <ImageBackground />
          <MainContent>
            {this.shouldRenderInactiveLicence() &&
              this.renderInactiveLicenceMessage()}

            {children}
          </MainContent>
        </MainContentContainer>

        <Footer />
      </Container>
    );
  }
}

PageLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  licenceIsActive: PropTypes.bool.isRequired
};

const mapStateToProps = ({ licence }) => ({
  licenceIsActive: licence.informations.isActive
});

export default connect(mapStateToProps)(PageLayout);
