import styled from "styled-components";
import { Dialog, FileInput, Button } from "@blueprintjs/core";

export const StyledDialog = styled(Dialog)`
  position: relative;
  max-width: 600px !important;
  width: 80% !important;
`;
export const StyledFileInput = styled(FileInput)`
  margin-right: 5px !important;
  float: left;
  margin-bottom: 10px;
`;
export const StyledButtonSave = styled(Button)`
  min-width: 90px !important;
  float: right;
  margin-bottom: 10px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 80vh !important;
  padding: 20px 20px;
`;

export const StyledAttachmentSection = styled.div`
  background: ${({ theme }) => theme.gray_very_light};
  border-radius: 10px;
  box-shadow: 8px 8px 58px 0 rgba(0, 0, 0, 0.2);
  min-height: 200px;
  overflow-y: scroll;
  margin-bottom: 20px;
`;

export const InputSection = styled.form`
  display: flex;
  @media (max-width: ${({ theme }) => theme.mobile_small_max_width}) {
    flex-direction: column;
  }
`;

export const StyledAttachment = styled.div`
  margin: 0px 20px;
  padding: 20px 0px;
  min-height: 50px;
  display: flex;
  align-items: center;

  &:not(:first-child) {
    border-top: ${({ theme }) => `1px solid ${theme.gray_light}`};
  }
`;

export const AttachmentDisplaySection = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const AttachmentInfos = styled.div`
  display: flex;
`;

export const AttachmentDownloadLink = styled.a`
  display: flex;
`;

export const AttachmentInfo = styled.p`
  font-weight: bold;
  font-size: 0.85em;
  padding: 0px 5px 0px 0px;
`;

export const AttachmentText = styled.p``;
