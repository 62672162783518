import React from "react";
import PropTypes from "prop-types";
import { ItemContainer } from "../items/index";
import { ItemType } from "../..";
import { roles } from "../../../roles";

const ActionList = ({
  actions,
  goal,
  strategy,
  userActions,
  queryValues,
  currentUser,
  isParentManager,
  project
}) => {
  if (actions) {
    return actions
      .sort((a, b) => a.rank - b.rank)
      .map((action, index) => (
        <ItemContainer
          item={action}
          key={`action-${action.id}`}
          index={index}
          itemType={ItemType.ACTION}
          collection={ItemType.ACTION}
          userActions={userActions}
          goalId={goal.id}
          strategyId={strategy.id}
          hasChildren={false}
          queryValues={queryValues}
          currentUser={currentUser}
          disabled={
            currentUser.role !== roles.Administrator &&
            currentUser.role !== roles.Management &&
            project.manager.id !== currentUser.id &&
            goal.manager.id !== currentUser.id &&
            strategy.manager.id !== currentUser.id
          }
          isParentManager={isParentManager}
        />
      ));
  }

  return <div>No action</div>;
};

ActionList.defaultProps = {
  actions: []
};

ActionList.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape().isRequired),
  goal: PropTypes.shape({}).isRequired,
  strategy: PropTypes.shape({}).isRequired,
};

export default ActionList;
