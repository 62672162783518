import {
  POST_ITEM_BEGIN,
  POST_ITEM_SUCCESS,
  POST_ITEM_FAILURE,
  PUT_ITEM_BEGIN,
  PUT_ITEM_SUCCESS,
  PUT_ITEM_FAILURE,
  PUT_USERS_ITEM_BEGIN,
  PUT_USERS_ITEM_SUCCESS,
  PUT_USERS_ITEM_FAILURE,
  OPEN_ITEM_DIALOG,
  CLOSE_ITEM_DIALOG
} from "../actions/types";

const initialState = {
  loading: false,
  error: null,
  itemDialogOpened: false
};

export default function item(state = initialState, action) {
  switch (action.type) {
    case PUT_USERS_ITEM_BEGIN:
    case POST_ITEM_BEGIN:
    case PUT_ITEM_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case PUT_USERS_ITEM_SUCCESS:
    case POST_ITEM_SUCCESS:
    case PUT_ITEM_SUCCESS:
      return initialState;
    case PUT_USERS_ITEM_FAILURE:
    case POST_ITEM_FAILURE:
    case PUT_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case OPEN_ITEM_DIALOG:
      return {
        ...state,
        itemDialogOpened: true
      };
    case CLOSE_ITEM_DIALOG:
      return {
        ...state,
        itemDialogOpened: false
      };
    default:
      return state;
  }
}
