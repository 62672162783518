import React from "react";
import { ItemContainer } from "../items/index";
import { ItemType } from "../..";
import StrategyList from "../strategyList/StrategyList";
import ItemSortableContainer from "../itemSortableContainer/ItemSortableContainer";
import { roles } from "../../../roles";

const GoalList = ({
  goals,
  queryValues,
  userActions,
  currentUser,
  isParentManager,
  project
}) =>
  goals
    .sort((a, b) => a.rank - b.rank)
    .map((goal, index) => (
      <ItemContainer
        item={goal}
        key={`goal-${goal.id}`}
        index={index}
        itemType={ItemType.OBJECTIVE}
        collection={ItemType.OBJECTIVE}
        userActions={userActions}
        hasChildren={goal.strategies.length > 0}
        queryValues={queryValues}
        currentUser={currentUser}
        disabled={
          currentUser.role !== roles.Administrator &&
          currentUser.role !== roles.Management &&
          project.manager.id !== currentUser.id
        }
        isParentManager={isParentManager}
      >
        <ItemSortableContainer
          itemOrderChanged={userActions.itemOrderChanged}
          goalId={goal.id}
        >
          <StrategyList
            strategies={goal.strategies}
            goal={goal}
            userActions={userActions}
            queryValues={queryValues}
            isParentManager={
              goal.manager.id === currentUser.id || isParentManager
            }
            currentUser={currentUser}
            project={project}
          />
        </ItemSortableContainer>
      </ItemContainer>
    ));

export default GoalList;
