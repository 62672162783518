import {
  FETCH_ATTACHMENTS_BEGIN,
  FETCH_ATTACHMENTS_SUCCESS,
  FETCH_ATTACHMENTS_FAILURE,
  FETCH_ATTACHMENT_FILE_BEGIN,
  FETCH_ATTACHMENT_FILE_SUCCESS,
  FETCH_ATTACHMENT_FILE_FAILURE,
  SAVE_ATTACHMENT_BEGIN,
  SAVE_ATTACHMENT_SUCCESS,
  SAVE_ATTACHMENT_FAILURE,
  REMOVE_ATTACHMENT_BEGIN,
  REMOVE_ATTACHMENT_SUCCESS,
  REMOVE_ATTACHMENT_FAILURE,
} from "./types";
import api from "../api";

const fetchAttachmentsBegin = () => ({
  type: FETCH_ATTACHMENTS_BEGIN,
});

const fetchAttachmentsSuccess = (attachments) => ({
  type: FETCH_ATTACHMENTS_SUCCESS,
  payload: attachments,
});

const fetchAttachmentsFailure = (error) => ({
  type: FETCH_ATTACHMENTS_FAILURE,
  payload: error,
});

const fetchAttachmentFileBegin = () => ({
  type: FETCH_ATTACHMENT_FILE_BEGIN,
});

const fetchAttachmentFileSuccess = () => ({
  type: FETCH_ATTACHMENT_FILE_SUCCESS,
});

const fetchAttachmentFileFailure = (error) => ({
  type: FETCH_ATTACHMENT_FILE_FAILURE,
  payload: error,
});
const saveAttachmentBegin = () => ({
  type: SAVE_ATTACHMENT_BEGIN,
});

const saveAttachmentSuccess = (attachment) => ({
  type: SAVE_ATTACHMENT_SUCCESS,
  payload: attachment,
});

const saveAttachmentFailure = (error) => ({
  type: SAVE_ATTACHMENT_FAILURE,
  payload: error,
});

const removeAttachmentBegin = () => ({
  type: REMOVE_ATTACHMENT_BEGIN,
});

const removeAttachmentSuccess = (attachment) => ({
  type: REMOVE_ATTACHMENT_SUCCESS,
  payload: attachment,
});

const removeAttachmentFailure = (error) => ({
  type: REMOVE_ATTACHMENT_FAILURE,
  payload: error,
});

export const fetchAttachments = (componentGuid, paging) => (dispatch) => {
  dispatch(fetchAttachmentsBegin());

  return api.attachments
    .get(componentGuid, paging)
    .then((res) => dispatch(fetchAttachmentsSuccess(res.data)))
    .catch((err) => dispatch(fetchAttachmentsFailure(err)));
};

export const fetchAttachmentFile = (componentGuid, fileId) => (dispatch) => {
  dispatch(fetchAttachmentFileBegin());

  return api.attachments
    .getFile(componentGuid, fileId)
    .then((res) => {
      dispatch(fetchAttachmentFileSuccess());
      return res.data;
    })
    .catch((err) => dispatch(fetchAttachmentFileFailure(err)));
};

export const saveAttachment = (
  componentGuid,
  fileName,
  file,
  itemId,
  itemParents
) => (dispatch) => {
  dispatch(saveAttachmentBegin());

  return api.attachments
    .post(componentGuid, fileName, file)
    .then((res) =>
      dispatch(
        saveAttachmentSuccess({ attachment: res.data, itemId, itemParents })
      )
    )
    .catch((err) => dispatch(saveAttachmentFailure(err)));
};

export const removeAttachment = (
  componentGuid,
  attachmentId,
  itemId,
  itemParents,
  hasItem
) => (dispatch) => {
  dispatch(removeAttachmentBegin());

  return api.attachments
    .remove(componentGuid, attachmentId)
    .then((res) => {
      dispatch(
        removeAttachmentSuccess({
          attachmentId,
          itemId,
          itemParents,
          hasItem,
        })
      );
      return res.data;
    })
    .catch((err) => dispatch(removeAttachmentFailure(err)));
};
