import { defineMessages } from "react-intl";

const messages = {
  RegularUser: { id: "Roles.regularUser" },
  ProjectManager: { id: "Roles.projectManager" },
  Management: { id: "Roles.management" },
  Administrator: { id: "Roles.administrator" }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
