import styled from "styled-components";
import { Dialog } from "@blueprintjs/core";
import { Input, Label } from "../forms";

export const StyledDialog = styled(Dialog)`
  position: relative;
  max-width: 600px !important;
  width: 80% !important;
`;
export const StyledCheckBox = styled(Input)`
  width: 12px;
`;
export const StyledLabel = styled(Label)`
  display: inline-block;
  margin-left: 5px;
  width: 100%;
  vertical-align: top;
  font-weight: 500;
  word-break: break-word;
`;

export const StyledTextbox = styled.textarea`
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  width: 100%;
  vertical-align: middle;
  resize: vertical;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 80vh !important;
  padding: 20px 20px;
`;

export const StyledCheckItemSection = styled.div`
  background: ${({ theme }) => theme.gray_very_light};
  border-radius: 10px;
  box-shadow: 8px 8px 58px 0 rgba(0, 0, 0, 0.2);
  min-height: 200px;
  overflow-y: scroll;
  margin-bottom: 20px;
`;

export const TextInput = styled.textarea`
  flex-grow: 1;
  margin-right: 5px;
  resize: none;
`;

export const InputSection = styled.form`
  display: flex;
`;

export const StyledCheckItem = styled.div`
  margin: 0 0px 0 20px;
  min-height: 40px;
  display: flex;
  align-items: center;

  &:not(:first-child) {
    border-top: ${({ theme }) => `1px solid ${theme.gray_light}`};
  }
`;

export const CheckItemDisplaySection = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

export const CheckItemDivFlex = styled.div`
  display: flex;
`;

export const CheckItemDownloadLink = styled.a`
  display: flex;
`;

export const CheckItemInfo = styled.p`
  font-weight: bold;
  font-size: 0.85em;
  padding: 0px 5px 0px 0px;
`;

export const CheckItemText = styled.p``;
