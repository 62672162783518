import React from "react";
import styled from "styled-components";
import { Spinner, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import { ItemType } from "../projects";

const ButtonSize = {
  SMALL: "SMALL",
  MEDIUM: "MEDIUM",
  LARGE: "LARGE",
  FULL: "FULL"
};

const StyledButton = styled.button`
  margin: 5px 0px;

  height: 3em;
  border-radius: 55px;
  font-weight: bold;
  transition: 0.25s;

  background: ${props => props.theme.white};

  min-width: ${props => {
    if (props.size === ButtonSize.SMALL) return "150px";
    if (props.size === ButtonSize.MEDIUM) return "300px";
    if (props.size === ButtonSize.LARGE) return "450px";
    return "100%";
  }};

  color: ${({ theme, itemType }) => {
    if (itemType === ItemType.OBJECTIVE) return theme.green_regular;
    if (itemType === ItemType.STRATEGY) return theme.blue_regular;
    if (itemType === ItemType.ACTION) return theme.purple_regular;
    return theme.blue_regular;
  }};

  border: 2px solid
    ${({ theme, itemType }) => {
      if (itemType === ItemType.OBJECTIVE) return theme.green_regular;
      if (itemType === ItemType.STRATEGY) return theme.blue_regular;
      if (itemType === ItemType.ACTION) return theme.purple_regular;
      return theme.blue_regular;
    }};

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.white};
    background: ${({ theme, itemType }) => {
      if (itemType === ItemType.OBJECTIVE) return theme.green_regular;
      if (itemType === ItemType.STRATEGY) return theme.blue_regular;
      if (itemType === ItemType.ACTION) return theme.purple_regular;
      return theme.blue_regular;
    }};
  }

  &:disabled {
    background: inherit;

    &:hover {
      cursor: default;
      color: ${({ theme, itemType }) => {
        if (itemType === ItemType.OBJECTIVE) return theme.green_regular;
        if (itemType === ItemType.STRATEGY) return theme.blue_regular;
        if (itemType === ItemType.ACTION) return theme.purple_regular;
        return theme.blue_regular;
      }};
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile_max_width}) {
    min-width: 100%;
  }
`;

const Button = ({
  children,
  type,
  loading,
  intent,
  size,
  itemType,
  disabled,
  ...rest
}) => (
  <StyledButton
    type={type}
    disabled={disabled}
    intent={intent}
    size={size}
    itemType={itemType}
    {...rest}
  >
    {!loading ? children : <Spinner size={Spinner.SIZE_SMALL} />}
  </StyledButton>
);

Button.defaultProps = {
  type: "submit",
  loading: false,
  intent: Intent.PRIMARY,
  size: ButtonSize.FULL,
  itemType: "",
  disabled: false
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  loading: PropTypes.bool,
  intent: PropTypes.string,
  size: PropTypes.string,
  itemType: PropTypes.string,
  disabled: PropTypes.bool
};

export { Button, ButtonSize };
