import React from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import { Button, Intent } from "@blueprintjs/core";
import { StyledDialog, Container, InputSection, TextInput } from "./Styles";
import { CommentSection } from "./CommentSection";
import { LoadingOverlay } from "../overlays";
import messages from "./Comment.messages";

class CommentDialogs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comment: ""
    };
  }

  commentChanged = event => {
    this.setState({ comment: event.target.value });
  };

  onClose = () => {
    const { onClose } = this.props;

    onClose();
  };

  onEnterPress = e => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      this.submit(e);
    }
  };

  submit = event => {
    event.preventDefault();
    const {
      state: { comment },
      props: { submit }
    } = this;

    if (comment) {
      submit(comment).then(() => this.setState({ comment: "" }));
    }
  };

  render() {
    const {
      props: { isOpen, loading, comments, intl },
      state: { comment }
    } = this;

    return (
      <StyledDialog
        title={intl.formatMessage(messages.general.title)}
        isOpen={isOpen}
        onClose={this.onClose}
        canOutsideClickClose={false}
      >
        {loading && <LoadingOverlay />}
        <Container>
          <CommentSection comments={comments} />

          <InputSection onSubmit={this.submit}>
            <TextInput
              value={comment}
              onChange={this.commentChanged}
              rows="3"
              onKeyDown={this.onEnterPress}
            />
            <Button intent={Intent.PRIMARY} type="submit" disabled={!comment}>
              <FormattedMessage {...messages.general.send} />
            </Button>
          </InputSection>
        </Container>
      </StyledDialog>
    );
  }
}

CommentDialogs.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(CommentDialogs);
