import { defineMessages } from "react-intl";

const messages = {
  header: {
    addGoal: { id: "ProjectSummary.addGoal" },
  },
  fields: {
    manager: { id: "Form.fields.manager" },
    priority: { id: "Form.fields.priority" },
    deadlineDate: { id: "Form.fields.deadlineDate" },
    percentage: { id: "Form.fields.percentage" },
    name: { id: "Form.fields.name" },
    description: { id: "Form.fields.description" },
    measuringTools: { id: "Form.fields.measuringTools" },
    creationDate: { id: "Form.fields.creationDate" },
    updateDate: { id: "Form.fields.updateDate" },
    notes: { id: "Form.fields.notes" },
    isCompleted: { id: "Form.fields.isCompleted" },
    beginningDate: { id: "Form.fields.beginningDate" },
    benefits: { id: "Form.fields.benefits" },
    isPrivate: { id: "Form.fields.isPrivate" },
  },
  dialog: {
    generalInformations: { id: "ProjectSummary.generalInformations" },
    additionalInformations: { id: "ProjectSummary.additionalInformations" },
    save: { id: "General.save" },
    assignedUsers: { id: "ProjectSummary.assignedUsers" },
    searchUsers: { id: "ProjectSummary.searchUser" },
  },
  itemType: {
    objective: { id: "General.goal" },
    strategy: { id: "General.strategy" },
    action: { id: "General.action" },
  },
  errors: {
    fieldIsRequired: { id: "Form.errors.fieldIsRequired" },
    beginningDateBiggerThanDeadlineDate: {
      id: "Form.errors.beginningDateBiggerThanDeadlineDate",
    },
    deadlineDateSmallerThanBeginningDate: {
      id: "Form.errors.deadlineDateSmallerThanBeginningDate",
    },
    invalidPriority: { id: "Form.errors.invalidPriority" },
    invalidCharacterCount: { id: "Form.errors.invalidCharacterCount" },
  },
  general: {
    editComponent: { id: "ProjectSummary.editComponent" },
    assignUsers: { id: "ProjectSummary.assignUsers" },
    commentComponent: { id: "ProjectSummary.commentComponent" },
    attachmentComponent: { id: "ProjectSummary.attachmentComponent" },
    checkItemComponent: { id: "ProjectSummary.checkItemComponent" },
    addSubComponent: { id: "ProjectSummary.addSubComponent" },
    removeComponent: { id: "ProjectSummary.removeComponent" },
    addStrategy: { id: "ProjectSummary.addStrategy" },
    addAction: { id: "ProjectSummary.addAction" },
    deleteGoal: { id: "ProjectSummary.deleteGoal" },
    deleteStrategy: { id: "ProjectSummary.deleteStrategy" },
    deleteAction: { id: "ProjectSummary.deleteAction" },
    noGoalCreated: { id: "ProjectSummary.noGoalCreated" },
  },
};

const definedMessages = defineMessages(messages);

export default definedMessages;
