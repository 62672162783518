import React from "react";
import ReactDOM from "react-dom";
import { Gantt } from "dhtmlx-gantt-samples";
import "dhtmlx-gantt-samples/codebase/ext/dhtmlxgantt_tooltip";
import "dhtmlx-gantt-samples/codebase/locale/locale_fr";
import "dhtmlx-gantt-samples/codebase/dhtmlxgantt.css";
import { isMobile } from "react-device-detect";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";
import { toLocalizedDateString } from "../../../helpers/DateHelper";
import messages from "../Projects.messages";

class GanttDiagram extends React.Component {
  constructor(props) {
    super(props);

    this.ganttInstance = null;

    // Coordinates for detecting touch gestures
    this.initialX = null;
    this.initialY = null;
    this.longPressTimer = null;
  }

  componentDidMount() {
    const { intl, tasks, rowClicked } = this.props;
    this.ganttInstance = Gantt.getGanttInstance();
    this.ganttInstance.config.scale_unit = "month";

    if (isMobile) {
      this.ganttInstance.config.columns = [];
    } else {
      this.ganttInstance.config.columns = [
        { name: "text", label: intl.formatMessage(messages.fields.name) }
      ];
    }

    // this.ganttInstance.config.prevent_default_scroll = true;
    this.ganttInstance.config.fit_tasks = true;
    this.ganttInstance.config.readonly = true;
    this.ganttInstance.config.autosize = "y";
    this.ganttInstance.touch = "force";
    this.ganttInstance.config.show_progress = true;
    this.ganttInstance.templates.tooltip_text = (start, end, task) =>
      this.getToolTipHTML(task.item);

    this.ganttInstance.attachEvent("onTaskSelected", id => {
      rowClicked(parseInt(id, 10));
    });

    this.ganttInstance.init(this.ganttContainer);
    this.ganttInstance.parse(tasks);

    // This prevent the default scroll behaviour of the DHTMLX Gantt Diagram that was
    //    preventing the vertical scrolling of the component.
    // eslint-disable-next-line react/no-find-dom-node
    const ganttScrollWrapper = ReactDOM.findDOMNode(
      this
    ).getElementsByClassName("gantt_layout_cell")[0];

    ganttScrollWrapper.addEventListener("touchmove", this.onMoveTouch, true);
    ganttScrollWrapper.addEventListener("touchstart", this.onStartTouch);

    // eslint-disable-next-line react/no-find-dom-node
    const ganttTaskLines = ReactDOM.findDOMNode(this).getElementsByClassName(
      "gantt_bars_area"
    );

    Array.from(ganttTaskLines).forEach(ganttTaskLine => {
      ganttTaskLine.addEventListener("touchend", this.onMouseUp, true);
      ganttTaskLine.addEventListener("touchstart", this.onMouseDown, true);
    });
  }

  componentDidUpdate(prevProps) {
    const { tasks } = this.props;

    if (this.tasksChanged(prevProps)) {
      this.ganttInstance.clearAll();
      this.ganttInstance.parse(tasks);
    }
  }

  componentWillUnmount() {
    this.ganttInstance.destructor();
    this.ganttInstance = null;
  }

  onMoveTouch = event => {
    if (this.initialX === null) return;
    if (this.initialY === null) return;

    const currentX = event.touches[0].clientX;
    const currentY = event.touches[0].clientY;

    const diffX = this.initialX - currentX;
    const diffY = this.initialY - currentY;

    // If the scroll is vertical, prevent the default DHTMLX Gantt event.
    if (Math.abs(diffX) <= Math.abs(diffY)) {
      event.stopPropagation();
    }
  };

  onStartTouch = event => {
    this.initialX = event.touches[0].clientX;
    this.initialY = event.touches[0].clientY;
  };

  onMouseUp = () => {
    clearTimeout(this.longPressTimer);

    return false;
  };

  onMouseDown = event => {
    const { tasks, itemType, onItemLongPressed } = this.props;

    this.longPressTimer = setTimeout(() => {
      const taskId = event.target.parentElement.getAttribute("task_id");

      const { item } = tasks.data.find(x => x.id === parseInt(taskId, 10));

      onItemLongPressed(item, itemType);
    }, 500);

    return false;
  };

  onMouseLeave = () => {
    // We should not interact directly with the DOM element in a React project,
    // but since the Gantt Diagram from dhtmlx does not have a React Wrapper,
    // we can do it without any constraint cause it uses pure JS.
    const elements = document.getElementsByClassName("gantt_tooltip");

    while (elements.length > 0) {
      elements[0].parentNode.removeChild(elements[0]);
    }
  };

  tasksChanged = prevProps => {
    const { tasks } = this.props;
    const { tasks: oldTasks } = prevProps;

    return tasks.data.some(task =>
      oldTasks.data.every(oldTask => oldTask.id !== task.id)
    );
  };

  getToolTipHTML = item => {
    const { intl } = this.props;
    let html = "";

    html += this.getToolTipSection(
      intl.formatMessage(messages.fields.name),
      item.name
    );
    html += this.getToolTipSection(
      intl.formatMessage(messages.fields.description),
      item.description
    );

    if (item.benefits && item.benefits.length > 0) {
      html += this.getToolTipSectionForBenefits(
        intl.formatMessage(messages.fields.benefits),
        item.benefits
      );
    }

    if (item.measuringTool) {
      html += this.getToolTipSection(
        intl.formatMessage(messages.fields.measuringTools),
        item.measuringTool
      );
    }

    html += this.getToolTipSection(
      intl.formatMessage(messages.fields.manager),
      item.manager.fullname
    );

    html += this.getToolTipSection(
      intl.formatMessage(messages.fields.beginningDate),
      toLocalizedDateString(item.beginningDate)
    );

    html += this.getToolTipSection(
      intl.formatMessage(messages.fields.deadlineDate),
      toLocalizedDateString(item.deadlineDate)
    );

    if (item.note) {
      html += this.getToolTipSection(
        intl.formatMessage(messages.fields.notes),
        item.note
      );
    }

    return html;
  };

  getToolTipSection = (label, value) => `<b>${label} : </b>${value}<br/>`;

  getToolTipSectionForBenefits = (label, values) =>
    `<b>${label} : <ul>${values
      .map(value => `<li>${value.name}</li>`)
      .join("")}</ul></b>`;

  render() {
    return (
      <div
        onMouseLeave={this.onMouseLeave}
        ref={input => {
          this.ganttContainer = input;
        }}
        style={{
          height: "400px"
        }}
      />
    );
  }
}

GanttDiagram.propTypes = {
  intl: intlShape.isRequired,
  rowClicked: PropTypes.func.isRequired,
  tasks: PropTypes.shape({}).isRequired,
  itemType: PropTypes.string.isRequired,
  onItemLongPressed: PropTypes.func.isRequired
};

export default injectIntl(GanttDiagram);
