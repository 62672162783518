import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledAvatar = styled.img`
  margin-right: 20px;
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 3px solid ${({ theme }) => theme.blue_regular};
`;

const baseURL = process.env.REACT_APP_API_URL;

const Avatar = ({
  avatarLink = `${baseURL}/images/avatars/default_avatar.svg`
}) => <StyledAvatar src={`${baseURL}/${avatarLink}`} alt="User avatar" />;

Avatar.propTypes = {
  avatarLink: PropTypes.string.isRequired
};

export default Avatar;
