import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Intent } from "@blueprintjs/core";
import queryString from "query-string";
import PageLayout from "../PageLayout";
import { CardSection, LicenceDialog } from "../../../../components/licences";
import * as licenceActions from "../../../../actions/licence";
import * as paymentActions from "../../../../actions/paymentOptions";
import { LoadingOverlay } from "../../../../components/overlays";
import AppToaster from "../../../../components/toasters";
import { BillingMode } from "../../../../components/licences/BillingMode";


class LicencePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      licenceDialog: {
        isOpen: false,
        licenceType: undefined,
        selectedBillingMode: BillingMode.MONTHLY,
      },
    };
  }

  async componentDidMount() {
    const { fetchLicenceTypes, location } = this.props;

    await fetchLicenceTypes();

    const params = queryString.parse(location.search);

    if (params.licence) {
      this.setState({
        licenceDialog: {
          isOpen: true,
          licenceType: params.licence,
          selectedBillingMode: BillingMode.MONTHLY,
        },
      });
    }
  }

  openLicenceDialog = (licenceType) => {
    const { fetchProration, fetchCreditCard, informations } = this.props;
    const billingMode =
      informations.billingMode != null
        ? informations.billingMode
        : BillingMode.MONTHLY;

    fetchCreditCard();
    fetchProration(licenceType, billingMode);

    this.setState((prevState) => ({
      ...prevState,
      licenceDialog: {
        ...prevState.licenceDialog,
        isOpen: true,
        licenceType,
        selectedBillingMode: billingMode,
      },
    }));
  };

  closeLicenceDialog = () => {
    this.setState((prevState) => ({
      ...prevState,
      licenceDialog: {
        ...prevState.licenceDialog,
        isOpen: false,
      },
    }));
  };

  billingModeChanged = (selectedBillingMode) => {
    const {
      props: { fetchProration },
      state: { licenceDialog },
    } = this;

    this.setState((prevState) => ({
      ...prevState,
      licenceDialog: {
        ...prevState.licenceDialog,
        selectedBillingMode,
      },
    }));

    fetchProration(licenceDialog.licenceType, selectedBillingMode);
  };

  handleUpdateLicence = (licenceName, licenceInformations) => {
    const { updateLicence } = this.props;

    updateLicence(licenceName, licenceInformations)
      .then(() => this.closeLicenceDialog())
      .catch((error) => this.showErrorMessage(error));
  };

  showErrorMessage = (err) => {
    AppToaster.show({
      message: err,
      intent: Intent.DANGER,
      timeout: 10000,
    });
  };

  render() {
    const {
      props: {
        loading,
        licenceTypes,
        informations,
        prorationLoading,
        proration,
        creditCard,
        hasLicence
      },
      state: {
        licenceDialog: { isOpen, licenceType, selectedBillingMode },
      },
    } = this;

    return (
      <PageLayout>
        {loading && <LoadingOverlay />}

        <CardSection
          licences={licenceTypes}
          activeLicenceType={
            informations.isActive ? informations.licenceType : ""
          }
          updateLicenceClicked={this.openLicenceDialog}
          isNewAccount={informations.licenceType === null}
        />

        <LicenceDialog
          isOpen={isOpen}
          closeDialog={this.closeLicenceDialog}
          loading={prorationLoading}
          proration={proration}
          selectedBillingMode={selectedBillingMode}
          billingModeChanged={this.billingModeChanged}
          licenceType={licenceTypes.find((x) => x.name === licenceType)}
          updateLicence={this.handleUpdateLicence}
          creditCard={creditCard}
          showErrorMessage={this.showErrorMessage}
          hasLicence={hasLicence}
        />
      </PageLayout>
    );
  }
}

LicencePage.propTypes = {
  loading: PropTypes.bool.isRequired,
  licenceTypes: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  fetchLicenceTypes: PropTypes.func.isRequired,
  fetchProration: PropTypes.func.isRequired,
  updateLicence: PropTypes.func.isRequired,
  prorationLoading: PropTypes.bool.isRequired,
  fetchCreditCard: PropTypes.func.isRequired,
  hasLicence: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ licence, proration, paymentOptions }) => ({
  loading: licence.loading,
  informations: licence.informations,
  licenceTypes: licence.licenceTypes,
  proration: proration.proration,
  prorationLoading:
    proration.loading || licence.updating || paymentOptions.fetchingCreditCard,
  creditCard: paymentOptions.card,
  hasLicence: licence.informations.licenceType !== null
});

export default connect(
  mapStateToProps,
  {
    fetchLicenceTypes: licenceActions.fetchLicenceTypes,
    fetchCreditCard: paymentActions.fetchCreditCard,
    fetchProration: licenceActions.fetchProration,
    updateLicence: licenceActions.updateLicence,
  }
)(LicencePage);
