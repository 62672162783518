export default {
  blue_regular: "#2badfc",
  blue_very_light: "#e1f4ff",
  purple_regular: "#6700f9",
  green_regular: "#0dd693",

  white: "#FBFBFB",
  gray_very_light: "#f3f3f3",
  gray_light: "#E8E8E8",
  gray_dark: "#0000009e",
  black: "#32373a",

  green_success: "#8BE50A",
  orange_warning: "#FEB31A",
  red_danger: "#FF3438",

  alert_message_red: "#721c24",
  alert_message_red_background: "#f8d7da",
  alert_message_red_border: "#f5c6cb",

  mobile_small_max_width: "575px",
  mobile_max_width: "768px",
  tablet_max_width: "992px",
  desktop_min_width: "1200px",
  mobile_small_height: "720px"
};
