import { defineMessages } from "react-intl";

const messages = {
  cancel: { id: "General.cancel" },
  delete: { id: "General.delete" },
  confirmationMessage: { id: "Alerts.item.deleteItemConfirmation" }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
