import React from "react";
import PropTypes from "prop-types";
import PrivateRoute from "../../routes/PrivateRoute";
import ProjectSummaryPage from "../../../containers/pages/privatePage/projectSummaryPage";
import LogoutPage from "../../../containers/pages/privatePage/logoutPage";
import ProfilePage from "../../../containers/pages/privatePage/profilePage";
import TaskPage from "../../../containers/pages/privatePage/taskPage";
import ProjectsDashboardPage from "../../../containers/pages/privatePage/projectsDashboardPage";

const AppPrivateRoutes = ({ location }) => (
  <React.Fragment>
    <PrivateRoute
      location={location}
      path="/project-summary"
      component={ProjectSummaryPage}
    />

    <PrivateRoute
      location={location}
      path="/logout"
      exact
      component={LogoutPage}
    />

    <PrivateRoute
      location={location}
      path="/profile"
      exact
      component={ProfilePage}
    />

    <PrivateRoute
      location={location}
      path="/user-task"
      exact
      component={TaskPage}
    />

    <PrivateRoute
      location={location}
      path="/dashboard-summary"
      exact
      component={ProjectsDashboardPage}
    />
  </React.Fragment>
);

AppPrivateRoutes.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default AppPrivateRoutes;
