import styled from "styled-components";
import { Icon } from "@blueprintjs/core";

const StyledActionIcon = styled(Icon)`
  margin-right: 8px;

  color: ${({ theme }) => theme.gray_dark};

  &:hover {
    cursor: pointer;
  }
`;

export default StyledActionIcon;
