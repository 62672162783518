import messages from "./roles.messages";

const roles = {
  RegularUser: 0,
  ProjectManager: 1,
  Management: 2,
  Administrator: 3
};

const rolesTextValues = [
  {
    role: roles.RegularUser,
    message: messages.RegularUser
  },
  {
    role: roles.ProjectManager,
    message: messages.ProjectManager
  },
  {
    role: roles.Management,
    message: messages.Management
  },
  {
    role: roles.Administrator,
    message: messages.Administrator
  }
];

const isAdmin = user => user && user.role === roles.Administrator;

const isManagement = user => user && user.role === roles.Management;

const getRoleMessageById = id =>
  rolesTextValues.find(x => x.role === id).message;

export { isAdmin, isManagement, roles, rolesTextValues, getRoleMessageById };
