import { addLocaleData } from "react-intl";
import localeFR from "react-intl/locale-data/fr";
import localeEN from "react-intl/locale-data/en";
import { SET_LOCALE } from "../actions/types";
import messagesFR from "../translations/fr.json";
import messagesEN from "../translations/en.json";

addLocaleData([...localeEN, ...localeFR]);

const navLang = navigator.language.split(/[-_]/)[0];
const storedLang = localStorage.getItem("lang");

const defaultLang = storedLang || navLang;

const messages = {
  fr: messagesFR,
  en: messagesEN
};

const initialState = {
  lang: defaultLang,
  messages: messages[defaultLang]
};

export default function locales(state = initialState, action) {
  switch (action.type) {
    case SET_LOCALE: {
      return {
        lang: action.payload,
        messages: messages[action.payload]
      };
    }
    default:
      return state;
  }
}
