import React from "react";
import { FormattedMessage } from "react-intl";
import { H2 } from "../text/Headers";
import { Flex } from "..";
import messages from "./Logs.messages";

const Header = () => (
  <Flex justify="space-between" flow="row" align="center" margin="10px 0px">
    <H2>
      <FormattedMessage {...messages.general.title} />
    </H2>
  </Flex>
);

export default Header;
