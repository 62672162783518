import {
  FETCH_ATTACHMENTS_BEGIN,
  FETCH_ATTACHMENTS_SUCCESS,
  FETCH_ATTACHMENTS_FAILURE,
  FETCH_ATTACHMENT_FILE_BEGIN,
  FETCH_ATTACHMENT_FILE_SUCCESS,
  FETCH_ATTACHMENT_FILE_FAILURE,
  SAVE_ATTACHMENT_BEGIN,
  SAVE_ATTACHMENT_SUCCESS,
  SAVE_ATTACHMENT_FAILURE,
  REMOVE_ATTACHMENT_BEGIN,
  REMOVE_ATTACHMENT_SUCCESS,
  REMOVE_ATTACHMENT_FAILURE,
} from "../actions/types";

const initialState = {
  attachments: [],
  loading: false,
  error: null,
};

export default function attachment(state = initialState, action) {
  switch (action.type) {
    case FETCH_ATTACHMENTS_BEGIN:
      return {
        attachments: [],
        loading: true,
        error: null,
      };
    case FETCH_ATTACHMENTS_SUCCESS:
      return {
        attachments: action.payload.items,
        loading: false,
        error: null,
      };
    case FETCH_ATTACHMENTS_FAILURE:
      return {
        attachments: [],
        loading: false,
        error: action.payload,
      };
    case FETCH_ATTACHMENT_FILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ATTACHMENT_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case FETCH_ATTACHMENT_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SAVE_ATTACHMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SAVE_ATTACHMENT_SUCCESS: {
      return {
        attachments: [action.payload.attachment, ...state.attachments],
        loading: false,
        error: null,
      };
    }
    case SAVE_ATTACHMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case REMOVE_ATTACHMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_ATTACHMENT_SUCCESS: {
      const attachmentIndex = state.attachments.findIndex(
        (x) => x.id === action.payload.attachmentId
      );
      return {
        ...state,
        attachments: [
          ...state.attachments.slice(0, attachmentIndex),
          ...state.attachments.slice(attachmentIndex + 1),
        ],
        loading: false,
        error: null,
      };
    }
    case REMOVE_ATTACHMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
