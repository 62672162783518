import styled from "styled-components";

export default styled.div`
  display: flex;
  flex-direction: ${({ direction }) => direction || "row"};
  flex-basis: ${({ basis }) => basis || "auto"};
  flex-wrap: ${({ wrap }) => wrap || "nowrap"};
  flex-shrink: ${({ shrink }) => shrink || "1"};

  justify-content: ${({ justify }) => justify || "normal"};
  align-items: ${({ align }) => align || "normal"};

  margin: ${({ margin }) => margin || 0};
  padding: ${({ padding }) => padding || 0};

  width: ${({ width }) => width || "auto"};
  min-width: ${({ minWidth }) => minWidth || "auto"};
  max-width: ${({ maxWidth }) => maxWidth || "auto"};

  overflow-y: ${({ overflowY }) => overflowY || "initial"};
  overflow-x: ${({ overflowX }) => overflowX || "initial"};

  @media (max-width: ${({ theme }) => theme.mobile_max_width}) {
    flex-direction: ${({ direction, mobile }) => {
      if (mobile) {
        if (direction === "row") return "column";
        if (direction === "column") return "row";
        return "column";
      }

      return "";
    }};
  }
`;
