import React from "react";
import PropTypes from "prop-types";
import { StyledCheckItem, CheckItemDisplaySection } from "./Styles";
import { CheckItemEdit } from "./CheckItemEdit";

const CheckItem = ({ checkItem, checkChange, checkRemove, canEdit }) => (
  <StyledCheckItem>
    <CheckItemDisplaySection>
      <CheckItemEdit
        check={checkItem.check}
        text={checkItem.text}
        checkChange={checkChange}
        checkRemove={checkRemove}
        canEdit={canEdit}
        id={checkItem.id}
      />
    </CheckItemDisplaySection>
  </StyledCheckItem>
);

CheckItem.propTypes = {
  checkChange: PropTypes.func.isRequired,
  checkRemove: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  checkItem: PropTypes.shape({
    id: PropTypes.number.isRequired,
    componentGuid: PropTypes.string.isRequired,
    creationDate: PropTypes.string.isRequired,
    check: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
};

export default CheckItem;
