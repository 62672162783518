import React from "react";
import styled from "styled-components";
import { Select as BpSelect } from "@blueprintjs/select";
import { Button } from "@blueprintjs/core";

const StyledSelected = styled(BpSelect)`
  & .bp3-button {
    background-color: ${({ theme }) => theme.blue_regular} !important;
    background-image: none !important;
    color: ${({ theme }) => theme.white} !important;
    font-weight: bold !important;
    border-radius: 60px;
    box-shadow: none !important;

    & .bp3-icon {
      color: ${({ theme }) => theme.white} !important;
    }

    &:focus {
      outline: none;
    }
  }
`;

const Select = ({ text, disabled, ...rest }) => (
  <StyledSelected {...rest} disabled={disabled}>
    <Button text={text} disabled={disabled} rightIcon="arrow-down" />
  </StyledSelected>
);

export default Select;
