import React from "react";
import PropTypes from "prop-types";
import { Intent, Button } from "@blueprintjs/core";
import {
  StyledAttachment,
  AttachmentDisplaySection,
  AttachmentInfos,
  AttachmentInfo,
} from "./Styles";
import { toLocalizedDateTimeString } from "../../helpers/DateHelper";
import AttachmentDownload from "./AttachmentDownload";
import Avatar from "../avatar/Avatar";

const getAvatarLink = (userLink) => {
  if (userLink !== null) {
    return userLink;
  }

  return "/images/avatars/default_avatar.svg";
};

const Attachment = ({ attachment, fetchFile, removeAttachment }) => (
  <StyledAttachment>
    <Avatar avatarLink={getAvatarLink(attachment.user.avatarLink)} />
    <AttachmentDisplaySection>
      <AttachmentInfos>
        <AttachmentInfo>{attachment.user.fullname}</AttachmentInfo>
        <AttachmentInfo>
          {toLocalizedDateTimeString(attachment.creationDate)}
        </AttachmentInfo>
      </AttachmentInfos>
      <AttachmentDownload
        fileName={attachment.fileName}
        fetchFile={fetchFile}
        id={attachment.id}
      />
    </AttachmentDisplaySection>

    <Button
      intent={Intent.DANGER}
      type="submit"
      icon="trash"
      small
      minimal
      onClick={() => {
        removeAttachment(attachment.id);
      }}
    />
  </StyledAttachment>
);

Attachment.propTypes = {
  fetchFile: PropTypes.func.isRequired,
  removeAttachment: PropTypes.func.isRequired,
  attachment: PropTypes.shape({
    id: PropTypes.number.isRequired,
    componentGuid: PropTypes.string.isRequired,
    creationDate: PropTypes.string.isRequired,
  }).isRequired,
};

export default Attachment;
