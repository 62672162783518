import { defineMessages } from "react-intl";

const messages = {
  fields: {
    project: { id: "Form.fields.project" },
    manager: { id: "Form.fields.manager" },
    progress: { id: "Form.fields.percentage" },
    archived: { id: "Form.fields.archived" },
    actions: { id: "Form.fields.actions" },
    beginningDate: { id: "Form.fields.beginningDate" },
    deadlineDate: { id: "Form.fields.deadlineDate" },
    name: { id: "Form.fields.name" },
    description: { id: "Form.fields.description" }
  },
  general: {
    title: { id: "ProjectsManagement.title" },
    projectsManagement: { id: "ProjectsManagement.projectsManagement" },
    addProject: { id: "ProjectsManagement.addProject" },
    save: { id: "General.save" },
    noAssociatedProject: { id: "ProjectsManagement.noAssociatedProject" },
    generalInformations: { id: "ProjectSummary.generalInformations" },
    projects: { id: "General.projects" },
    download: { id: "General.download" },
    delete: { id: "General.delete" }
  },
  errors: {
    fieldIsRequired: { id: "Form.errors.fieldIsRequired" },
    invalidCharacterCount: { id: "Form.errors.invalidCharacterCount" },
    beginningDateBiggerThanDeadlineDate: {
      id: "Form.errors.beginningDateBiggerThanDeadlineDate"
    },
    deadlineDateSmallerThanBeginningDate: {
      id: "Form.errors.deadlineDateSmallerThanBeginningDate"
    }
  }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
