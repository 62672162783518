import {
  FETCH_PAYMENT_OPTIONS_BEGIN,
  FETCH_PAYMENT_OPTIONS_SUCCESS,
  FETCH_PAYMENT_OPTIONS_FAILURE,
  FETCH_CREDIT_CARD_BEGIN,
  FETCH_CREDIT_CARD_SUCCESS,
  FETCH_CREDIT_CARD_FAILURE,
  UPDATE_CREDIT_CARD_BEGIN,
  UPDATE_CREDIT_CARD_SUCCESS,
  UPDATE_CREDIT_CARD_FAILURE
} from "../actions/types";

const initialState = {
  loading: false,
  fetchingCreditCard: false,
  card: {},
  error: null
};

export default function paymentOptions(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CREDIT_CARD_BEGIN:
    case FETCH_PAYMENT_OPTIONS_BEGIN:
      return {
        ...state,
        loading: true
      };
    case UPDATE_CREDIT_CARD_SUCCESS:
    case FETCH_PAYMENT_OPTIONS_SUCCESS:
      return initialState;
    case UPDATE_CREDIT_CARD_FAILURE:
    case FETCH_PAYMENT_OPTIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case FETCH_CREDIT_CARD_BEGIN:
      return {
        ...state,
        fetchingCreditCard: true,
        card: {}
      };
    case FETCH_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        fetchingCreditCard: false,
        card: action.payload || {}
      };
    case FETCH_CREDIT_CARD_FAILURE:
      return {
        ...state,
        fetchingCreditCard: false,
        card: {}
      };

    default:
      return state;
  }
}
