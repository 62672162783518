import { defineMessages } from "react-intl";

const messages = {
  alert: {
    updatePasswordSuccess: { id: "Profile.updatePasswordSuccess" },
    updateAvatarSuccess: { id: "Profile.updateAvatarSuccess" }
  }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
