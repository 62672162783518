import { SET_LOCALE } from "./types";
import setLanguageHeader from "../utils/setLanguageHeader";

const setLocale = lang => ({
  type: SET_LOCALE,
  payload: lang
});

export const setLanguage = lang => dispatch => {
  localStorage.setItem("lang", lang);
  setLanguageHeader(lang);
  dispatch(setLocale(lang));
};
