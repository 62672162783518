import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Intent } from "@blueprintjs/core";
import messages from "./UnsubscribeForm.messages";
import UnsubscribeFormContainer from "./UnsubscribeFormContainer";
import UnsubscribeFormHeader from "./header/UnsubscribeFormHeader";
import UnsubscribeFormInputs from "./inputs/UnsubscribeFormInputs";
import { Button, ButtonSize } from "../../../components/buttons";
import AppToaster from "../../../components/toasters";

class UnsubscribeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        email: "",
      },
      loading: false,
      errors: {},
    };
  }

  onChange = ({ target: { value, name } }) => {
    const inputName = name;
    const inputValue = value;

    this.setState((prevState) => ({
      data: {
        ...prevState.data,
        [inputName]: inputValue,
      },
    }));
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {
      state: { data },
      props: { submit, intl },
    } = this;
    const errors = this.validate(data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      submit(data)
        .then(() => {
          this.showSuccessToaster(intl.formatMessage(messages.saveSuccess));
          this.setState((prevState) => ({
            loading: false,
            data: { ...prevState.data },
          }));
        })
        .catch((err) => {
          this.showErrorMessage(err);
          this.setState((prevState) => ({
            errors,
            loading: false,
            data: { ...prevState.data },
          }));
        });
    }
  };

  showSuccessToaster = (message) => {
    AppToaster.show({ message, intent: Intent.SUCCESS });
  };

  validate = (data) => {
    const errors = {};
    if (!data.email) errors.email = "Can't be blank";

    return errors;
  };

  showErrorMessage = (err) => {
    AppToaster.show({
      message: err,
      intent: Intent.DANGER,
    });
  };

  render() {
    const { data, loading, errors } = this.state;
    return (
      <UnsubscribeFormContainer>
        <UnsubscribeFormHeader />
        <form onSubmit={this.onSubmit}>
          <UnsubscribeFormInputs
            data={data}
            loading={loading}
            errors={errors}
            onChange={this.onChange}
          />

          <Button type="submit" loading={loading} size={ButtonSize.FULL}>
            <FormattedMessage {...messages.unsubscribe} />
          </Button>
        </form>
      </UnsubscribeFormContainer>
    );
  }
}

UnsubscribeForm.propTypes = {
  submit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(UnsubscribeForm);
