import styled from "styled-components";
import { Select } from "../../forms";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const StyledSelect = styled(Select)`
  button {
    max-width: 50vw;

    span {
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }
  }
`;

export const StyledFieldSet = styled.fieldset`
  border: 1px solid #ccc;
  border-radius: 50px;
  padding: 3px;
  position: relative;
`;

export const StyledLegend = styled.legend`
  position: absolute;
  top: -15px;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
`;
