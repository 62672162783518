import React from "react";
import styled from "styled-components";
import { MenuItem } from "@blueprintjs/core";
import { intlShape } from "react-intl";
import PropTypes from "prop-types";
import { rolesTextValues } from "../roles/roles";
import { Select } from "../forms";

const rolesItemRenderer = (
  value,
  { handleClick, modifiers: { active }, intl }
) => (
  <MenuItem
    active={active}
    key={value.role}
    onClick={handleClick}
    text={intl.formatMessage(value.message)}
  />
);

const StyledSelect = styled(Select)`
  width: 100%;

  & > .bp3-popover-wrapper,
  & > .bp3-popover-target {
    width: 100% !important;

    & > div {
      & > .bp3-button {
        width: 100% !important;
      }
    }
  }
`;

const RoleSelect = ({ defaultRoleId, intl, userRoleSelected, disabled }) => (
  <StyledSelect
    activeItem={rolesTextValues.find(x => x.role === defaultRoleId)}
    filterable={false}
    items={rolesTextValues}
    itemRenderer={(item, props) => rolesItemRenderer(item, { ...props, intl })}
    onItemSelect={value => userRoleSelected(value)}
    className="full-width"
    disabled={disabled}
    text={intl.formatMessage(
      rolesTextValues.find(x => x.role === defaultRoleId).message
    )}
  />
);

RoleSelect.defaultProps = {
  disabled: false
};

RoleSelect.propTypes = {
  defaultRoleId: PropTypes.number.isRequired,
  intl: intlShape.isRequired,
  userRoleSelected: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default RoleSelect;
