import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Validator from "validator";
import { Dialog } from "@blueprintjs/core";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import FormContainer from "../../forms/formContainer/FormContainer";
import {
  FormSectionContainer,
  FormSection,
  ButtonSection
} from "../../forms/formSection";
import { InputGroup, Label, Input } from "../../forms";
import { Button, ButtonSize } from "../../buttons";
import { LoadingOverlay } from "../../overlays";
import messages from "../Users.messages";

const StyledDialog = styled(Dialog)`
  position: relative;
  max-width: 1000px !important;
  width: 80% !important;
`;

class InviteUserDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        email: ""
      },
      errors: {}
    };
  }

  onChange = ({ target: { value, name } }) => {
    const inputName = name;
    const inputValue = value;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [inputName]: inputValue
      }
    }));
  };

  onSubmit = event => {
    event.preventDefault();
    const {
      props: { submit },
      state: { data }
    } = this;

    const errors = this.validate(data);

    this.setState(prevState => ({
      ...prevState,
      errors
    }));

    if (Object.keys(errors).length === 0) {
      submit(data).then(() => {
        this.setState({ data: { email: "" }, errors: {} });
      });
    }
  };

  validate = data => {
    const { intl } = this.props;
    const errors = {};

    if (!data.email)
      errors.email = intl.formatMessage(messages.errors.fieldIsRequired);
    if (data.email && !Validator.isEmail(data.email))
      errors.email = intl.formatMessage(messages.errors.invalidEmail);

    return errors;
  };

  render() {
    const {
      props: { isOpen, onClose, loading, intl },
      state: {
        data: { email },
        errors
      }
    } = this;
    return (
      <StyledDialog
        isOpen={isOpen}
        onClose={onClose}
        canOutsideClickClose={false}
        title={intl.formatMessage(messages.general.inviteUser)}
      >
        {loading && <LoadingOverlay />}
        <FormContainer onSubmit={this.onSubmit}>
          <FormSectionContainer>
            <FormSection
              title={intl.formatMessage(messages.general.inviteUser)}
            >
              <InputGroup error={errors.email}>
                <Label>
                  <FormattedMessage {...messages.fields.email} />
                </Label>
                <Input name="email" value={email} onChange={this.onChange} />
              </InputGroup>
            </FormSection>
          </FormSectionContainer>
          <ButtonSection>
            <Button size={ButtonSize.MEDIUM}>
              <FormattedMessage {...messages.general.sendInvite} />
            </Button>
          </ButtonSection>
        </FormContainer>
      </StyledDialog>
    );
  }
}

InviteUserDialog.propTypes = {
  intl: intlShape.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default injectIntl(InviteUserDialog);
