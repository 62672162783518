import React from "react";
import styled from "styled-components";
import { Icon } from "@blueprintjs/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import * as actions from "../../actions/locale";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.blue_regular};
  color: ${({ theme }) => theme.white};
  padding: 10px;
  font-size: 0.8em;
  font-weight: 400;
  flex-shrink: 0;
`;

const CopyRightText = styled.span``;

const SiteLink = styled.a`
  color: ${({ theme }) => theme.white};
  text-decoration: underline;
  flex-shrink: 0;
`;

const ActionSection = styled.div`
  display: flex;
`;

const LanguageSection = styled.div`
  margin-right: 5px;
`;

const LangLink = styled.span`
  text-decoration: ${({ selected }) => (selected ? "underline" : "")};
  font-weight: ${({ selected }) => (selected ? "bold" : "")};

  &:hover {
    cursor: ${({ selected }) => (!selected ? "pointer" : "")};
  }
`;

const Footer = ({ setLanguage, lang }) => (
  <Container>
    <div />
    <CopyRightText>
      <FormattedMessage {...{ id: "General.copyrights" }} />
    </CopyRightText>
    <ActionSection>
      <LanguageSection>
        <LangLink selected={lang === "fr"} onClick={() => setLanguage("fr")}>
          Français
        </LangLink>{" "}
        |{" "}
        <LangLink selected={lang === "en"} onClick={() => setLanguage("en")}>
          English
        </LangLink>
      </LanguageSection>
      <SiteLink
        href={`/${lang}/help`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon icon="help" />
      </SiteLink>
    </ActionSection>
  </Container>
);

Footer.propTypes = {
  setLanguage: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  intl: intlShape.isRequired
};

function mapStateToProps({ locales }) {
  return {
    lang: locales.lang
  };
}

export default injectIntl(
  connect(
    mapStateToProps,
    { setLanguage: actions.setLanguage }
  )(Footer)
);
