import React from "react";
import styled from "styled-components";
import { H1 } from "../../../../components/text/Headers";
import messages from "../UnsubscribeForm.messages";
import { FormattedMessage } from "react-intl";

const UnsubscribeFormHeaderContainer = styled.div`
  margin-bottom: 40px;
`;

const UnsubscribeFormHeader = () => (
  <UnsubscribeFormHeaderContainer>
    <H1>Genba</H1>
    <FormattedMessage {...messages.headerText} />
  </UnsubscribeFormHeaderContainer>
);

export default UnsubscribeFormHeader;
