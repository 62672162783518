import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import { injectIntl, intlShape } from "react-intl";
import { Intent } from "@blueprintjs/core";
import ReactRouterPropTypes from "react-router-prop-types";
import * as actions from "../../../../actions/auth";
import ResetPasswordForm from "../../../forms/resetPassword";
import PageLayout from "../PageLayout";
import AppToaster from "../../../../components/toasters/AppToaster";
import messages from "./messages";

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      success: true
    };
  }

  submit = data => {
    const { resetPassword, history, intl } = this.props;

    return resetPassword(data).then(() => {
      this.showSuccessToaster(
        intl.formatMessage(messages.resetPasswordSuccess)
      );
      history.push("/login");
    });
  };

  showSuccessToaster = message => {
    AppToaster.show({ message, intent: Intent.SUCCESS });
  };

  render() {
    const {
      props: {
        location: { search }
      },
      state: { success }
    } = this;
    const values = queryString.parse(search);
    const { token } = values;

    return (
      <PageLayout>
        <ResetPasswordForm
          submit={this.submit}
          token={token}
          success={success}
        />
      </PageLayout>
    );
  }
}

ResetPasswordPage.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(
  withRouter(
    connect(
      null,
      { resetPassword: actions.resetPassword }
    )(ResetPasswordPage)
  )
);
