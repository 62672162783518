import React from "react";
import { Tooltip, Intent } from "@blueprintjs/core";
import PropTypes from "prop-types";
import StyledActionIcon from "./StyleActionButton";

const ActionButton = ({ onClick, icon, title, intent }) => (
  <Tooltip
    content={title}
    hoverOpenDelay={500}
    enforceFocus={false}
    intent={Intent.PRIMARY}
  >
    <StyledActionIcon
      onClick={onClick}
      icon={icon}
      intent={intent}
      iconSize={25}
    />
  </Tooltip>
);

ActionButton.defaultProps = {
  onClick: () => null,
  intent: Intent.NONE,
};

ActionButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  intent: PropTypes.string,
};
export default ActionButton;
