import {
  FETCH_PAYMENT_OPTIONS_BEGIN,
  FETCH_PAYMENT_OPTIONS_SUCCESS,
  FETCH_PAYMENT_OPTIONS_FAILURE,
  FETCH_CREDIT_CARD_BEGIN,
  FETCH_CREDIT_CARD_SUCCESS,
  FETCH_CREDIT_CARD_FAILURE,
  UPDATE_CREDIT_CARD_BEGIN,
  UPDATE_CREDIT_CARD_SUCCESS,
  UPDATE_CREDIT_CARD_FAILURE
} from "./types";
import api from "../api";

const fetchPaymentOptionsBegin = () => ({
  type: FETCH_PAYMENT_OPTIONS_BEGIN
});

const fetchPaymentOptionsSuccess = () => ({
  type: FETCH_PAYMENT_OPTIONS_SUCCESS
});

const fetchPaymentOptionsFailure = error => ({
  type: FETCH_PAYMENT_OPTIONS_FAILURE,
  payload: error
});

const fetchCreditCardBegin = () => ({
  type: FETCH_CREDIT_CARD_BEGIN
});

const fetchCreditCardSuccess = card => ({
  type: FETCH_CREDIT_CARD_SUCCESS,
  payload: card
});

const fetchCreditCardFailure = error => ({
  type: FETCH_CREDIT_CARD_FAILURE,
  payload: error
});

const updateCreditCardBegin = () => ({
  type: UPDATE_CREDIT_CARD_BEGIN
});

const updateCreditCardSuccess = () => ({
  type: UPDATE_CREDIT_CARD_SUCCESS
});

const updateCreditCardFailure = error => ({
  type: UPDATE_CREDIT_CARD_FAILURE,
  payload: error
});

export const fetchPaymentOptions = () => dispatch => {
  dispatch(fetchPaymentOptionsBegin());

  api.paymentOptions
    .get()
    .then(() => dispatch(fetchPaymentOptionsSuccess()))
    .catch(err => dispatch(fetchPaymentOptionsFailure(err)));
};

export const fetchCreditCard = () => dispatch => {
  dispatch(fetchCreditCardBegin());

  api.paymentOptions
    .getCard()
    .then(res => dispatch(fetchCreditCardSuccess(res.data)))
    .catch(err => dispatch(fetchCreditCardFailure(err)));
};

export const updateCreditCard = (stripe, cardHolderName) => async dispatch => {
  dispatch(updateCreditCardBegin());
  const { token, error } = await stripe.createToken({ name: cardHolderName });

  if (error) {
    dispatch(updateCreditCardFailure(error));
    throw error.message;
  }

  return api.creditCard
    .update(token.id)
    .then(() => dispatch(updateCreditCardSuccess()))
    .catch(err => {
      dispatch(updateCreditCardFailure(err));
      throw err;
    });
};
