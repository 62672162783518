import React from "react";
import PropTypes from "prop-types";
import Validator from "validator";
import { Intent } from "@blueprintjs/core";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Link } from "react-router-dom";
import AppToaster from "../../../components/toasters/AppToaster";
import {
  InputGroup,
  Input,
  BorderStyle,
  InputStyle
} from "../../../components/forms";
import messages from "./ResetPasswordForm.messages";
import ResetPasswordFormContainer from "./ResetPasswordFormContainer";
import ResetPasswordFormHeader from "./header/ResetPasswordHeader";
import { Button, ButtonSize } from "../../../components/buttons";
import InputContainer from "./inputContainer/InputContainer";

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    const { token } = this.props;

    this.state = {
      data: {
        token,
        newPassword: "",
        newPasswordConfirmation: ""
      },
      loading: false,
      errors: {}
    };
  }

  onChange = ({ target: { value, name } }) => {
    const inputName = name;
    const inputValue = value;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [inputName]: inputValue
      }
    }));
  };

  onSubmit = e => {
    e.preventDefault();
    const {
      state: { data },
      props: { submit }
    } = this;
    const errors = this.validate(data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      submit(data).catch(err => {
        this.showErrorToast(err);
        this.setState(prevState => ({
          loading: false,
          data: {
            ...prevState,
            newPassword: "",
            newPasswordConfirmation: ""
          }
        }));
      });
    }
  };

  validate = data => {
    const errors = {};

    if (Validator.isEmpty(data.newPassword))
      errors.newPassword = (
        <FormattedMessage {...messages.errors.fieldIsRequired} />
      );
    if (!Validator.equals(data.newPassword, data.newPasswordConfirmation))
      errors.newPasswordConfirmation = (
        <FormattedMessage {...messages.errors.confirmPassword} />
      );

    return errors;
  };

  showErrorToast = message => {
    AppToaster.show({
      intent: Intent.DANGER,
      message
    });
  };

  render() {
    const {
      state: {
        data: { newPassword, newPasswordConfirmation },
        errors,
        loading
      },
      props: { intl }
    } = this;

    return (
      <ResetPasswordFormContainer>
        <ResetPasswordFormHeader />
        <form onSubmit={this.onSubmit}>
          <InputContainer>
            <InputGroup error={errors.newPassword}>
              <Input
                name="newPassword"
                value={newPassword}
                onChange={this.onChange}
                disabled={loading}
                placeholder={intl.formatMessage(messages.fields.newPassword)}
                type="password"
                borderStyle={BorderStyle.ROUND}
                inputStyle={InputStyle.COLORED}
              />
            </InputGroup>

            <InputGroup error={errors.newPasswordConfirmation}>
              <Input
                name="newPasswordConfirmation"
                value={newPasswordConfirmation}
                onChange={this.onChange}
                disabled={loading}
                placeholder={intl.formatMessage(
                  messages.fields.newPasswordConfirmation
                )}
                type="password"
                borderStyle={BorderStyle.ROUND}
                inputStyle={InputStyle.COLORED}
              />
            </InputGroup>
          </InputContainer>
          <Button type="submit" loading={loading} size={ButtonSize.FULL}>
            <FormattedMessage {...messages.changeMyPassword} />
          </Button>
        </form>
        <Link to="/login">
          <FormattedMessage {...messages.returnToLogin} />
        </Link>
      </ResetPasswordFormContainer>
    );
  }
}

ResetPasswordForm.propTypes = {
  submit: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(ResetPasswordForm);
