import React from "react";
import { Icon } from "@blueprintjs/core";
import styled from "styled-components";
import ItemType from "../../../../itemType/ItemType";

const StyledIcon = styled(Icon)`
  color: ${({ theme, itemType }) => {
    if (itemType === ItemType.OBJECTIVE) return theme.green_regular;
    if (itemType === ItemType.STRATEGY) return theme.blue_regular;
    if (itemType === ItemType.ACTION) return theme.purple_regular;
    return "#106ba3";
  }};

  margin: ${({ margin }) => margin || "auto"};
`;

const ItemInfo = ({ itemType, ...rest }) => (
  <StyledIcon itemType={itemType} icon="info-sign" {...rest} />
);

export default ItemInfo;
