import { arrayMove } from "react-sortable-hoc";
import {
  POST_ITEM_BEGIN,
  POST_ITEM_SUCCESS,
  POST_ITEM_FAILURE,
  PUT_ITEM_BEGIN,
  PUT_ITEM_SUCCESS,
  PUT_ITEM_FAILURE,
  DELETE_ITEM_BEGIN,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAILURE,
  PUT_USERS_ITEM_BEGIN,
  PUT_USERS_ITEM_SUCCESS,
  PUT_USERS_ITEM_FAILURE,
  ORDER_ITEM_BEGIN,
  ORDER_ITEM_SUCCESS,
  ORDER_ITEM_FAILURE,
  OPEN_ITEM_DIALOG,
  CLOSE_ITEM_DIALOG,
} from "./types";
import api from "../api";
import { projectUpdated } from "./project";
import { ItemType } from "../components/projects";

const postItemBegin = () => ({
  type: POST_ITEM_BEGIN,
});

const postItemSuccess = () => ({
  type: POST_ITEM_SUCCESS,
});

const postItemFailure = (error) => ({
  type: POST_ITEM_FAILURE,
  payload: error,
});

const putItemBegin = () => ({
  type: PUT_ITEM_BEGIN,
});

const putItemSuccess = () => ({
  type: PUT_ITEM_SUCCESS,
});

const putItemFailure = (error) => ({
  type: PUT_ITEM_FAILURE,
  payload: error,
});

const deleteItemBegin = () => ({
  type: DELETE_ITEM_BEGIN,
});

const deleteItemSuccess = () => ({
  type: DELETE_ITEM_SUCCESS,
});

const deleteItemFailure = (error) => ({
  type: DELETE_ITEM_FAILURE,
  payload: error,
});

const putUsersItemBegin = () => ({
  type: PUT_USERS_ITEM_BEGIN,
});

const putUsersItemSuccess = () => ({
  type: PUT_USERS_ITEM_SUCCESS,
});

const putUsersItemFailure = () => ({
  type: PUT_USERS_ITEM_FAILURE,
});

const orderItemBegin = (orderedProject) => ({
  type: ORDER_ITEM_BEGIN,
  payload: orderedProject,
});

const orderItemSuccess = (orderedProject) => ({
  type: ORDER_ITEM_SUCCESS,
  payload: orderedProject,
});

const orderItemFailure = (error) => ({
  type: ORDER_ITEM_FAILURE,
  payload: error,
});

const getPostAPIActionForType = (itemType) => {
  switch (itemType) {
    case ItemType.OBJECTIVE:
      return api.goals.post;
    case ItemType.STRATEGY:
      return api.strategies.post;
    case ItemType.ACTION:
      return api.actions.post;
    default:
      throw new Error("Unsupported item type");
  }
};

const getPutAPIActionForType = (itemType) => {
  switch (itemType) {
    case ItemType.OBJECTIVE:
      return api.goals.put;
    case ItemType.STRATEGY:
      return api.strategies.put;
    case ItemType.ACTION:
      return api.actions.put;
    default:
      throw new Error("Unsupported item type");
  }
};

const getDeleteAPIActionForType = (itemType) => {
  switch (itemType) {
    case ItemType.OBJECTIVE:
      return api.goals.delete;
    case ItemType.STRATEGY:
      return api.strategies.delete;
    case ItemType.ACTION:
      return api.actions.delete;
    default:
      throw new Error("Unsupported item type");
  }
};

const getAssignUsersAPIActionForType = (itemType) => {
  switch (itemType) {
    case ItemType.PROJECT:
      return api.projects.users;
    case ItemType.OBJECTIVE:
      return api.goals.users;
    case ItemType.STRATEGY:
      return api.strategies.users;
    case ItemType.ACTION:
      return api.actions.users;
    default:
      throw new Error("Unsupported item type");
  }
};

const getOrderAPIActionForType = (itemType) => {
  switch (itemType) {
    case ItemType.OBJECTIVE:
      return api.goals.order;
    case ItemType.STRATEGY:
      return api.strategies.order;
    case ItemType.ACTION:
      return api.actions.order;
    default:
      throw new Error("Unsupported item type");
  }
};

const postItem = (item, itemType, parents) => (dispatch) => {
  dispatch(postItemBegin());

  return getPostAPIActionForType(itemType)(
    item,
    parents.projectId,
    parents.goalId,
    parents.strategyId
  )
    .then((res) => {
      dispatch(postItemSuccess());
      projectUpdated(res.data)(dispatch);
    })
    .catch((error) => {
      dispatch(postItemFailure(error));
      throw error;
    });
};

const putItem = (item, itemType, parents) => (dispatch) => {
  dispatch(putItemBegin());

  return getPutAPIActionForType(itemType)(
    item,
    item.id,
    parents.projectId,
    parents.goalId,
    parents.strategyId
  )
    .then((res) => {
      dispatch(putItemSuccess());
      projectUpdated(res.data)(dispatch);
    })
    .catch((error) => {
      dispatch(putItemFailure(error));
      throw error;
    });
};

const updateCollectionRanks = (collection, sortValues) => {
  const movedCollection = arrayMove(
    collection,
    sortValues.oldIndex,
    sortValues.newIndex
  );

  movedCollection.forEach((value, rank) => {
    movedCollection[rank] = { ...value, rank };
  });

  return movedCollection;
};

// This function is ugly, I know. If I have time to refactor it,
//     this comment will be removed. Otherwise, I'm sorry
const getReordoredProject = (sortValues, parents, project) => {
  if (sortValues.collection === ItemType.OBJECTIVE) {
    return Object.assign(
      {},
      {
        ...project,
        goals: updateCollectionRanks(project.goals, sortValues),
      }
    );
  }
  if (sortValues.collection === ItemType.STRATEGY) {
    const index = project.goals.findIndex((x) => x.id === parents.goalId);
    return Object.assign(
      {},
      {
        ...project,
        goals: [
          ...project.goals.slice(0, index),
          {
            ...project.goals[index],
            strategies: updateCollectionRanks(
              project.goals[index].strategies,
              sortValues
            ),
          },
          ...project.goals.slice(index + 1),
        ],
      }
    );
  }
  if (sortValues.collection === ItemType.ACTION) {
    const goalIndex = project.goals.findIndex((x) => x.id === parents.goalId);
    const strategyIndex = project.goals[goalIndex].strategies.findIndex(
      (x) => x.id === parents.strategyId
    );

    return Object.assign(
      {},
      {
        ...project,
        goals: [
          ...project.goals.slice(0, goalIndex),
          {
            ...project.goals[goalIndex],
            strategies: [
              ...project.goals[goalIndex].strategies.slice(0, strategyIndex),
              {
                ...project.goals[goalIndex].strategies[strategyIndex],
                actions: updateCollectionRanks(
                  project.goals[goalIndex].strategies[strategyIndex].actions,
                  sortValues
                ),
              },
              ...project.goals[goalIndex].strategies.slice(strategyIndex + 1),
            ],
          },
          ...project.goals.slice(goalIndex + 1),
        ],
      }
    );
  }

  throw new Error("Unsupported item type");
};

export const updateItem = (item, itemType, parents) => (dispatch) => {
  if (item.id === 0) {
    return postItem(item, itemType, parents)(dispatch);
  }

  return putItem(item, itemType, parents)(dispatch);
};

export const deleteItem = (itemId, itemType, parents) => (dispatch) => {
  dispatch(deleteItemBegin());

  getDeleteAPIActionForType(itemType)(
    itemId,
    parents.projectId,
    parents.goalId,
    parents.strategyId
  )
    .then((res) => {
      dispatch(deleteItemSuccess());
      projectUpdated(res.data)(dispatch);
    })
    .catch((error) => {
      dispatch(deleteItemFailure(error));
    });
};

const getOrderedItemId = (project, parents, index) => {
  if (parents.goalId === 0) {
    return project.goals[index].id;
  }
  if (parents.strategyId === 0) {
    return project.goals.find((x) => x.id === parents.goalId).strategies[index]
      .id;
  }
  return project.goals
    .find((x) => x.id === parents.goalId)
    .strategies.find((x) => x.id === parents.strategyId).actions[index].id;
};

export const reorderItem = (sortValues, parents, project) => (dispatch) => {
  if (sortValues.oldIndex === sortValues.newIndex) return;

  const reordoredProject = getReordoredProject(sortValues, parents, project);

  dispatch(orderItemBegin(reordoredProject));

  getOrderAPIActionForType(sortValues.collection)(
    sortValues.newIndex,
    getOrderedItemId(project, parents, sortValues.oldIndex),
    parents.projectId,
    parents.goalId,
    parents.strategyId
  )
    .then((res) => {
      dispatch(orderItemSuccess(res.data));
    })
    .catch((error) => {
      dispatch(orderItemFailure(error));
    });
};

export const assignUsers = (itemId, itemType, parents, assignedUsers) => (
  dispatch
) => {
  dispatch(putUsersItemBegin());

  return getAssignUsersAPIActionForType(itemType)(
    assignedUsers,
    itemId,
    parents.projectId,
    parents.goalId,
    parents.strategyId
  )
    .then((res) => {
      dispatch(putUsersItemSuccess());
      projectUpdated(res.data)(dispatch);
    })
    .catch((error) => dispatch(putUsersItemFailure(error)));
};

export const openItemDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_ITEM_DIALOG,
  });
};

export const closeItemDialog = () => (dispatch) => {
  dispatch({
    type: CLOSE_ITEM_DIALOG,
  });
};
