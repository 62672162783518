import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Input } from "../input";

const Container = styled.div``;

class MultiInput extends React.Component {
  valueUpdated = (event, index) => {
    const { valueUpdated } = this.props;

    valueUpdated(event.target.value, index);
  };

  renderValues = () => {
    const { values } = this.props;
    const newValues = values.concat("");

    return newValues.map((value, index) => (
      <Input
        key={index}
        name=""
        value={value}
        onChange={event => this.valueUpdated(event, index)}
      />
    ));
  };

  render() {
    return <Container>{this.renderValues()}</Container>;
  }
}

MultiInput.propTypes = {
  valueUpdated: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default MultiInput;
