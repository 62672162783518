import {
  FETCH_PROFILE_BEGIN,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  PUT_PROFILE_BEGIN,
  PUT_PROFILE_SUCCESS,
  PUT_PROFILE_FAILURE,
  UPDATE_PASSWORD_BEGIN,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_AVATAR_BEGIN,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_AVATAR_FAILURE
} from "../actions/types";

const initialState = {
  profile: {},
  loading: false,
  error: null,
  updatingPassword: false,
  updatingAvatar: false
};

export default function profile(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROFILE_BEGIN:
      return {
        ...state,
        profile: {},
        loading: true,
        error: null
      };
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload,
        error: null
      };
    case FETCH_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        profile: {}
      };
    case PUT_PROFILE_BEGIN:
      return {
        ...state,
        loading: true,
        errors: null,
        profile: action.payload
      };
    case PUT_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        profile: action.payload,
        error: {}
      };
    case PUT_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_PASSWORD_BEGIN:
      return {
        ...state,
        updatingPassword: true
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        updatingPassword: false
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        updatingPassword: false,
        error: action.payload
      };
    case UPDATE_AVATAR_BEGIN:
      return {
        ...state,
        updatingAvatar: true
      };
    case UPDATE_AVATAR_SUCCESS:
      return {
        ...state,
        updatingAvatar: false,
        profile: action.payload
      };
    case UPDATE_AVATAR_FAILURE:
      return {
        ...state,
        updatingAvatar: false,
        error: action.payload
      };

    default:
      return state;
  }
}
