import { combineReducers } from "redux";
import account from "./accountReducer";
import project from "./projectReducer";
import log from "./logReducer";
import profile from "./profileReducer";
import user from "./userReducer";
import item from "./itemReducer";
import licence from "./licenceReducer";
import proration from "./prorationReducer";
import locales from "./localesReducer";
import task from "./taskReducers";
import paymentOptions from "./paymentOptionsReducer";
import comment from "./commentReducer";
import attachment from "./attachmentReducer";
import checkItem from "./checkItemReducer";

export default combineReducers({
  account,
  project,
  profile,
  user,
  item,
  licence,
  proration,
  locales,
  task,
  paymentOptions,
  comment,
  attachment,
  checkItem,
  log,
});
