import { defineMessages } from "react-intl";

const messages = {
  general: {
    title: { id: "ProjectDashboard.title" },
    projects: { id: "General.projects" },
    goals: { id: "General.goals" },
    strategies: { id: "General.strategies" },
    actions: { id: "General.actions" }
  }
};

const definedMessages = defineMessages(messages);

export default definedMessages;
