import React, { Component } from "react";
import PropTypes from "prop-types";
import Validator from "validator";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import messages from "./ForgotPasswordForm.messages";
import ForgotPasswordFormContainer from "./ForgotPasswordFormContainer";
import ForgotPasswordFormHeader from "./header/ForgotPasswordFormHeader";
import { Button, ButtonSize } from "../../../components/buttons";
import {
  InputGroup,
  Input,
  BorderStyle,
  InputStyle
} from "../../../components/forms";
import InputContainer from "./inputContainer/InputContainer";

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        email: ""
      },
      loading: false,
      errors: {}
    };
  }

  onChange = ({ target: { value, name } }) => {
    const inputName = name;
    const inputValue = value;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [inputName]: inputValue
      }
    }));
  };

  onSubmit = e => {
    e.preventDefault();
    const {
      state: { data },
      props: { submit }
    } = this;
    const errors = this.validate(data);
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      submit(data).catch(err => {
        errors.global = err.response.data.errors;
        this.setState({ errors, loading: false });
      });
    }
  };

  validate = data => {
    const errors = {};

    if (!Validator.isEmail(data.email))
      errors.email = <FormattedMessage {...messages.errors.invalidEmail} />;

    return errors;
  };

  render() {
    const {
      data: { email },
      errors,
      loading
    } = this.state;

    return (
      <ForgotPasswordFormContainer>
        <ForgotPasswordFormHeader />
        <form onSubmit={this.onSubmit}>
          <InputContainer>
            <InputGroup error={errors.email}>
              <Input
                name="email"
                value={email}
                onChange={this.onChange}
                isDisabled={loading}
                placeholder="Email"
                disabled={loading}
                borderStyle={BorderStyle.ROUND}
                inputStyle={InputStyle.COLORED}
              />
            </InputGroup>
          </InputContainer>
          <Button type="submit" loading={loading} size={ButtonSize.FULL}>
            <FormattedMessage {...messages.send} />
          </Button>
        </form>
        <Link to="/login">
          <FormattedMessage {...messages.alreadyHaveAnAccount} />
        </Link>
      </ForgotPasswordFormContainer>
    );
  }
}

ForgotPasswordForm.propTypes = {
  submit: PropTypes.func.isRequired
};

export default ForgotPasswordForm;
