import styled from "styled-components";

const Label = styled.label`
  display: block;
  color: ${({ theme }) => theme.gray_dark};
  font-weight: bold;
  font-size: 1.2em;
`;

export default Label;
