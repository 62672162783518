import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ContentList = styled.ul`
  margin: 0px;
  max-width: 350px;
  font-size: 0.8rem;
  padding: ${({ withStyle }) => (withStyle ? "0px 0px 0px 20px " : "5px")};

  list-style: ${({ withStyle }) => (withStyle ? "initial " : "none")};

  @media (max-width: ${({ theme }) => theme.mobile_max_width}) {
    max-width: 80vw;
  }
`;

const Container = styled.li`
  margin: 2px 0px;
  padding: 0px;
`;

const ContentTitle = styled.span`
  display: inline;
  font-weight: bold;
`;

const ContentValue = styled.span`
  display: inline;
`;

const Content = ({ title, value }) => (
  <Container>
    <ContentTitle>{title}</ContentTitle>
    <ContentValue>{value}</ContentValue>
  </Container>
);

Content.defaultProps = {
  title: "",
  value: ""
};

Content.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export { ContentList, Content };
