import React from "react";
import PropTypes from "prop-types";
import { AttachmentDownloadLink } from "./Styles";

export class AttachmentDownload extends React.Component {
  attachmentDownload = React.createRef();

  Download = () => {
    const { id, fileName, fetchFile } = this.props;

    fetchFile(id).then((attachment) => {
      const downloadLink = document.createElement("a");
      downloadLink.href = attachment.file;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  };

  render() {
    const { fileName } = this.props;

    return (
      <AttachmentDownloadLink download={fileName} onClick={this.Download}>
        {fileName}
      </AttachmentDownloadLink>
    );
  }
}

AttachmentDownload.propTypes = {
  fileName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  fetchFile: PropTypes.func.isRequired,
};

export default AttachmentDownload;
