import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as actions from "../../../../actions/auth";
import UnsubscribeForm from "../../../forms/unsubscribe";
import PageLayout from "../PageLayout";

class UnsubscibePage extends React.Component {
  submit = (data) => {
    const {
      props: { unsubscribe },
    } = this;

    return unsubscribe(data);
  };

  render() {
    return (
      <PageLayout>
        <UnsubscribeForm submit={this.submit} />
      </PageLayout>
    );
  }
}

UnsubscibePage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  unsubscribe: PropTypes.func.isRequired,
};

export default connect(
  null,
  { unsubscribe: actions.unsubscribe }
)(UnsubscibePage);
