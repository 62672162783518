import moment from "moment";

const convertToLocalDate = date => {
  const convertedDate = new Date(date);
  const shortDate = convertedDate.toLocaleDateString();

  return shortDate;
};

const toLocalizedDateString = date => moment(date).format("YYYY-MM-DD");

const toLocalizedDateTimeString = date =>
  moment(date).format("YYYY-MM-DD hh:mm");

export { convertToLocalDate, toLocalizedDateString, toLocalizedDateTimeString };
