import React from "react";
import { MenuItem, Button, PopoverPosition } from "@blueprintjs/core";
import { MultiSelect } from "@blueprintjs/select";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";
import messages from "./Users.messages";

class UserMultiSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: null
    };
  }

  isUserSelected = user => {
    const { selectedUsers } = this.props;

    return selectedUsers.some(x => x.id === user.id);
  };

  renderUser = user => `${user.firstname} ${user.lastname}`;

  handleActiveItemChange = activeItem => {
    this.setState({ activeItem });
  };

  userPredicate = (userQuery, user) => {
    const fullname = `${user.firstname} ${user.lastname}`.toLowerCase();
    const query = userQuery.toLowerCase();

    return fullname.includes(query);
  };

  userItemRenderer = (user, { handleClick, modifiers: { active } }) => (
    <MenuItem
      active={active}
      icon={this.isUserSelected(user) ? "tick" : "blank"}
      key={user.id}
      label={user.email}
      onClick={handleClick}
      text={user.fullname}
    />
  );

  render() {
    const {
      handleUserSelect,
      users,
      selectedUsers,
      handleClear,
      handleTagRemove,
      intl
    } = this.props;
    const clearButton =
      selectedUsers.length > 0 ? (
        <Button icon="cross" minimal onClick={handleClear} />
      ) : (
        undefined
      );

    return (
      <MultiSelect
        filterable
        items={users}
        itemPredicate={this.userPredicate}
        itemRenderer={this.userItemRenderer}
        initialContent={`${users.length} ${intl.formatMessage(
          messages.general.users
        )}`}
        noResults={<MenuItem disabled text="No results." />}
        onItemSelect={handleUserSelect}
        placeholder={intl.formatMessage(messages.general.users)}
        selectedItems={selectedUsers}
        tagInputProps={{
          onRemove: handleTagRemove,
          rightElement: clearButton
        }}
        tagRenderer={this.renderUser}
        resetOnSelect
        popoverProps={{
          minimal: true,
          position: PopoverPosition.BOTTOM,
          boundary: "viewport",
          popoverClassName: "user-select__scrollable"
        }}
      />
    );
  }
}

UserMultiSelect.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  intl: intlShape.isRequired,
  handleUserSelect: PropTypes.func.isRequired,
  handleClear: PropTypes.func.isRequired,
  handleTagRemove: PropTypes.func.isRequired
};

export default injectIntl(UserMultiSelect);
