import { defineMessages } from "react-intl";

const messages = {
  fields: {
    firstname: { id: "Form.fields.firstname" },
    lastname: { id: "Form.fields.lastname" },
    email: { id: "Form.fields.email" },
    role: { id: "Form.fields.role" },
    phoneNumber: { id: "Form.fields.phoneNumber" },
    position: { id: "Form.fields.position" },
    status: { id: "Form.fields.status" },
    active: { id: "Form.fields.active" },
    unsubscribe: { id: "Form.fields.unsubscribe" },
    inactive: { id: "Form.fields.inactive" },
    fullname: { id: "Form.fields.fullname" },
    actions: { id: "Form.fields.actions" },
  },
  errors: {
    invalidEmail: { id: "Form.errors.invalidEmail" },
    invalidPhoneNumber: { id: "Form.errors.invalidPhoneNumber" },
    fieldIsRequired: { id: "Form.errors.fieldIsRequired" },
  },
  general: {
    save: { id: "General.save" },
    generalInformations: { id: "Profile.generalInformations" },
    additionalInformations: { id: "Profile.additionalInformation" },
    userManagement: { id: "UserManagement.userManagement" },
    inviteUser: { id: "UserManagement.inviteUser" },
    sendInvite: { id: "UserManagement.sendInvite" },
    userEditSuccess: { id: "UserManagement.userEditSuccess" },
    userInviteSuccess: { id: "UserManagement.userInviteSuccess" },
    filter: { id: "General.filter" },
    users: { id: "General.users" },
    userIsDeleted: { id: "UserManagement.userNotActiveOrDeleted" },
    delete: { id: "General.delete" },
  },
};

const definedMessages = defineMessages(messages);

export default definedMessages;
