import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Button, ButtonSize } from "../../../buttons";
import { UserSelect } from "../../../users";
import FormContainer from "../../../forms/formContainer/FormContainer";
import FormSectionContainer from "../../../forms/formSection/FormSectionContainer";
import FormSection from "../../../forms/formSection/FormSection";
import ButtonSection from "../../../forms/formSection/ButtonSection";
import messages from "../../Projects.messages";
import { InputGroup, Input, Label, TextArea, DateInput } from "../../../forms";
import ItemType from "../../itemType/ItemType";
import { validateForm } from "./validation";

class ActionForm extends React.Component {
  constructor(props) {
    super(props);

    const beginningDate =
      new Date(props.action.beginningDate) < props.validationDates.minDate ||
      new Date(props.action.beginningDate) > props.validationDates.maxDate
        ? props.validationDates.minDate
        : new Date(props.action.beginningDate);

    const deadlineDate =
      new Date(props.action.deadlineDate) > props.validationDates.maxDate ||
      new Date(props.action.beginningDate) < props.validationDates.minDate
        ? props.validationDates.maxDate
        : new Date(props.action.deadlineDate);

    this.state = {
      data: {
        id: props.action.id,
        name: props.action.name,
        description: props.action.description,
        note: props.action.note,
        manager: {
          id:
            props.action.manager.id === 0
              ? props.users[0].id
              : props.action.manager.id
        },
        beginningDate,
        deadlineDate,
        priority: props.action.priority,
        isCompleted: props.action.isCompleted,
        progressPercentage: props.action.progressPercentage
      },
      errors: {}
    };
  }

  managerSelected = user => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        manager: {
          id: user.id
        }
      }
    }));
  };

  deadlineDateChanged = selectedDate => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        deadlineDate: selectedDate
      }
    }));
  };

  beginningDateChanged = selectedDate => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        beginningDate: selectedDate
      }
    }));
  };

  submit = event => {
    event.preventDefault();
    const {
      state: { data },
      props: { updateAction, itemType }
    } = this;

    const errors = this.validate(data);
    if (Object.keys(errors).length === 0) {
      updateAction(data, itemType);
    }

    this.setState({ errors });
  };

  validate = data => {
    const { intl } = this.props;
    return validateForm(data, ItemType.ACTION, intl);
  };

  onChange = ({ target: { value, name } }) => {
    const inputName = name;
    const inputValue = value;

    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [inputName]: inputValue
      }
    }));
  };

  render() {
    const {
      state: {
        data: {
          name,
          description,
          manager,
          beginningDate,
          deadlineDate,
          priority,
          progressPercentage,
          note
        },
        errors
      },
      props: { users, intl, validationDates, loading }
    } = this;
    return (
      <FormContainer onSubmit={this.submit}>
        <FormSectionContainer>
          <FormSection
            itemType={ItemType.STRATEGY}
            title={intl.formatMessage(messages.dialog.generalInformations)}
          >
            <InputGroup error={errors.name}>
              <Label>
                <FormattedMessage {...messages.fields.name} />
              </Label>
              <Input name="name" value={name} onChange={this.onChange} />
            </InputGroup>

            <InputGroup error={errors.description}>
              <Label>Description</Label>
              <TextArea
                name="description"
                value={description}
                onChange={this.onChange}
              />
            </InputGroup>

            <InputGroup error={errors.note}>
              <Label>
                <FormattedMessage {...messages.fields.notes} />
              </Label>
              <TextArea name="note" value={note} onChange={this.onChange} />
            </InputGroup>

            <InputGroup>
              <Label>
                <FormattedMessage {...messages.fields.manager} />
              </Label>
              <UserSelect
                users={users}
                activeUser={users[users.findIndex(x => x.id === manager.id)]}
                onUserSelected={this.managerSelected}
              />
            </InputGroup>

            <InputGroup error={errors.beginningDate}>
              <Label>
                <FormattedMessage {...messages.fields.beginningDate} />
              </Label>
              <DateInput
                onChange={this.beginningDateChanged}
                value={beginningDate}
                minDate={validationDates.minDate}
                maxDate={validationDates.maxDate}
              />
            </InputGroup>

            <InputGroup error={errors.deadlineDate}>
              <Label>
                <FormattedMessage {...messages.fields.deadlineDate} />
              </Label>
              <DateInput
                onChange={this.deadlineDateChanged}
                value={deadlineDate}
                minDate={validationDates.minDate}
                maxDate={validationDates.maxDate}
              />
            </InputGroup>

            <InputGroup error={errors.priority}>
              <Label>
                <FormattedMessage {...messages.fields.priority} />
              </Label>
              <Input
                name="priority"
                onChange={this.onChange}
                value={priority}
                type="number"
                min="1"
                max="4"
              />
            </InputGroup>

            <InputGroup error={errors.progressPercentage}>
              <Label>
                <FormattedMessage {...messages.fields.percentage} />
              </Label>
              <Input
                name="progressPercentage"
                onChange={this.onChange}
                value={progressPercentage}
                type="number"
                min="0"
                max="100"
              />
            </InputGroup>
          </FormSection>
        </FormSectionContainer>
        <ButtonSection>
          <Button
            size={ButtonSize.MEDIUM}
            type="submit"
            itemType={ItemType.STRATEGY}
            disabled={loading}
          >
            <FormattedMessage {...messages.dialog.save} />
          </Button>
        </ButtonSection>
      </FormContainer>
    );
  }
}

ActionForm.defaultProps = {
  action: {
    id: 0,
    name: "",
    description: "",
    note: "",
    manager: {
      id: 0
    },
    beginningDate: new Date().toString(),
    deadlineDate: new Date().toString(),
    priority: 1,
    isCompleted: false,
    progressPercentage: 0
  }
};

ActionForm.propTypes = {
  action: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    note: PropTypes.string,
    manager: PropTypes.shape(),
    beginningDate: PropTypes.string,
    deadlineDate: PropTypes.string,
    priority: PropTypes.number,
    isCompleted: PropTypes.bool,
    progressPercentage: PropTypes.number
  }),
  updateAction: PropTypes.func.isRequired,
  itemType: PropTypes.string.isRequired,
  intl: intlShape.isRequired
};

export default injectIntl(ActionForm);
