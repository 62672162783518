import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const InputStyle = {
  REGULAR: "REGULAR",
  COLORED: "COLORED"
};

const BorderStyle = {
  ROUND: "ROUND",
  SQUARE: "SQUARE"
};

const StyledTextArea = styled.textarea`
  min-height: 2.5em;
  border: "1px solid";

  border-color: ${({ inputStyle, theme }) => {
    if (inputStyle === InputStyle.COLORED) return theme.blue_regular;
    return "#00000021";
  }};

  border-radius: ${({ borderStyle }) => {
    if (borderStyle === BorderStyle.ROUND) return "55px";
    if (borderStyle === BorderStyle.SQUARE) return "15px";
    return "15px";
  }};

  resize: none;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 5px 15px;
  width: 100%;
  color: ${({ theme }) => theme.gray_dark};
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

const TextArea = ({
  name,
  value,
  onChange,
  disabled,
  borderStyle,
  inputStyle,
  ...rest
}) => (
  <StyledTextArea
    name={name}
    value={value}
    onChange={onChange}
    disabled={disabled}
    borderStyle={borderStyle}
    inputStyle={inputStyle}
    {...rest}
  />
);

TextArea.defaultProps = {
  disabled: false,
  borderStyle: BorderStyle.SQUARE,
  inputStyle: InputStyle.REGULAR,
  rows: 4
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  borderStyle: PropTypes.string,
  inputStyle: PropTypes.string,
  rows: PropTypes.number
};

export default TextArea;
