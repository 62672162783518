import axios from "axios";
import store from "./store";
import { roles } from "./components/roles";
import { SET_LICENCE_INFORMATIONS, USER_LOGGED_OUT } from "./actions/types";

const baseURL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = baseURL;

axios.interceptors.response.use(
  (response) => {
    // Intercept every response success
    const { licence } = response.data;

    if (licence) {
      store.dispatch({ type: SET_LICENCE_INFORMATIONS, payload: licence });
    }

    return response;
  },
  (error) => {
    if (error && error.response && error.response.status === 401) {
      store.dispatch({ type: USER_LOGGED_OUT });
    }
    if (!error || !error.response) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(
        "Une erreur est survenue en tentant d'effectuer l'action. Contactez votre administrateur pour plus de renseignements."
      );
    }
    if (error.response.data) {
      return Promise.reject(error.response.data.errors.join("\r\n"));
    }
    return Promise.reject(error);
  }
);

export default {
  actions: {
    post: (data, projectId, goalId, strategyId) =>
      axios
        .post(
          `/api/project/${projectId}/goal/${goalId}/strategy/${strategyId}/action`,
          data
        )
        .then((res) => res.data),
    put: (data, actionId, projectId, goalId, strategyId) =>
      axios
        .put(
          `/api/project/${projectId}/goal/${goalId}/strategy/${strategyId}/action/${actionId}`,
          data
        )
        .then((res) => res.data),
    delete: (actionId, projectId, goalId, strategyId) =>
      axios
        .delete(
          `/api/project/${projectId}/goal/${goalId}/strategy/${strategyId}/action/${actionId}`
        )
        .then((res) => res.data),
    users: (assignedUsers, actionId, projectId, goalId, strategyId) =>
      axios
        .put(
          `/api/project/${projectId}/goal/${goalId}/strategy/${strategyId}/action/${actionId}/assigned-users`,
          assignedUsers
        )
        .then((res) => res.data),
    order: (newIndex, actionId, projectId, goalId, strategyId) =>
      axios
        .put(
          `/api/project/${projectId}/goal/${goalId}/strategy/${strategyId}/action/${actionId}/order`,
          { newIndex }
        )
        .then((res) => res.data),
  },
  auth: {
    login: (credentials) =>
      axios.post("/api/account/login", credentials).then((res) => res.data),
    register: (data) =>
      axios.post("/api/account/register", data).then((res) => res.data),
    registerCompany: (data) =>
      axios.post("/api/account/register-company", data).then((res) => res.data),
    resetPasswordRequest: (data) =>
      axios.post("/api/account/reset-password-request", data),
    unsubscribe: (data) => axios.post("/api/account/unsubscribe", data),
    resetPassword: (data) => axios.post("/api/account/reset-password", data),
  },
  comments: {
    get: (componentGuid, paging) =>
      axios
        .get("/api/comment", {
          params: {
            componentGuid,
            pageIndex: paging.pageIndex,
            pageSize: paging.pageSize,
          },
        })
        .then((res) => res.data),
    post: (componentGuid, comment) =>
      axios
        .post("/api/comment", { componentGuid, comment })
        .then((res) => res.data),
  },
  attachments: {
    get: (componentGuid, paging) =>
      axios
        .get("/api/attachment", {
          params: {
            componentGuid,
            pageIndex: paging.pageIndex,
            pageSize: paging.pageSize,
          },
        })
        .then((res) => res.data),
    getFile: (componentGuid, fileId) =>
      axios
        .get("/api/attachment/getfile", {
          params: {
            componentGuid,
            fileId,
          },
        })
        .then((res) => res.data),
    remove: (componentGuid, attachmentId) =>
      axios
        .post("/api/attachment/removeAttachment", {
          componentGuid,
          attachmentId,
        })
        .then((res) => res.data),
    post: (componentGuid, fileName, file) =>
      axios
        .post("/api/attachment", { componentGuid, fileName, file })
        .then((res) => res.data),
  },

  checkItem: {
    get: (componentGuid, paging) =>
      axios
        .get("/api/checkItem", {
          params: {
            componentGuid,
            pageIndex: paging.pageIndex,
            pageSize: paging.pageSize,
          },
        })
        .then((res) => res.data),
    edit: (componentGuid, checkItemId, check, text) =>
      axios
        .post("/api/checkItem/edit", {
          componentGuid,
          checkItemId,
          check,
          text,
        })
        .then((res) => res.data),
    post: (componentGuid, check, text) =>
      axios
        .post("/api/checkItem", { componentGuid, check, text })
        .then((res) => res.data),
    remove: (componentGuid, checkItemId) =>
      axios
        .post("/api/checkItem/remove", {
          componentGuid,
          checkItemId,
        })
        .then((res) => res.data),
  },
  creditCard: {
    update: (token) =>
      axios
        .post("/api/licence/update-credit-card-token", { token })
        .then((res) => res.data),
  },
  goals: {
    post: (data, projectId) =>
      axios
        .post(`/api/project/${projectId}/goal`, data)
        .then((res) => res.data),
    put: (data, goalId, projectId) =>
      axios
        .put(`/api/project/${projectId}/goal/${goalId}`, data)
        .then((res) => res.data),
    delete: (goalId, projectId) =>
      axios
        .delete(`/api/project/${projectId}/goal/${goalId}`)
        .then((res) => res.data),
    users: (assignedUsers, goalId, projectId) =>
      axios
        .put(
          `/api/project/${projectId}/goal/${goalId}/assigned-users`,
          assignedUsers
        )
        .then((res) => res.data),
    order: (newIndex, goalId, projectId) =>
      axios
        .put(`/api/project/${projectId}/goal/${goalId}/order`, { newIndex })
        .then((res) => res.data),
  },
  licence: {
    update: (licenceName, licenceInformations) =>
      axios
        .put(`api/licence/${licenceName}`, licenceInformations)
        .then((res) => res.data),
    disableBilling: () =>
      axios
        .post(`api/licence/disable-recuring-billing`)
        .then((res) => res.data),
  },
  licenceTypes: {
    get: () => axios.get("/api/licence").then((res) => res.data),
  },
  logs: {
    get: (page, pagesize, sort, sortOrder, filter) =>
      axios
        .get(`/api/log/getlog`, {
          params: {
            PageIndex: page,
            PageSize: pagesize,
            Sort: sort,
            SortOrder: sortOrder,
            Filters: JSON.stringify(filter),
          },
        })
        .then((res) => res.data),
  },
  paymentOptions: {
    get: () => axios.get("/api/paymentOptions").then((res) => res.data),
    getCard: () =>
      axios.get("/api/paymentOptions/card").then((res) => res.data),
  },
  profile: {
    get: () => axios.get("/api/profile").then((res) => res.data),
    put: (data) => axios.put("/api/profile", data).then((res) => res.data),
    updatePassword: (data) =>
      axios.post("/api/profile/password", data).then((res) => res.data),
    updateAvatar: (file, selectedAvatar) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("selectedAvatar", selectedAvatar);
      return axios
        .post("/api/profile/avatar", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => res.data);
    },
  },
  projects: {
    get: (activeProjectOnly) =>
      axios
        .get(`/api/project?activeProjectOnly=${activeProjectOnly}`)
        .then((res) => res.data),
    post: (data) => axios.post(`/api/project`, data).then((res) => res.data),
    put: (data, id) =>
      axios.put(`/api/project/${id}`, data).then((res) => res.data),
    delete: (id) => axios.delete(`/api/project/${id}`).then((res) => res.data),
    report: (id, name) =>
      axios
        .get(`/api/project/${id}/report`, { responseType: "blob" })
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${name}.pdf`);
          document.body.appendChild(link);
          link.click();
        }),
    users: (assignedUsers, projectId) =>
      axios
        .put(`/api/project/${projectId}/assigned-users`, assignedUsers)
        .then((res) => res.data),
  },
  proration: {
    get: (billingMode, licenceType) =>
      axios
        .get(`api/licence/${licenceType}/proration?billingMode=${billingMode}`)
        .then((res) => res.data),
  },
  strategies: {
    post: (data, projectId, goalId) =>
      axios
        .post(`/api/project/${projectId}/goal/${goalId}/strategy`, data)
        .then((res) => res.data),
    put: (data, strategyId, projectId, goalId) =>
      axios
        .put(
          `/api/project/${projectId}/goal/${goalId}/strategy/${strategyId}`,
          data
        )
        .then((res) => res.data),
    delete: (strategyId, projectId, goalId) =>
      axios
        .delete(
          `/api/project/${projectId}/goal/${goalId}/strategy/${strategyId}`
        )
        .then((res) => res.data),
    users: (assignedUsers, strategyId, projectId, goalId) =>
      axios
        .put(
          `/api/project/${projectId}/goal/${goalId}/strategy/${strategyId}/assigned-users`,
          assignedUsers
        )
        .then((res) => res.data),
    order: (newIndex, strategyId, projectId, goalId) =>
      axios
        .put(
          `/api/project/${projectId}/goal/${goalId}/strategy/${strategyId}/order`,
          { newIndex }
        )
        .then((res) => res.data),
  },
  tasks: {
    get: (userId) =>
      axios.get(`/api/task?userid=${userId}`).then((res) => res.data),
  },
  user: {
    get: (minRole = roles.RegularUser) =>
      axios.get(`/api/user?minRole=${minRole}`).then((res) => res.data),
    post: (data) => axios.post("/api/user", data).then((res) => res.data),
    put: (data, id) =>
      axios.put(`/api/user/${id}`, data).then((res) => res.data),
    delete: (id) => axios.delete(`/api/user/${id}`).then((res) => res.data),
  },
};
