import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import HasRole from "../../../roles/HasRole";

const NavigationLink = styled(NavLink)`
  display: block;
  font-weight: 400;
  font-size: 0.8em;
  padding: 5px 10px;
  border-radius: 33px;
  text-decoration: none;
  color: ${props => props.theme.white};
  margin: 5px 0;

  &.active,
  &:hover {
    color: ${({ theme }) => theme.blue_regular};
    background-color: ${({ theme }) => theme.white};
    text-decoration: none;
  }
`;

const SubNavItem = ({ subNavItem, intl }) => (
  <HasRole minRole={subNavItem.minRole}>
    <NavigationLink
      to={subNavItem.path}
      key={subNavItem.path}
      tabIndex="-1"
      target={subNavItem.target}
    >
      {intl.formatMessage(subNavItem.name)}
    </NavigationLink>
  </HasRole>
);

export default SubNavItem;
