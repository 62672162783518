import {
  FETCH_USERS_BEGIN,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  PUT_USER_BEGIN,
  PUT_USER_FAILURE,
  PUT_USER_SUCCESS,
  POST_USER_BEGIN,
  POST_USER_FAILURE,
  POST_USER_SUCCESS,
  DELETE_USER_BEGIN,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE
} from "./types";
import api from "../api";

const fetchUsersBegin = () => ({
  type: FETCH_USERS_BEGIN
});

const fetchUsersSuccess = users => ({
  type: FETCH_USERS_SUCCESS,
  payload: users
});

const fetchUsersFailure = error => ({
  type: FETCH_USERS_FAILURE,
  payload: error
});

const putUserBegin = () => ({
  type: PUT_USER_BEGIN
});

const putUserSuccess = user => ({
  type: PUT_USER_SUCCESS,
  payload: user
});

const putUserFailure = (error, oldUser) => ({
  type: PUT_USER_FAILURE,
  payload: { error, oldUser }
});

const postUserBegin = () => ({
  type: POST_USER_BEGIN
});

const postUserSuccess = () => ({
  type: POST_USER_SUCCESS
});

const postUserFailure = error => ({
  type: POST_USER_FAILURE,
  payload: error
});

const deleteUserBegin = () => ({
  type: DELETE_USER_BEGIN
});

const deleteUserSuccess = userId => ({
  type: DELETE_USER_SUCCESS,
  payload: userId
});

const deleteUserFailure = error => ({
  type: DELETE_USER_FAILURE,
  payload: error
});

export const fetchUsers = minRole => dispatch => {
  dispatch(fetchUsersBegin());

  return api.user
    .get(minRole)
    .then(res => {
      dispatch(fetchUsersSuccess(res.data));
    })
    .catch(error => {
      dispatch(fetchUsersFailure(error));
    });
};

export const updateUserRole = (roleId, user) => dispatch => {
  const newUser = { ...user, role: roleId };

  dispatch(putUserBegin(newUser));

  return api.user
    .put(newUser, newUser.id)
    .then(() => {
      dispatch(putUserSuccess(newUser));
    })
    .catch(error => {
      dispatch(putUserFailure(error, user));
    });
};

export const updateUser = user => dispatch => {
  dispatch(putUserBegin());

  return api.user
    .put(user, user.id)
    .then(() => dispatch(putUserSuccess(user)))
    .catch(error => {
      dispatch(putUserFailure(error, user));
      throw error;
    });
};

export const inviteUser = user => dispatch => {
  dispatch(postUserBegin());

  return api.user
    .post(user)
    .then(() => {
      dispatch(postUserSuccess());
    })
    .catch(error => {
      dispatch(postUserFailure(error));
      throw error;
    });
};

export const deleteUser = userId => dispatch => {
  dispatch(deleteUserBegin());

  return api.user
    .delete(userId)
    .then(() => dispatch(deleteUserSuccess(userId)))
    .catch(err => dispatch(deleteUserFailure(err)));
};
