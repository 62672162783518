import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";
import NavItems from "./navItems/NavItems";

const NavContainer = styled.ul`
  /* Position the content */
  position: absolute;
  width: 300px;
  margin-right: 20px;
  padding: 25px;
  padding-top: 25px;
  right: 0px;
  top: 50px;
  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.blue_regular};
  box-shadow: 8px 8px 58px 0 rgba(0, 0, 0, 0.5);

  /* to stop flickering of text in safari */
  -webkit-font-smoothing: antialiased;
  list-style: none;
  /* Transform the div to stop showing it if the nav is not opened */
  transform-origin: 0% 0%;
  transform: translateX(150%);
  transition: transform 0.2s cubic-bezier(0.77, 0.2, 0.05, 1);
  transform: ${props => (props.isOpen ? "scale(1.0, 1.0)" : "")};

  border-radius: 10px;
  z-index: 20000;
`;

class Nav extends React.Component {
  handleClickOutside = () => {
    const { handleClickOutside } = this.props;
    handleClickOutside();
  };

  render() {
    const { isOpen } = this.props;
    return (
      <NavContainer isOpen={isOpen}>
        <NavItems />
      </NavContainer>
    );
  }
}

Nav.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClickOutside: PropTypes.func.isRequired
};

export default onClickOutside(Nav);
