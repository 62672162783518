import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { H2 } from "../../../../components/text/Headers";
import messages from "../ForgotPasswordForm.messages";

const ForgotPasswordFormHeaderContainer = styled.div`
  margin-bottom: 40px;
`;

const ForgotPasswordFormHeader = () => (
  <ForgotPasswordFormHeaderContainer>
    <H2>
      <FormattedMessage {...messages.recoverMyPassword} />
    </H2>
  </ForgotPasswordFormHeaderContainer>
);

export default ForgotPasswordFormHeader;
