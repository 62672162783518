import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const AvatarContainer = styled.div`
  margin: 5px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const AvatarImage = styled.img`
  max-width: 50px;
  width: 50px;
  height: 50px;
  margin: 2px;

  border: ${({ selected, theme }) =>
    selected ? `2px solid ${theme.blue_regular}` : ""};

  &:hover {
    cursor: pointer;
  }
`;

class AvatarBank extends React.Component {
  constructor(props) {
    super(props);

    this.avatars = [
      `/Images/avatars/genba_01.png`,
      `/Images/avatars/genba_02.png`,
      `/Images/avatars/genba_03.png`,
      `/Images/avatars/genba_04.png`,
      `/Images/avatars/genba_05.png`,
      `/Images/avatars/genba_06.png`,
      `/Images/avatars/genba_07.png`,
      `/Images/avatars/genba_08.png`,
      `/Images/avatars/genba_09.png`,
      `/Images/avatars/genba_10.png`
    ];
  }

  render() {
    const baseURL = process.env.REACT_APP_API_URL;
    const { avatarClicked, selectedAvatar } = this.props;

    return (
      <AvatarContainer>
        {this.avatars.map(avatar => (
          <AvatarImage
            draggable={false}
            key={avatar}
            src={`${baseURL}${avatar}`}
            onClick={() => avatarClicked(avatar)}
            selected={avatar === selectedAvatar}
          />
        ))}
      </AvatarContainer>
    );
  }
}

AvatarBank.defaultProps = {
  selectedAvatar: ""
};

AvatarBank.propTypes = {
  avatarClicked: PropTypes.func.isRequired,
  selectedAvatar: PropTypes.string
};

export default AvatarBank;
