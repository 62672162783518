const InputStyle = {
  REGULAR: "REGULAR",
  COLORED: "COLORED"
};

const BorderStyle = {
  ROUND: "ROUND",
  SQUARE: "SQUARE"
};

const DisplayStyle = {
  BLOCK: "BLOCK",
  INLINE: "INLINE",
  NONE: "NONE"
};

export { InputStyle, BorderStyle, DisplayStyle };
